<script setup lang="ts">
import Input from '@/components/first/Input/index.vue'
import NavigationBar from '@/components/NavigationBar/index.vue'
import { IonPage, IonContent, IonRadio, IonRadioGroup, IonModal, IonIcon, IonImg, IonItem, IonSegment, IonSelect, IonSelectOption } from '@ionic/vue';
import useLogic from "../hooks/useLogic";
import AssetsFooter from '@/components/first/AssetsFooter.vue'
import Tab from '../../comp/Tab.vue'
import Select from '@/views/withdraw/comp/BankCardSelect.vue'
import { getTheme } from '@/theme/hooks'
import { REAL_NAME_LEN } from '../../constant'

const { theme } = getTheme()
const {
  tabList,
  typeList,
  accountInfo,
  btnDisabled,
  isSpecialCode,
  formEl,
  tabId,
  tabName,
  tabCode,
  footEl,
  distance,
  phoneCode,
  isReadonlyRealName,
  isReadonlyBANKACCOUNT,
  t,
  addAccount,
  selectChange,
  tabChange,
  handleInput,
  handleInputBankCard
} = useLogic()

const inputBgColor = computed(() => theme === 'blue-default' ? '#070B18' : 'var(--color-bg-400)')

const bankLen = computed(() => {
  const minLen = phoneCode.value === '+63' ? 1 : 10
  const maxLen = phoneCode.value === '+63' ? 64 : 16

  return { minLen, maxLen }
})


const getText = (phoneCode: string) => (key: string) => `${t(`hint.${key}${phoneCode}`)}`
const getHintText = getText(phoneCode.value)
</script>
<template>
  <IonPage>
    <NavigationBar :title="$t('viewsAssets.addAccount')" bgColor="--color-bg-200" />
    <IonContent class="b-account">
      <Tab v-model:tab-id="tabId" :tabList="tabList" @tabChange="tabChange" />
      <div ref="formEl" :class="theme">
        <div>
          <p class="label">{{ $t('label.name') }}<span class="sign">*</span></p>
          <div class="input-area">
            <Input type="text" :placeholder="$t('hint.tipName')" ref='elObj.realNameEl' :maxlength="REAL_NAME_LEN"
              v-model="accountInfo.realName" :error-text="$t('hint.invalidName')" class="font-weight"
              @input="handleInput" :readonly="isReadonlyRealName" />
          </div>
        </div>


        <div class="item" v-if="typeList.length > 1">
          <p class="label">  {{ tabName }}  <span class="sign">*</span> </p>
          <div class="input-area">
            <Select :select-list="typeList" :placeholder="$t(`hint.tipBank`)" v-model="accountInfo.code" />
          </div>

        </div>

        <div class="item" v-if="isSpecialCode">
          <p class="label">{{ tabName }} {{ $t(`label.account`) }}<span class="sign">*</span></p>
          <div class="input-area">
            <Input type="phone" :placeholder="$t(`label.${tabCode}`)" :error-text="getHintText('invalidPhone')"
              v-model="accountInfo.BANKACCOUNT" ref="elObj.BANKACCOUNTEl" :readonly="isReadonlyBANKACCOUNT" class="font-weight" />
          </div>
        </div>

        <div class="item" v-else>
          <p class="label">{{ tabName }} {{ $t(`label.account`) }}<span class="sign">*</span></p>
          <div class="input-area">
            <Input type="bankCard" :placeholder="getHintText('invalidBank')" :error-text="getHintText('invalidBank')"
              v-model="accountInfo.BANKACCOUNT" ref="elObj.BANKACCOUNTEl" class="font-weight"
              :minlength="bankLen.minLen" :maxlength="bankLen.maxLen" :hideCurrency="true"
              @input="handleInputBankCard" />
          </div>
        </div>

      </div>
      <!-- <div class="footer-wrap">
        <Button @Click="addAccount" :disabled="!btnDisabled"> {{ $t('viewsAssets.addAccount') }} </Button>
      </div> -->


    </IonContent>
    <AssetsFooter :disabled="!btnDisabled" ref="footEl" :isHasBottomTab="false" :btnText="t('viewsAssets.addAccount')"
      @submitForm="addAccount" />


  </IonPage>

</template>

<style scoped lang="less">
ion-content.b-account {
  --background: var(--color-bg-300);
  --padding-start: 1.125rem;
  --padding-end: 1.125rem;
  --padding-bottom: v-bind('distance');
}



.item {
  margin-top: 1.9rem;

}

.text-item {
  width: 50%;
}

.label {
  line-height: 18px;
  margin: .375rem 0;

  .sign {
    font-weight: var(--font-weight-medium);
    color: var(--color-danger);
    margin-left: .1875rem;
    font-size: var(--font-size-14);
  }


}

.input-area {
  width: 22.875rem;
  height: 2.875rem;
  line-height: 2.875rem;
  .bg-400();
  background-color: v-bind('inputBgColor');
}


.option-content {
  display: flex;
  align-items: center;
}

.option-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.purple-light {
  .label {
    color: var(--color-text-80)
  }
}
</style>
