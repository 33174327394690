// 检测当前用户是否开启了调试工具
import { addListener, launch } from 'devtools-detector';
import { useSystemStore } from '@/store/system';
import { useI18n } from '@/hooks/useI18n';

const { t } = useI18n();

// 用户开启调试工具时相关处理
function handleOpenDebugger() {
  alert(t('activity.closeDevToolsTips'));
  window.location.reload();
}

function checkDevToolsIsOpen() {
  const urlParams = new URLSearchParams(window.location.search);
  const disableDetection = urlParams.get('check') === '0';
  if (disableDetection) return;
  
  addListener((isOpen) => useSystemStore().isOpenDevTools = isOpen);
  launch();
}

export { checkDevToolsIsOpen, handleOpenDebugger };
