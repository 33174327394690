<!-- 代理活动-详情 -->
<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <!-- 顶部导航栏 -->
      <ion-toolbar class="" mode="ios">
          <BackButton />
          <ion-title>{{ $t('activity.agent28') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <!-- 搜索栏 -->
      <div class="flex items-center px-2 w-full h-[2.5rem]">
        <div class="flex-center px-[0.625rem] py-[0.25rem] w-[10.5rem] h-[1.625rem] color-border input-bg rounded-small">
          <ion-input class="no-spinners text-xs" ref="inputRef" mode="ios" v-model="userId" :placeholder="$t('activity.agent29')" type="search" :controls="false" @ionBlur="getUserDetails">
            <ion-icon slot="end" :icon="searchOutline"></ion-icon>
          </ion-input>
        </div>
      </div>
      <div class="px-2">
        <!-- 列表标题 -->
        <div class="flex py-3 bg-100 rounded-top">
          <span v-for="title in titleList" class="flex-1 whitespace-normal text-center text-xs font-weight-bold color-text-40">
            {{ title }}
          </span>
        </div>
        <ion-list>
          <div v-for="(item, index) in pageData" class="flex items-center w-full h-[3.125rem] text-xs text-center text-normal" :class="index%2 === 0 ? 'bg-300': 'bg-400'">
            <span class="flex-1">{{ item.registerTime }}</span>
            <span class="flex-1">{{ item.userId }}</span>
            <span class="flex-1 color-text-currency font-weight-bold">
              <p>{{ formatMoneyToShow(item.firstRechargeAmount) }}</p>
              <p>{{ formatMoneyToShow(item.historicalPay) }}</p>
            </span>
            <span class="flex-1">
              <p>{{ `${item.rechargeCount}${$t('activity.agent35')}` }}</p>
              <p>{{ `${item.rechargeDays}${$t('activity.agent36')}` }}</p>
            </span>
            <span class="flex-1 color-text-currency font-weight-bold">
              <p>{{ formatMoneyToShow(item.historicalBetting) }}</p>
            </span>
          </div>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonHeader, IonToolbar, IonInput, IonContent, IonList, IonTitle, IonIcon } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';
import { formatMoneyToShow } from '@/utils/custom';
import { searchOutline } from 'ionicons/icons';

import useLogic from '../detailsLogic';

const {
  titleList,
  pageData,
  userId,
  getUserDetails,
} = useLogic();

</script>

<style scoped>

.rounded-top {
  border-radius: var(--rounded-small)  var(--rounded-small) 0 0;
}

ion-input {
  --color: var(--color-text-80);
  --placeholder-color: var(--color-text-40);
}

.input-bg {
  background-color: var(--color-agent-input-bg);
}

:global(ion-input .input-wrapper.sc-ion-input-ios) {

  /* 禁用默认的增加和减少按钮 */
  -webkit-appearance: textfield;
  /* Firefox 隐藏箭头按钮 */
  appearance: textfield;


  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;

  /* background: #f88; */
}

ion-list {
  padding-top: 0;
  padding-bottom: 0;
}

.text-normal {
  color: var(--text-color-light-purple-1-100);
}
</style>
