<template>
  <div>
    <div class="w-full h-full" 
      :style="game.background ? `background: url('${game.background}') no-repeat 0 0 / 100% 100%` : ''">
      <div class="flex justify-end w-full p-1">
        <ion-img :class="['w-6', isFavorite ? 'animate-star' : '']"
          :src="`/icons/favorite_${isFavorite ? 'on' : 'off'}.png`" @click.stop="favoriteHandle(game)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonImg } from '@ionic/vue'
import { useUserStore } from '@/store/user'
import { isEmpty } from 'lodash-es';

const emit = defineEmits<{
  (e: 'cancelFavorite', id: number): void
}>()
const userStore = useUserStore()

interface Props {
  game: Record<string, any>
  platform?: any
}
const props = defineProps<Props>()

const isFavorite = ref(false)
watch(() => props.game, (newValue) => {
  if (isEmpty(newValue)) return
  isFavorite.value = newValue.isFavorite
}, { immediate: true })

/**
 * @description 收藏事件
 */
function favoriteHandle(item: Record<string, any>) {
  // item.isFavorite = !item.isFavorite;
  const game = {
    gameName: item.gameName || item.name,
  };
  Object.assign(game, item);
  if (!isFavorite.value) {
    userStore.addFavorite(game, props.platform);
    isFavorite.value = true
  } else {
    userStore.cancelFavorite(game, props.platform);
    isFavorite.value = false
    emit("cancelFavorite", Number(item.id))
  }
}
</script>

<style scoped></style>
