<!-- 多级分销 邀请链接 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('mlmAgent.inviteLink') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="link">
         <!-- 轮播图 -->
         <div class="swiperBg">
          <swiper @swiper="onSwiper">
            <swiper-slide v-for="item in linkMes" :key="item.id">
              <div class="item">
                <img :src="item.src"/>
                <div class="qrCode">
                  <QrCode :value="shareUrl" :size="remToPx(3.8438)" :margin="0" :color="`#000000`" :bgColor="`#FFFFFF`" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
         </div>
        <div class="btn" >
         <div class="top">
           {{ $t('mlmAgent.invitationMsg') }}
         </div>
         <div v-if="!isSecondSkin" @click="copyBtnClick" class="invitation-copy-btn">
           {{ $t('main.copy') }} {{ $t('mlmAgent.inviteLink') }}
         </div>
         <div v-else class="invitation-else-btn">
          <Button @click="copyBtnClick">{{ $t('main.copy') }} {{ $t('mlmAgent.inviteLink') }}</Button>
         </div>
         <div @click="customerBtnClick" class="invitation-cust-btn"> 
           <ion-icon icon="/svg/spread/customerService.svg" />
           {{ $t('mlmAgent.invitationCusMsg') }}
         </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import { remToPx } from '@/hooks/RemToPx';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useinvitationLinkSpread } from '@/views/mlmAgent/hooks/invitationLinkSpread'
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle ,IonImg ,IonIcon} from '@ionic/vue';
import Button from '@/components/second/Button/index.vue'
import BackButton from '@/components/BackButton.vue';
import QrCode from 'qrcode.vue';

// 当前是second皮肤
const isSecondSkin = computed(() => getTheme().skin == 'second');

const {
  onSwiper,
  shareUrl,
  linkMes,
  copyBtnClick,
  customerBtnClick
} = useinvitationLinkSpread();
</script>

<style scoped lang="less">
@import '@/views/mlmAgent/styles/invitationLink.less' ;
</style>
