import { apkTaskForceModal } from "./apkTask"
import { registerRewardForceModal } from "./registerReward"
import { channelForceModal } from "./channel"
import { announcementModal } from "./announcement"

 const useForcedModal = async () => {
  const channelStore = useChannelStore(); // 渠道信息 
  const forceModalMap = new Map<string, () => Promise<boolean>>([
    ['registerReward', registerRewardForceModal], 
    ['apkTask', apkTaskForceModal],
    ['channel', channelForceModal],
    ['announcement', announcementModal]
  ])
  for (const [key, fn] of forceModalMap) {
    const isShowForceModal = await fn()
    if (isShowForceModal) {
      channelStore.isShowForceModal = true
      return true
    }
  }
  return false
}

export {
  apkTaskForceModal,
  registerRewardForceModal, 
  channelForceModal,
  announcementModal,
  useForcedModal
}
