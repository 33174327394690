<!-- 公告消息 -->
<template>
  <ion-content class="common-ion-content">
    <div class="w-full mt-[0.0625rem] flex justify-center" v-if="unreadAnnouncement>0">
      <ion-button class="read-all-btn" @click="readAllClick">
        <ion-icon class="text-[1.125rem] mr-[0.21875rem]" src="/second/svg/user/all-email.svg" />
        {{ $t('viewsUser.emailAllRead') }}
      </ion-button>
    </div>
    <div class="w-full" :class="unreadAnnouncement > 0 ? 'mt-[0.8125rem]' : ''">
      <div v-for="(list, index) in announcementList" :key="index">
        <p v-if="typeof list == 'string'"></p>
        <div class="announcement-msg relative" v-for="item in list" v-else @click="detailHandle(item)" :key="item.id">
          <div class="item-top flexBox mb-[1.171875rem] z-30">
            <!-- 公告🎁svg图标 -->
            <ion-icon class="text-[2.25rem]" src="/second/svg/user/ancement-icon.svg" />
            <div class="item-top-tips ml-[0.75rem] w-full">
              <div class="middle-text-white flex-between">
                <!-- 公告标题 -->
                <p class="item-title">{{ item.title }}</p>
              </div>
              <!-- 公告创建时间 -->
              <p>{{ item.createTime }}</p>
            </div>
          </div>
          <!-- 公告内容 -->
          <div class="small-text-white announcement-content line-clamp-2 message-content" v-html="item.content"></div>
          <!-- 公告红点 -->
          <RipplePoint v-show="!readAnnouncementList.includes(item.id)" class="top-[0.234375rem] right-[0.3125rem]" size="0.5rem"/>
        </div>
      </div>
    </div>
    <!-- 空列表提示 -->
    <div class="flex-center w-full h-full" v-if="!announcementList.length">
      <Empty :type="'msg'" class="w-full" />
    </div>
  </ion-content>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import Empty from '@/components/Empty/index.vue'
import { IonContent, IonIcon, IonButton, IonImg, IonLabel } from '@ionic/vue';
import { useAnnouncement } from '@/views/user/notification/hooks/announcementLogic'
import RipplePoint from '@/components/HotPoint/ripplePoint.vue'

const {
  readAnnouncementList,
  announcementList,
  unreadAnnouncement,
  replaceImgTag,
  detailHandle,
  readAllClick
} = useAnnouncement();

</script>

<style scoped lang="less">
.announcement-msg {
  padding: .75rem .5313rem .9375rem .8125rem;
  font-size: .625rem;
  line-height: .9375rem;
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: .625rem;
  border-radius: var(--rounded-large);
  background: var(--color-bg-200);
  .item-title {
    font-weight: 600;
    color: #BDB8E1;
  }
}

:deep(.announcement-content p img) {
  margin-bottom: 1.3125rem;
}

ion-button.read-all-btn {
  min-height: 0;
  min-width: 0;
  margin: 0;
  --background: linear-gradient(180deg, #5A25E9 0%, #7E45D2 100%);
  --color: #BDB8E1;
  --border-radius: 1.8125rem;
  // --background-focused-opacity: 1;
  // --background-hover-opacity: 1;
  // --background-activated-opacity: 1;
  // --background-focused: var(--msg-all-button-color-shade);
  // --background-hover: var(--msg-all-button-color-tint);
  // --background-activated: var(--msg-all-button-color-shade);
}

ion-button.read-all-btn::part(native) {
  padding: .1875rem .9113rem .25rem .6875rem;
  font-weight: 500;
  font-size: .75rem;
  line-height: 1.3125rem;
}

.message-content {
  font-weight: 600;
  color: var(--my-card-detail-color);
}
</style>
