<template>
  <ion-item lines="none" class="rebate mb-2.5 text-[.625rem]" :key="item.platformId" button detail v-if="maxRebateRate">
    <div class="main-rebate">
      <div class="top-rebate">
        <div class="flex items-center top-rebate-left">
          <div class="flex items-center">
            <ion-icon class="logo text-2xl" :src="item.logo" />
            <p class="rebate-ratio">{{ $t("activity.agent34") }}</p>
          </div>
          <div class="color-text-100">
            {{ formatMoneyToShow(validBet) }}
          </div>
        </div>
        <div class="rebate-right">
          <span class="rebate-ratio" style="text-shadow: none; font-size: 0.625rem">{{ $t("viewsActivity.rebateRatio") }}</span>
          <span class="color-text-100" style="text-shadow: none; font-size: 0.625rem">{{ rebateRate }}%</span>
        </div>
      </div>
      <div class="bottom-rebate">
        <div class="bottom-rebate-left">
          <ion-progress-bar class="h-2.5" :value="upgrade ? validBet / upgrade : 1" />
          <p class="absolute top-0 left-0 right-0 bottom-0 text-center leading-none overflow-content color-text-80">
            {{
              upgrade
                ? $t("viewsActivity.betTips", { betMoney: formatMoneyToShow(upgrade - validBet), rate: nextRebateRate })
                : $t("viewsActivity.maxLevel") + " 100%"
            }}
          </p>
        </div>
        <div class="rebate-right">
          <span class="rebate-ratio" style="text-shadow: none; font-size: 0.625rem">{{ $t("label.collectable") }}</span>
          <span class="color-text-currency" style="text-shadow: none; font-size: 0.625rem">{{ formatMoneyToShow(availableRebate) }}</span>
        </div>
      </div>
    </div>
  </ion-item>
</template>

<script setup lang="ts">
import { formatMoneyToShow } from "@/utils/custom";
import { IonItem, IonIcon, IonProgressBar } from "@ionic/vue";
import useRebateItemLogic from "../../rebateItemLogic";

const props = defineProps({
  item: { type: Object, required: true },
  gameType: { type: String, required: true },
});

const { validBet, rebateRate, upgrade, nextRebateRate, availableRebate, maxRebateRate } = useRebateItemLogic({ props });
</script>

<style scoped>
ion-item.rebate {
  --detail-icon-opacity: 1;
  --detail-icon-font-size: 1rem;
  --detail-icon-color: var(--color-text-40);
  --background: var(--color-bg-200);
  --min-height: 3.75rem;
  --height: 3.75rem;
  --max-height: 3.75rem;
  border-radius: 0.625rem;
}

ion-item.rebate::part(native) {
  /* 插槽slot内容样式 */
  padding-inline-start: 0.4rem;
  --inner-padding-end: 0;
}

ion-item::part(detail-icon) {
  margin-inline-end: 0;
}

ion-progress-bar {
  --background: var(--color-progress-bg);
  --progress-background: var(--color-progress-bar);
  border-radius: 0.8125rem;
}

.main-rebate {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top-rebate,
.bottom-rebate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1px 0;
}
.top-rebate-left,
.bottom-rebate-left {
  flex: 1;
  position: relative;
}
.rebate-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  padding-right: 5px;
  max-width: 7.5rem;
}
.rebate-ratio {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  text-align: left;
  color: var(--color-rebate-title);
}
.overflow-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.top-rebate-left {
  justify-content: space-between;
}

div ion-icon.logo {
  color: var(--color-icon-platform-rebate);
} 
</style>
