import router from '@/router'
import { isEmpty } from 'lodash-es'



export default async (gameList: any[], isFavorite: boolean = false) => {
  const gameStore = useGameStore() // 游戏信息
  await gameStore.getHomeGames()
  const route = router.currentRoute

  const platformList = gameStore.homeGames.find((it: any) => it.gameType == 'SPORTS')?.platformList
  if (isEmpty(platformList)) return gameList

  let platformMap = new Map()
  platformList.filter((item: any) => item.target === 'hall').forEach((it: any) => platformMap.set(it.id, it))



  let result = gameList.filter((item: any) => {
    let isHas = platformMap.has(item.platformId)
    return route.value.path === '/game/category/sport' ? isHas : !isHas

  })
  //   收藏接口 返回收藏体育平台的时候，没有游戏信息， 只能用homeList接口的信息
  if (route.value.path === '/game/category/sport') {
    result = result.map((it: any) => {
      return {...platformMap.get(it.platformId), isFavorite}
    })
  }

  return result

}
