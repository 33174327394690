<template>
  <div class="px-3">
    <div class="flex items-stretch justify-center my-3 px-1 py-0.5 rounded-[.25rem] relative min-h-[1.875rem]">
      <div class="left w-12 absolute h-full left-1 top-0 z-10 flex items-center">
        <ion-icon class="text-[1.5rem]" src="/first/svg/broadcast.svg" style="color:var(--bugle-color)"/>
      </div>
      <Marquee class="marquee my-1" :content="marqueeContent"/>
      <div class="right w-12 absolute h-full right-1 top-0 z-10 flex justify-end items-center" @click="router.push('/notification')">
        
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonIcon, IonImg } from '@ionic/vue';
import Marquee from '@/components/Marquee.vue';
import useMarqueeLogic from '../logic';
import router from '@/router';

const { marqueeContent, unreadMailCount, unreadAnnouncement } = useMarqueeLogic();
</script>

<style scoped>
div.left {
  background: linear-gradient(90deg, var(--color-bg-300) 46.36%, rgba(28, 35, 52, 0) 100%);
}

div.right {
  background: linear-gradient(270deg, var(--color-bg-300), rgba(28, 35, 52, 0) 100%);
}

.marquee {
  color: var(--marquee-color);
  font-weight: var(--marquee-weight);
  font-size: var(--marquee-size);
}
</style>
