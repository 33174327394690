

export default (accountList: any[], subTypeList: any[]) => {
  console.log(accountList,subTypeList, 'accountList');
  if (!accountList.length) return []
  let codeMay = new Map()
  subTypeList.forEach((it: any) => codeMay.set(it.code, { icon: it.icon, name: it.name, id: it.id }))
  let relatedCodelist: any[] = []

  return accountList.reduce((init: any[], cur: any, index: number, list: any[]) => {
    let { relatedCode, tenantWithdrawTypeId, isDefault } = cur
    if (relatedCode && !relatedCodelist.includes(relatedCode)) {
      relatedCodelist.push(relatedCode)

      let samelist = list.filter(it => it.relatedCode === cur.relatedCode)
      let code = samelist.find(it => it.valueType === 'BANKACCOUNT')?.code
      if (code && codeMay.get(code)) {
        let { icon, name, id } = codeMay.get(code)
        let ids = samelist.map((it: any) => it.id)
        let valueTypeMay = { icon, name, ids, relatedCode, isDefault, tenantWithdrawTypeId, tenantWithdrawTypeSubId: id }
        samelist.forEach((it: any) => valueTypeMay[it.valueType] = it.value)
        samelist.length > 1 && init.push(valueTypeMay) 
      }

    }
    return init
  }, []).sort((it1, it2) => it2.isDefault - it1.isDefault)
}
