<script setup lang="ts">

import NavigationBar from '@/components/NavigationBar/index.vue'
import { IonPage, IonContent, IonRadio, IonRadioGroup, IonModal, IonIcon } from '@ionic/vue';
import useLogic from "../hooks/useLogic";
// import { close } from 'ionicons/icons';
import Card from '../../comp/Card.vue'
import Button from '@/components/first/Button/index.vue';
import useGetDarkBtnTextColor from '@/hooks/useGetDarkBtnTextColor'
import Tab from '../../comp/Tab.vue'
import useHeaderBgColor from '../../hooks/useHeaderBgColor'
import { getTheme } from '@/theme/hooks'
const btnColor = useGetDarkBtnTextColor()
const { theme } = getTheme()
const {
  relatedCode,
  modelValue,
  accountList,
  deleteIFSC,
  tabList,
  tabId,
  loading,
  isDisabled,
  isShowAddCard,
  closeHandle,
  modifyDefault,
  handleDelete,
  deleteAccount,
  tabChange
} = useLogic()

const getBtnBgColor = computed(() => {
  switch (theme) {
    case 'green-default':
    case 'blue-default':
      return 'var(--theme-color-gradient-100)'

    default:
      return 'var(--theme-color-800)'
  }

})

</script>
<template>
  <IonPage>
    <NavigationBar :title="$t('viewsAssets.myAccount')" :bgColor="useHeaderBgColor()" />
    <IonContent class="w-account" :class="theme">
      <Tab :tabList="tabList" v-model:tab-id="tabId" :disabled="loading" @tabChange="tabChange" />
      <ion-radio-group :value="relatedCode" @ionChange="modifyDefault">
        <template v-for="it in accountList">
          <Card :cardInfo="it" class="m-b" :isShowAction="true" @handleDelete="handleDelete">
            <template></template>
            <template #action>
              <div class="flex-between"><ion-radio class='check' :disabled="isDisabled" :value="it.relatedCode"
                  mode="ios"></ion-radio> <span class="ml-0.5">{{ $t('viewsAssets.default') }}</span>
              </div>
            </template>
          </Card>
        </template>
      </ion-radio-group>
      <Card type="empty" v-if="isShowAddCard" />
    </IonContent>


    <ion-modal id="modal-delete-account" :class="[theme, 'modal-delete-account']" :is-open="modelValue"
      @didDismiss="closeHandle">
      <div class="modal-content">
        <p class="warning-title">{{ $t('main.tips')
          }}</p>
        <p class="warning-text">{{ $t('viewsAssets.warningText')
          }}<span class="tip">{{ deleteIFSC }}</span>?</p>

        <div class="flex-between">
          <Button class="text-sm mr-[.3125rem]" height="3.125rem" background="var(--color-button-bg-gray)"
            :style="btnColor" @click="closeHandle">{{ $t('label.cancelOrder')
            }}</Button>
          <Button class="text-sm ml-[.3125rem]" height="3.125rem" @click="deleteAccount" :background="getBtnBgColor">{{
            $t('main.confirm')
          }}</Button>
        </div>
      </div>
      <!-- 订单操作 -->


    </ion-modal>

  </IonPage>

</template>

<style scoped lang="less">
ion-content.w-account {
  --background: var(--color-bg-300);
  --padding-top: 1.0625rem;
  --padding-bottom: 1.0625rem;
}

.m-b {
  margin-bottom: 1.0625rem !important;
}


ion-radio {
  --color-checked: var(--color-bg-200);
}

#withdraw-main-withdraw-account-radio-index {
  .style(@radioCheckedBg: --color-text-80) {
    ion-radio.check.ios::part(container) {
      width: 16px;
      height: 16px;

      border: 1px solid var(--color-text-40);
      border-radius: 50%;
    }

    .radio-checked.check.ios::part(container) {
      border-color: var(--line-color);
      background-color: var(@radioCheckedBg);
    }

  }
}





#withdraw-main-withdraw-modal-delete-account-index {
  .style(@bg: --color-bg-300, @tipColor: --color-text-100) {
    ion-modal.modal-delete-account {
      --min-width: 0;
      --width: fit-content;
      --height: fit-content;
      --border-radius: 6px;
      --box-shadow: none;
      --background: var(@bg);

      .modal-content {
        background: var(--color-bar-bg-200);
        width: 21.25rem;
        height: 11.75rem;
        padding: 17px 15px 14px;
        box-sizing: border-box;
        text-align: center;

        .warning-title {
          .dynamic-font(@color: --color-text-100, @fontWeight: --font-weight-bold, @fontSize: --font-size-18);
          line-height: 1.6875rem;
          margin-bottom: 12px;
        }

        .warning-text {
          .dynamic-font(@color: --color-text-40, @fontSize: --font-size-14);
          line-height: 1.3125rem;
          margin-bottom: 1.75rem;

          .tip {
            margin: 0 .1875rem;
            .dynamic-font(@color: @tipColor, @fontWeight: --font-weight-medium, @fontSize: --font-size-14);
          }
        }
      }
    }
  }

}

#withdraw-main-withdraw-account-radio-index.style();

#withdraw-main-withdraw-modal-delete-account-index.style();
.auroral-yellow{
  #withdraw-main-withdraw-account-radio-index.style(@radioCheckedBg: --color-text-gray-100);
  #withdraw-main-withdraw-modal-delete-account-index.style(--color-bg-200, --color-text-gray-200);
}

.green-default {
  #withdraw-main-withdraw-account-radio-index.style(@radioCheckedBg: --color-text-80);
  #withdraw-main-withdraw-modal-delete-account-index.style(--color-bg-200, --color-text-gray-100);
}




</style>
