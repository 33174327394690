<!-- 首页红包雨活动弹窗 -->
<template>
  <!-- 红包雨图标 -->
  <RedPacketRain 
    @close="closeRedPacketRain"
    @openModel="openRedPacketModel" 
    :activityId="activityId"
    v-if="showRedPacketRain"
  />
  <!-- 红包雨详情弹窗 -->
  <RedPacketModel :activityId="activityId" :openRedModel="openRedModel"  @closeModel="closeModel" />
</template>

<script setup lang="ts">
import RedPacketRain from './RedPacketRain.vue'
import RedPacketModel from './RedPacketModel.vue'
import useLogic from '../logic'

const { 
  showRedPacketRain,
  activityId,
  openRedModel,
  closeRedPacketRain,
  openRedPacketModel,
  closeModel
} = useLogic()
</script>
