<template>
  <ion-modal id="shareModal" ref="modal" :initial-breakpoint="1" :is-open="visible" :backdrop-dismiss="false">
     <div class="flex flex-col justify-end h-full">
      <div class="p-3 text-[#898988] bg-white relative">
        <!-- 关闭按钮 -->
        <ion-icon class="absolute text-xl top-3 right-2" :icon="close" @click="closeShareModal" />
        <p class="text-sm font-black">1. {{ $t('activity.inviteFriendsWithdrawal') }}</p>
        <ion-segment 
          ref="segment" 
          mode="ios" 
          scrollable 
          @mousedown="handleMouseDown"
          @mouseup="handleMouseUp"
          @mouseleave="handleMouseLeave"
          @mousemove="handleMouseMove"
        >
          <ion-segment-button 
            v-for="(item, index) in segmentList" 
            :value="index" 
            :key="item.name"
            :disabled="disableTab"  
            @click="launchHandle(item.name)"
          >
            <ion-icon 
              :src="item.icon" 
              :class="{ 
                'invite-cpf-share-icon': ['Twitter'].includes(item.type), 
                'you-tu-be-icon': item.type == 'YouTube' 
              }" 
              class="w-[2.1875rem] h-[2.1875rem]" 
            />
          </ion-segment-button>
        </ion-segment>
        <!-- 分享链接 -->
        <ion-row class="text-sm justify-between bg-[#f9f9f9] py-2 px-3 rounded-[.25rem]">
          {{ shareUrl }}
          <span class="text-[#40a1de]" @click="copy(shareUrl)">{{ $t('main.copy') }}</span>
        </ion-row>
        <!-- 分享号码列表 -->
        <p class="text-sm font-black mt-4 mb-2.5" v-if="sharePhones.length">
          2. {{ $t('activity.sendRandomPlayer') }} 
        </p>
        <div class="bg-[#898988] rounded-[.1875rem] p-1.5" v-if="sharePhones.length">
          <ion-row>
            <ion-col 
              size="3" 
              class="ps-0 pe-0 text-center text-[.625rem] leading-none"
              :class="item.isUsed ? 'text-[#BBB]' : 'text-white'" 
              v-for="(item,index) in sharePhones" 
              :key="index"
            >
              {{ item.phone }}
            </ion-col>
          </ion-row>
          <ion-row class="text-xs leading-relaxed text-black">
            <ion-col size="6" class="pr-2" @click="whatsappHandle">
              <ion-row class="bg-white h-12 px-2 py-1 rounded-[.1875rem] items-center">
                <p class="flex-1">{{ $t('activity.sendMessageOn') }}<span class="font-black"> WhatsAPP</span></p>
                <img class="w-9" src="/images/activity/call.svg" />
              </ion-row>
            </ion-col>
            <ion-col size="6" class="pl-2" @click="sendSMSHandle">
              <ion-row class="bg-white h-12 px-2 py-1 rounded-[.1875rem] items-center">
                <img class="mr-1 w-9" src="/images/activity/sms.png" />
                <p class="flex-1">{{ $t('activity.sendMessageOn') }}<span class="font-black"> SMS</span></p>
              </ion-row>
            </ion-col>
          </ion-row>
        </div>
      </div>
     </div>
  </ion-modal>
</template>

<script setup lang="ts">
import { close } from 'ionicons/icons'
import { IonModal, IonIcon, IonSegment, IonSegmentButton, IonRow, IonCol } from '@ionic/vue'
import { copy } from '@/hooks/Copy'
import { useShareModalLogic } from '@/views/activity/inviteCpf/hooks/shareModalLogic'

const emit = defineEmits(["closeShare"]);

const {
  visible,
  segmentList,
  disableTab,
  sharePhones,
  shareUrl,
  closeShareModal,
  launchHandle,
  handleMouseDown,
  handleMouseUp,
  handleMouseLeave,
  handleMouseMove,
  whatsappHandle,
  sendSMSHandle
} = useShareModalLogic({ emit });
</script>

<style scoped lang="less">
  @import '@/views/activity/inviteCpf/components/modal/styles/base.less';

  .green-dark,
  .yellow-dark {
    .invite-cpf-share-icon {
      background: #333539;
    }
  }

  .purple-light {
    .invite-cpf-share-icon {
      background: #effdfc;
    }
  }

  .green-default,
  .amber-purple,
  .blue-default {
    .invite-cpf-share-icon {
      background: #000000;
    }
  }

  .green-default,
  .amber-purple,
  .blue-default,
  .forest-green {
    .you-tu-be-icon {
      background: #e52f1e;
    }
  }
</style>
