
import useSportGame from './useSportGame'
import useBetbySprotGame from "./useBetbySprotGame";
import { TGameInfo } from '@/store/game'
import router from '@/router'
import { emitter } from '@/utils/event'
import { getMobileOperatingSystem } from '@/utils/custom'
import { goMain } from './navigate'
export default async (game: TGameInfo, isForce: boolean = false) => {

  if (game.target !== 'hall') return
  const gameStore = useGameStore()
  const tenantStore = useTenantStore()
  const userStore = useUserStore()
  await userStore.getUser()
  const userId = userStore.user?.userId!

  if (!userId) return

  let { name, platformId, code, gameType } = game


  if (code === 'Betby') {

    await useBetbySprotGame()

  } else {
    await useSportGame(game)
  }

  if (game) {
    gameStore.addRecentGame(game)
    sessionStorage.setItem('gameCode', game.name || '')
  }




  // }
  if (code === "M8SPORTS") {
    window.open(gameStore.gameSportsUrl, '_blank');
  } else if (code === 'Betby') {
    if (isForce) {
      goMain("/main/Betby")
    } else {
      router.push("/main/Betby")
    }

  } else {
    if (isForce) {
      goMain(`/main/gameSports/${name}/${platformId}`)
    } else {
      router.push(`/main/gameSports/${name}/${platformId}`)
    }

  }

  // router.push(`/main/gameSports/${name}/${platformId}`)


  emitter.emit('user/play-game', {
    gameId: '',
    userId: userId,
    tenantId: Number(tenantStore.tenantId),
  })

}
