import { modalController } from "@ionic/vue";
import { announcementModal } from "./announcement"
export const closeForceModal = async (isSetExpirationTime: boolean = true) => {
  const appStore = useAppStore(); // 用户store
  const channelStore = useChannelStore(); // 渠道store
  isSetExpirationTime && channelStore.setCompulsoryInstallTime(channelStore.promotionInfo?.popupInterval);
  appStore.modalVisible = false;
  channelStore.isShowForceModal = false
  try {
    const topModal = await modalController.getTop();
    if (topModal) {
      modalController.dismiss();
    }
  } catch (error) {
    console.warn('Modal dismiss error:', error);
  } finally {
    if (!isSetExpirationTime) return
    await announcementModal()
  }
}


