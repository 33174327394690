<template>
  <ion-modal ref="modal" :is-open="true" @willDismiss="maskBtn">
    <div 
      :class="{ 
        'old-skin': curDefaultSkin, 
        'first-skin': curFirstSkin, 
        'content-warning': curDefaultSkin && alertOptions.bgColor == 'warning'
      }"
    >
      <!-- alert 标题 -->
      <div :class="{ 'old-title': curDefaultSkin, 'first-title': curFirstSkin }">{{ alertOptions.title }}</div>
      <!-- alert 内容 -->
      <div :class="{ 'old-message': curDefaultSkin, 'first-message middle-text-white': curFirstSkin }">{{ alertOptions.message }}</div>
      <!-- 底部按钮 -->
      <div class="alert-button">
        <!-- 取消 -->
        <ion-button 
          size="small"
          v-show="showCancel" 
          @click="cancelButton" 
          :expand="alertOptions.cancelOption.expand" 
          :fill="curDefaultSkin ? alertOptions.cancelOption.fill : 'solid'" 
          :class="{ 'old-left-btn': curDefaultSkin,'first-left-btn button-bg-left-color': curFirstSkin, 'cancel-warning': defaultGameLeftBtn  }"
        >
          {{ cancelText }}
        </ion-button>
        <!-- 确定 -->
        <ion-button 
          size="small"
          v-show="showSure" 
          @click="sureButton" 
          :expand="alertOptions.sureOption.expand" 
          :fill="curDefaultSkin ? alertOptions.sureOption.fill : 'solid'" 
          :class="{ 'old-right-btn': curDefaultSkin, 'first-right-btn button-bg-right-color': curFirstSkin, 'sure-warning': defaultGameRightBtn}"
        >
          {{ sureText }}
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { computed } from "vue";
import { IonButton, IonModal } from "@ionic/vue";
import { getTheme } from "@/theme/hooks";

const { t } = i18n.global;
const props = defineProps({
  alertOptions: {
    type: Object,
    default: {},
  },
});

// 当前属于旧皮肤
const curDefaultSkin = computed(() => getTheme().skin == 'default');
const curFirstSkin = computed(() => ['first','second'].includes(getTheme().skin));

// default 游戏退出
const defaultGameLeftBtn= computed(() => curDefaultSkin.value && props.alertOptions?.cancelOption?.color == 'warning');
const defaultGameRightBtn= computed(() => curDefaultSkin.value && props.alertOptions?.sureOption?.color == 'warning');

// 取消按钮是否显示
const showCancel = computed(() => {
  const { alertOptions: { cancelOption: { showCancel } } } = props;
  return showCancel ? showCancel : true;
});
// 确定按钮是否显示
const showSure = computed(() => {
  const { alertOptions: { sureOption: { showSure } } } = props;
  return showSure ? showSure : true;
});
// 取消文本
const cancelText = computed(() => {
  const { alertOptions: { cancelOption: { cancelText } } } = props;
  return cancelText ? cancelText : t("main.cancel");
});
// 确定文本
const sureText = computed(() => {
  const { alertOptions: { sureOption: { sureText } } } = props;
  return sureText ? sureText : t("label.logout");
});

// 取消按钮颜色
const emit = defineEmits(["maskClick", "cancelClick", "sureClick"]);

// 取消  click 事件
function cancelButton() {
  emit("cancelClick", "");
}
// 确定 click 事件
function sureButton() {
  emit("sureClick", "");
}
// 遮罩层 点击事件
function maskBtn() {
  emit("maskClick", "");
}
</script>

<style scoped lang="less">
@import '@/components/IonAlert/styles/index.less' ;
</style>
