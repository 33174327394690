<template>
  <div class="aspect-ratio-box" :class="{frame: showFrame}">
    <div class="tool">
      <ion-icon class="logo" :src="logo" v-if="isLogo"/>
      <progressiveImages 
        :class="[isFavorite ? 'animate-star' : '', 'favorite']"
        :src="favoriteImgPath"
        @click.stop="favoriteHandle"
      />
    </div>
    <progressiveImages 
      class="game-img" 
      lowResolutionImage="/icons/heart.png" 
      :src="gameImage"
    />
    <progressiveImages 
      v-if="status === 'MAINTAIN'"
      class="game-status"
      src="/icons/maintain.png"
      @click.stop="showToast('toast.gameMaintain', 'warning')"
    />
    <div class="game-name">
      <slot name="gameName" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonIcon } from "@ionic/vue";
import { showToast } from '@/utils'
import progressiveImages from '@/components/GameCard/progressiveImages.vue';
import { isGray, isProd } from "@/utils/app";

const props = withDefaults(
  defineProps<{
    logo: string;
    status: string;
    isLogo: boolean;
    gameImage: string;
    isFavorite: boolean;
    isOwnGame?: boolean;
  }>(),
  {
    isLogo: false,
    isFavorite: false,
    isOwnGame: false,
  },
);

const emit = defineEmits(["favoriteHandle"]);

const favoriteImgPath = computed(() => `/icons/favorite_${props.isFavorite ? 'on' : 'off'}.png`)

const showFrame = computed(() => {
  return (isGray() || !isProd()) && props.isOwnGame;
});
// 收藏事件的回调用
const favoriteHandle = () => {
  emit("favoriteHandle");
};

</script>

<style scoped lang="less">
@import './index.less';

.aspect-ratio-box {
  position: relative;
  border-radius: 12.5% 12.5% / 9.25% 9.25%;
  overflow: hidden;
  background: var(--color-bg-200);
  height: 100%;
  display: flex;

  &::before {
    content: '';
    display: block;
    position: relative;
    padding-bottom: 135%;
  }

  .game-img {
    position: absolute;
    inset: 0;
    z-index: 1;
    object-fit: fill;

    &::part(image) {
      border: none;
    }

    &.good-img {
      width: 100%;
      height: 100%;

      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 10.41%, rgba(0, 0, 0, 0) 18.5%, rgba(0, 0, 0, 0) 100%);
      }
    } 

    &.error-img {
      width: 45%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }

  .game-status {
    position: absolute;
    inset: 0;
    z-index: 3;
    width: 0;

    &.good-img {
      width: 100%;
    }
  }
  
  .tool {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    padding: 0.25rem;
    display: flex;
    justify-content: flex-end;

    .logo {
      font-size: 1.375rem;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .favorite {
      width: 0;

      &.good-img {
        width: 1.5rem;
      } 
    }
  }
}

.frame {
  border: 1px solid red;
}

.game-name {
  position: absolute;
  bottom: 0;
  .flex-center();
  text-align: center;
  font-size: 0.75rem;
  color: var(--color-text-100);
}

/* 收藏点击动画 */
.animate-star {
  animation: scale 500ms 1 ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.15);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

</style>
