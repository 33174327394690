import { defineStore } from 'pinia'

export const useRegchargeStore = defineStore({
  id: 'regcharge',
  state: () => ({
    typeId: 0,
    subTypeId: 0,
    QRCodeInfo:{
      orderNo: '',
      QRCode: '',
      expireTime: 0,
      createTime: '',
      amount: ''
    }
  })
})
