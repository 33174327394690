<template>
  <div class="flex flex-col items-center justify-center" :class="`${setEmptyTheme}-empty`">
    <ion-icon class="text-[14.5rem]" :src="`/first/svg/empty/msg-${theme}.svg`" v-if="type === 'msg'"/>
    <!-- <div style="color:red" class="empty-search h-[12.5rem] w-[15.375rem] flex items-center justify-center" v-else>
      <ion-icon class="text-9xl" :src="`/first/svg/empty/search-${theme}.svg`"/>
    </div> -->
    <div style="color:red" class="h-[12.5rem] w-[15.375rem] flex items-center justify-center relative" v-else>
      <ion-icon class="empty-svg h-full w-full absolute top-0 left-0" :src="'/first/svg/empty/search_bg.svg'"/>
      <ion-icon class="text-9xl" :src="`/first/svg/empty/search-${setEmptyTheme}.svg`"/>
    </div>
    <ion-label  class="text-[--color-text-empty]" >{{ text || $t('label.noRecord') }}</ion-label>
  </div>
</template>

<script setup lang="ts">
import { getTheme } from '@/theme/hooks'
import { IonIcon, IonLabel } from '@ionic/vue';

const { theme } = getTheme();

interface Props {
  type?: string
  text?: string
  specifyTheme?: string
}

const props = defineProps<Props>()
const setEmptyTheme = computed(() => {
   if(props.specifyTheme) return props.specifyTheme
   if(theme == 'auroral-yellow') return 'yellow-dark'
   return theme
})
</script>

<style scoped lang="less">
div.empty-search {
  background: url('/first/svg/empty/search_bg.svg') no-repeat center;
}

.empty-svg {
  color: var(--empty-icon-color);
}

.empty-svg-style(@color) {
  .empty-svg {
    color: @color;
  }
  
}

.blue-default-empty{
  .empty-svg-style(#101629);
}
.green-default-empty{
  .empty-svg-style(#274116);
}
.green-dark-empty{
  .empty-svg-style(#262624);
}
.yellow-dark-empty{
  .empty-svg-style(#262624);
}

.purple-light-empty{
  .empty-svg-style(#ccb2ff);
}
.amber-purple-empty{
  .empty-svg-style(#7a3fe8);
}


</style>
