
import { SD_MODEL } from '@/enums/device'
import { useTenantStore } from '@/store/tenant';
import { handleInstallAction, iosInstallAction } from "@/pwa/hooks/useInstallPWAOrAPK"
import type { InstallPWAOrAPKParams } from "@/pwa/hooks/useInstallPWAOrAPK"

export default function useAppInstallLogic() {

  const statusStore = useStatusStore()    // 状态
  const systemStore = useSystemStore()    // 系统
  const channelStore = useChannelStore()  // 渠道
  const tenantStore = useTenantStore(); // 租户信息
  const appLogo = computed(() => tenantStore.tenantInfo?.logo);


  // 生命周期-页面挂载前
  onBeforeMount(() => {
    channelStore.requestMainChannelInstallInfo()
  })

  /**
   * @description 苹果安装操作
   */
  function appleHandle() {
    if (systemStore.app?.build !== SD_MODEL.IOS_H5) return;
    iosInstallAction()
  }

  /**
   * @description 安卓安装操作
   */
  async function androidHandle() {

    if (systemStore.app?.build === SD_MODEL.IOS_H5) return;
    const params: InstallPWAOrAPKParams = {
      PWACb: () => {
        statusStore.setHomeInstallModalVisible(true);
      }
    }
    handleInstallAction(params)

  }

  return {
    appleHandle,
    androidHandle,
    appLogo
  }
}
