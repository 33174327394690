import { computed } from "vue";
import { useI18n } from "vue-i18n";

export function useAgentNewestLogic() {
  const { locale } = useI18n();
  const className = computed(() =>
    locale.value === "zh-CN" ? "w-1/2" : "w-full"
  );
  const avatarStyle = {
    "margin-left": "1.5rem",
  };
  
  const agentBColor = "#005dfe";
  const agentColor = "#04be02";
  
  //  生成代理对象数据的函数
  const generateAgentObj = (
    color: string,
    agentlevel: string,
    betAmount: number,
    commission: number
  ) => ({ color, agentlevel, betAmount, commission });
  
  const agentB1 = generateAgentObj(agentBColor, "B1", 500, 15);
  const agentB2 = generateAgentObj(agentBColor, "B2", 3000, 90);
  const agentB3 = generateAgentObj(agentBColor, "B3", 2000, 60);
  const agentC1 = generateAgentObj(agentColor, "C1", 1000, 10);
  const agentC2 = generateAgentObj(agentColor, "C2", 2000, 20);
  const agentC3 = generateAgentObj(agentColor, "C3", 20000, 600);

  return {
    className,
    avatarStyle,
    agentB1,
    agentB2,
    agentB3,
    agentC1,
    agentC2,
    agentC3,
  }
}
