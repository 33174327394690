<!-- 游戏分类列表 -->
<template>
  <ion-page class="category-container">
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t(`sort.${gameParams.gameType}`) }}</ion-title>
      </ion-toolbar>
      <!-- 搜索框 -->
      <ion-toolbar class="toolbar-search">
        <ion-searchbar mode="md" class="relative" id="searchbar" v-model="gameParams.gameName" show-clear-button="never"
          :placeholder="$t('sort.SEARCH') + $t('main.games')">
          <ion-spinner name="bubbles"
            v-if="loading && gameParams.page == 1" />
          <ion-icon :icon="searchOutline" @click="searchHandle" v-else />
        </ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content id="content">
      <div class="container">
        <!-- 侧面栏 -->
        <ion-content class="side">
          <ion-segment ref="segment" mode="ios" v-model="sideValue" :disabled="loading"
            @ionChange="sideChange">
            <ion-segment-button :value="0">
              <ion-img :class="[sideValue == 0 ? 'active' : '']"
                :src="`/icons/sort/${gameParams.gameType}_on.png`" />
              <ion-label :class="[sideValue == 0 ? 'active' : '', 'label line-clamp-2']">{{
                $t('main.all') + $t(`sort.${gameParams.gameType}`) }}</ion-label>
            </ion-segment-button>
            <ion-segment-button v-for="item in segmentList" :value="item.id">
              <ion-icon :class="[sideValue == item.id ? 'active' : '']" :src="item.logo"/>
              <ion-label :class="[sideValue == item.id ? 'active' : '', 'label line-clamp-2']">{{ item.name }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-content>
        <!-- 主屏内容 -->
        <ion-content id="main" :scrollY="false">
          <!-- 导航标签 -->
          <ion-segment class="tab-bar" :mode="tabsMode" :scrollable="true" v-model="tabValue" :disabled="loading" @ionChange="tabChange">
            <ion-segment-button v-for="item in tabs" :value="item" :key="item" class="btn">
              <ion-label>{{ $t(`sort.${item}`) }}</ion-label>
            </ion-segment-button>
          </ion-segment>
          <ion-content ref="contentRef">
            <ion-list>
              <!-- 骨架屏 -->
              <ion-grid v-if="loading && gameParams.page == 1">
                <ion-row>
                  <ion-col size="4" v-for="_item in 18">
                    <div class="skeleton-card"><ion-skeleton-text /></div>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <!-- 游戏列表 -->
              <ion-grid v-else>
                <div class="empty" v-if="!loading && !gameList.length">
                  <div class="empty-bg-game"></div>
                  <ion-label class="tip">{{ $t('label.noGame') }}</ion-label>
                </div>
                <ion-row>
                  <ion-col class="card-col" size="4" v-for="item in gameList">
                    <GameCard @click="gameHandle(item)" @favoriteHandle="() => favoriteHandle(item)"
                      v-bind="{ isOwnGame: !!item.externalGameId, cardBgStyle: gameCardStyle(item), platformLogo: platformList[Number(item.platformId)], isFavorite: (item?.isFavorite || false), status: forGameStatus(item), isShowPlatformLogo: !sideValue }" >
                      <template #gameName v-if="!item.logo && item.externalGameId">
                        <span>{{item.name}}</span>
                      </template>
                    </GameCard>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-list>
            <ion-infinite-scroll ref="infiniteRef" @ionInfinite="ionInfinite" threshold="5px">
              <ion-infinite-scroll-content class="text-xs"
                :loading-text="loadMore == 'noMore' ? $t('label.noMore') : ''"
                :loading-spinner="loadMore == 'more' ? 'bubbles' : null" />
            </ion-infinite-scroll>
          </ion-content>
        </ion-content>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useAppStore } from '@/store/app';
import { gameListApi } from '@/api/normal';
import { useUserStore } from '@/store/user';
import { useGameStore, TGameInfo } from '@/store/game';
import { useTenantStore } from '@/store/tenant';
import { searchOutline } from 'ionicons/icons';
import { setFavorite } from '@/hooks/SetFavorite';
import { FavoriteListParams } from '@/api/personal/model';
import { GameListParams, HotParams } from '@/api/normal/model';
import { onBeforeMount, onMounted, reactive, ref, computed } from 'vue';
import { IonPage, IonHeader, IonToolbar, IonList, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonContent, IonSegment, IonSegmentButton, IonImg, IonTitle, IonSearchbar, IonIcon, IonSpinner, IonGrid, IonRow, IonCol, IonSkeletonText, InfiniteScrollCustomEvent } from '@ionic/vue';
import useGetFavoriteGame from '@/hooks/useGetFavoriteGame';
import useGetRecentGame from '@/hooks/useGetRecentGame';
import BackButton from '@/components/BackButton.vue';
import GameCard from '@/components/GameCard.vue';
import useGameStatus from '@/views/tabbar/tabs/inicio/components/GameWrapper/useGameStatus';


const router = useRouter();       // 路由对象
const appStore = useAppStore();   // 应用信息
const gameStore = useGameStore(); // 游戏信息
const userStore = useUserStore(); // 用户信息

const contentRef = ref()        // content实例
const sideValue = ref(0);       // 侧边导航标签动态值
const loading = ref(true);      // 搜索框加载动画
const infiniteRef = ref();      // 触底加载更多组件
const tabValue = ref('ALL');    // 导航标签动态值
const loadMore = ref('more');   // 加载更多状态
const gameList = ref<any>([]);  // 游戏列表
const { forGameStatus } = useGameStatus();


const platformList = computed(() => gameStore.platformList);  // 平台列表
const segmentList = computed(() => {                          // 平台列表
  let list: any[] = [];
  gameStore.homeGames.forEach((item: any) => {
    if (item.gameType == gameParams.gameType) {
      item.platformList.forEach((platform: any) => {
        if (list.some((item: any) => item.id == platform.id)) return;
        list.push(platform);
      })
    }
  })
  return list.sort((a, b) => b.sort - a.sort);
});

const gameParams = reactive<GameListParams>({                                                                 // 游戏列表请求参数
  gameType: router.currentRoute.value.params.gameType.toString().toUpperCase() as GameListParams['gameType'], // 获取路由动态参数
  platformId: Number(router.currentRoute.value.params.platformId),                                            // 获取平台ID(路由URL参数)
  gameName: '',
  page: 1,
  pageSize: 30,
})
const hotParams = reactive<HotParams>({                                                                       // 热门游戏列表请求参数
  gameType: router.currentRoute.value.params.gameType.toString().toUpperCase() as GameListParams['gameType'], // 获取路由动态参数
  platformId: Number(router.currentRoute.value.params.platformId),                                            // 获取平台ID(路由URL参数)
  gameName: '',
  page: 1,
  pageSize: 100,
  isHot: true,
});
const favoriteListParams = reactive<FavoriteListParams>({                                                     // 收藏列表请求参数
  gameType: router.currentRoute.value.params.gameType.toString().toUpperCase() as GameListParams['gameType'], // 获取路由动态参数
  page: 1,
  pageSize: 1000
});

const tabs = ['ALL', 'POPULAR', 'RECENT', 'FAVORITE'];              // 导航标签列表

gameParams.platformId && (sideValue.value = gameParams.platformId); // 设置侧边导航标签动态值

const tenantStore = useTenantStore();  // 租户信息
const tabsMode = computed(() => {
  const { skin, theme, home } = tenantStore.themeConfig || {};
  const themeKey = `${skin}-${theme + (home ? ('-' + home) : '')}`;
  return ['default-amber-purple'].includes(themeKey) ? 'md' : 'ios';
});      // 导航标签模式
// 生命周期-页面加载前
onBeforeMount(() => {
  gameStore.loadHomeGames();  // 载入首页游戏列表
})

// 生命周期-页面加载完成
onMounted(async () => {
  await useGetFavoriteGame(favoriteListParams)
  gameStore.getHomeGames();                   // 获取最新首页游戏列表
  onGetGame();
})

/**
 * @description 侧边栏标签切换事件
 * @param event 事件对象
 */
async function sideChange(event: any) {
  loading.value = true;
  await contentRef.value.$el.scrollToTop(500)                     // 500毫秒回到顶部
  gameParams.platformId = hotParams.platformId = event.detail.value;
  gameParams.page = 1;
  gameList.value = [];
  gameParams.gameName = '';
  tabHandle(tabValue.value, true);
}

/**
 * @description 搜索框输入事件
 */
function searchHandle() {
  if (!gameParams.gameName) return;
  gameParams.page = 1;
  gameList.value = [];
  tabValue.value = 'ALL';
  onGetGame();
}

function tabChange(event: any) {
  loading.value = true;
  tabHandle(event.detail.value);
}
/**
 * @description 导航标签切换事件
 * @param item 导航标签值
 * @param segment 是否触发侧边导航标签切换
 */
function tabHandle(item: string, segment?: boolean) {
  gameList.value = [];
  gameParams.gameName = '';
  gameParams.page = 1;
  gameParams.gameName = '';
  hotParams.page = 1;
  if (item == 'ALL') {
    onGetGame();
  } else if (item == 'POPULAR') {
    onGetHotGame();
  } else if (item == 'RECENT') {
    onGetRecentGame();
  } else if (item == 'FAVORITE') {
    onGetFavorite();
  }
}

/**
 * @description 游戏卡片样式
 */
function gameCardStyle(item: any) {
  if (item.gameName || item.name) {
    const gradient = '360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 16.23%, rgba(0, 0, 0, 0) 29.9%, rgba(0, 0, 0, 0) 100%'
    const logo = item.logo || item.gameLogo;
    return `background:linear-gradient(${gradient}),url("${logo}"); background-size: cover; background-position: center`
  }
  return `background: url(${item.background || item.plateformBackground}); background-size: cover; background-position: center`
}

/**
 * @description 收藏事件
 * @param item 游戏对象
 */
function favoriteHandle(item: any) {
  item.isFavorite = !item.isFavorite;
  item.type = 'game';
  if (item.isFavorite)
    userStore.addFavorite(item);
  else {
    userStore.cancelFavorite(item);
    if (tabValue.value == 'FAVORITE') {
      gameList.value.splice(gameList.value.indexOf(item), 1);
    }
  }
}

/**
 * @description 触底加载更多事件
 */
async function ionInfinite(event: InfiniteScrollCustomEvent) {
  setTimeout(() => {
    if (loadMore.value == 'noMore' || gameList.value.length == 0) return;
    onGetGame();
  }, 500)
}

/**
 * @description 游戏跳转
 */
function gameHandle(item: any) {
  return gameStore.enterGame(item)
}

/**
 * 接口调用-获取游戏列表
 */
async function onGetGame() {
  loading.value = true;
  if (gameParams.platformId == 0) gameParams.platformId = undefined;
  const res = await gameListApi(gameParams);
  if (res && 'gameList' in res) {
    const newGameList = res.gameList;
    setFavorite(newGameList);
    if (newGameList.length < gameParams.pageSize!) {
      loadMore.value = 'noMore';
    } else {
      loadMore.value = 'more';
      gameParams.page!++;
    }
    gameList.value = gameList.value.concat(newGameList);
  }
  loading.value = false;
  infiniteRef.value.$el.complete();
}

/**
 * 接口调用-获取热门游戏列表
 */
async function onGetHotGame() {
  loading.value = true;
  loadMore.value = 'loading';
  const res = await gameListApi(hotParams);
  if (res && 'gameList' in res) {
    setFavorite(res.gameList);
    if (res.gameList.length < hotParams.pageSize!) {
      loadMore.value = 'noMore';
    } else {
      loadMore.value = 'more';
      hotParams.page!++;
    }
    gameList.value = gameList.value.concat(res.gameList);
  }
  loading.value = false;
}

/**
 * 接口调用-获取最近游戏列表
 */
async function onGetRecentGame() {
  gameList.value = await useGetRecentGame({ platformId: gameParams.platformId, gameType: `${gameParams.gameType}` })
  loading.value = false;
}

/**
 * 接口调用-获取收藏列表
 */
async function onGetFavorite() {
  loading.value = true;
  loadMore.value = 'loading';
  let favoriteList = await useGetFavoriteGame(favoriteListParams)
  gameList.value = gameParams.platformId ? favoriteList.filter((it: any) => it.platformId == gameParams.platformId) : favoriteList
  loading.value = false;
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
