<script setup lang="ts">
import {
  IonPage,
  IonContent,
  IonHeader,
} from '@ionic/vue'
import AssetsHeader from '@/components/first/AssetsHeader.vue'
import AssetsFooter from '@/components/first/AssetsFooter.vue'
import useWLogic from '../hooks/useLogic'
import { useElementBounding } from '@vueuse/core'
import AssetsTab from '../../comp/AssetsTab.vue'
import Input from '@/components/first/Input/index.vue'
import { WithdrawPageType } from '@/hooks/useWithdraw'
import FormInfo from '../comp/withdrawalMethod/First.vue'
import BindAccount from '../comp/BindAccount.vue'
import BalanceArea from '../comp/BalanceArea.vue'
import AmountBtnList from '../comp/AmountBtnList.vue'
import FeeTip from '../comp/FeeTip.vue'
import ConfirmModal from '../comp/ConfirmModal.vue'
import WithdrawHint from '../comp/WithdrawHint.vue'
import { getTheme } from '@/theme/hooks';
import { formEl, accountEl, subTabCode } from '@/views/withdraw/main/hooks/useUserInputData'
const { theme } = getTheme();
const { t } = useI18n() // 国际化

const footEl = ref(null)

const {
  amountInput,
  rateValue,
  isHasBottomTab,
  tabList,
  tabId,
  merchantCy,
  balance,
  isDemo,
  prompt,
  betAmount,
  readonly,
  placeholder,
  amountList,
  withdrawalConfig,
  remind,
  assets,
  rateType,
  hintIndex,
  demoWithdraw,
  confirmModalVisible,
  subTabList,
  subTabId,
  maxAmount,
  minAmount,
  tabTagInfo,
  accountList,
  receiveAmount,
  bindAccountMax,
  btnText,
  recordHandle,
  mainTabChange,
  amountInputChange,
  amountHandle,
  subTabChange,
  modalDismiss,
  passwordHandle,
  onWithdrawCreate,
  handleBtnClick,
} = useWLogic()

const setMaxValue = () => {
  const floorBalance = (balance: string | number) => {
    return Math.floor(Number(balance)).toString()
  }
 amountInput.value = maxAmount.value > balance.value ? `${floorBalance(balance.value)}` : `${floorBalance(maxAmount.value)}`

}
const { height }
  = useElementBounding(footEl)
const distance = computed(() => `${((height.value) * (isHasBottomTab.value ? 1.1 : 3)) / 16}rem`)
const btnDisabled = computed(() => {
  if (isDemo.value) return false
  if (Number(amountInput.value) <= 0) return false
  const disabledConditions = {
    'demo': () => isDemo.value,
    'amount': () => Number(amountInput.value) <= 0,

  }
  const disabled = Object.values(disabledConditions).some(condition => condition())
  if (disabled) return false
  if (withdrawalConfig.value === 'BindFirst' && !accountList.value.length) return true
  return Number(amountInput.value) < Number(minAmount.value)


})





</script>
<template>
  <IonPage>
    <IonHeader mode="ios">
      <AssetsHeader :contentText="`${t('main.withdraw')}`" :isShowCloseicon="!isHasBottomTab"
        @recordHandle="() => recordHandle(WithdrawPageType.WITHDRAW_RECORD)" />
    </IonHeader>

    <ion-content :class="['first-content', theme]">
      <div class="header-bottom-area">
        <div class="line"></div>
        <AssetsTab :tabList="tabList" v-model:tab-id="tabId" type="main-tab"
          :title="$t('viewsAssets.WithdrawalChannels')" activeBgColor="--color-bg-100" @tabChange="mainTabChange" />
        <BalanceArea :merchantCy="merchantCy" :betAmount="betAmount" :balance="balance" :isDemo="isDemo"
          :prompt="prompt" @recordHandle="recordHandle(WithdrawPageType.WITHDRAW_AUDIT)" />
        <div class="input-wrap">
          <Input type="withdraw" v-model="amountInput" :readonly="readonly" :placeholder="placeholder"
            @setMaxValue="setMaxValue" @input="amountInputChange" :error-text="$t('toast.0003')" />
        </div>
        <AmountBtnList :amountList="amountList" :amount="amountInput" @amountHandle="amountHandle" :balance="balance" />
        <FeeTip :amountInput="amountInput" :merchantCy="merchantCy" :rateValue="rateValue" :remind="remind" />
      </div>


      <template v-if="withdrawalConfig === 'BindFirst'">

        <BindAccount :accountList="accountList" :bindAccountMax="bindAccountMax" ref="accountEl" />
      </template>
      <template v-else>
          <div class="m-b">
            <AssetsTab :title="$t('label.withdrawSubType')" :tabList="subTabList" v-model:tab-id="subTabId"
              :tagInfo="tabTagInfo" @tabChange="subTabChange" type="sub-tab" />
          </div>
          <FormInfo :dynamicType="subTabCode" ref="formEl" :typeId="tabId" />
      </template>

    </ion-content>
    <AssetsFooter :merchantCy="merchantCy" :disabled="btnDisabled" ref="footEl" :textContent="$t('viewsAssets.receive')"
      :isHasBottomTab="isHasBottomTab" :btnText="btnText" @submitForm="handleBtnClick" />
    <!-- 确认提现弹窗 -->
    <ConfirmModal v-model:visible="confirmModalVisible" :merchantCy="merchantCy" :amountInput="amountInput"
      :rateValue="rateValue" :rateType="rateType" :receiveAmount="receiveAmount"
      :passwordSwitch="assets?.passwordSwitch" @passwordHandle="passwordHandle" @onWithdrawCreate="onWithdrawCreate" />



    <!-- 体验金无法提现弹窗  -->
    <WithdrawHint v-model:visible="demoWithdraw" :hintIndex="hintIndex" />

  </IonPage>
</template>

<style scoped lang="less">
.header-bottom-area {
  width: 100%;
  .bg-200();
  padding: 0 .75rem;
  .rounded-large-b();

  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 12px;
    background-color: var(--color-border);
  }

  .input-wrap {
    height: 2.875rem;
    width: 22.875rem;
    margin-bottom: 1.5rem;
    .bg-400();
    .rounded-small();

  }

}

ion-content.first-content {
  --background: var(--color-bg-300);
  --padding-bottom: v-bind('distance');
}

.m-b {
  margin-top: 1rem;
  margin-bottom: .75rem;
  padding-left: .75rem;
}


.purple-light {
  .header-bottom-area {
    background-color: var(--color-bg-500);
  }
}

.amber-purple {
  .header-bottom-area {
    background-color: var(--color-bg-100);
  }
}
</style>
