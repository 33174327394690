<!-- 自定义活动 -->
<template>
  <ion-page>
    <ion-header>
      <!-- 顶部导航栏 -->
      <ion-toolbar class="" mode="ios">
        <BackButton />
        <ion-title>  <div class="text-container" ref="textRef" :style="titleStyle"> {{ activityName }}</div></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="space-y-3 ion-padding">
      <div v-html="content" class="text-[--color-text-second]">
      </div>
      <div v-if="showApplyBtn" class="flex items-center justify-center">
        <ion-button class="text-sm apply" @click="applyHandle">{{ $t('activity.custom1') }}</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle, IonButton } from '@ionic/vue'
import BackButton from '@/components/BackButton.vue'
import useLogic from '../logic'

const textRef = ref(null);      
const isTwoLines = ref(false);

// 计算属性，动态调整字体大小
const titleStyle = ref({
  fontSize: "1.25rem",
  lineHeight:"1.875rem"
});

// 计算是否是两行
const checkLines = () => {
  if (!textRef.value) return;
  const element = textRef.value;
  isTwoLines.value = element.scrollHeight > parseFloat(getComputedStyle(element).lineHeight) * 1.5; // 超过一行半就算两行
  titleStyle.value.fontSize = isTwoLines.value ? "0.875rem" : ""; // 动态修改字体
  titleStyle.value.lineHeight = isTwoLines.value ? "1rem" : ""; // 动态修改字体
};


const {
  activityName,
  content,
  applyHandle,
  showApplyBtn
} = useLogic()

// **监听 activityName 变化，并立即触发检查**
watch(activityName, async () => {
  await nextTick(); // 确保 DOM 更新后计算
  checkLines();
}, { immediate: true }); // **确保初次渲染时就执行一次 checkLines()**

</script>

<style scoped lang="less">
  // 基础公共 less
  @import "@/views/activity/custom/default/style/base-index.less";
  @import "@/views/activity/custom/default/style/theme-style.less";

  #activity-custom-default-index.style();

	.blue-default {
    #activity-custom-default-index.style(
      --theme-color-gradient-100,
      --theme-color-gradient-100,
      --border-radius,
      --line-color,
      --color-text-gray-200
    );
	}

	.auroral-yellow {
    #activity-custom-default-index.style(
      --theme-color-800,
      --theme-color-800,
      --border-radius,
      --line-color,
      --color-text-black-100,
      --color-text-black-100
    );
	}

	.green-default {
    #activity-custom-default-index.style(
      --theme-color-gradient-100,
      --theme-color-gradient-100,
      --border-radius,
      --line-color,
      --color-text-gray-200
    );
	}

  .amber-purple {
    #activity-custom-default-index.style(
      --segment-gradients-purple,
      --segment-gradients-purple,
      --border-radius,
      --line-color,
      --text-color-light-purple-2-100
    );
  }
  .text-container {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制最多 2 行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: normal; /* 允许换行 */
}
</style>
