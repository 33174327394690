import router from "@/router";
import { uesShowForceModal } from "./useShow"
export const announcementModal = async () => {
  const userStore = useUserStore();
  const tenantStore = useTenantStore();
  const route = router.currentRoute;
  const { isAppUser } = toRefs(userStore);

  const checkMap = new Map<string, () => boolean | Promise<boolean>>([
    ['isInHome', () => route.value.path === '/main/inicio'],
    ['hasAnnouncement', async () => {
      const announcementList = await tenantStore.getAnnouncementList();
      return announcementList.length > 0;
    }]
  ]);

  for (const check of checkMap.values()) {

    const result = await check();
    if (!result) return false;
  }
  uesShowForceModal('announcement')
  return true;

}
