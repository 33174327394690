import { number } from "zod";




export function hide(text: number | string) {
  text = String(text)
  const texProfix = text.slice(0, 3);
  let textSuffix = '';
  if (text.includes('@')) {
    const textIndex = text.indexOf('@');
    textSuffix = text.slice(textIndex);
  } else {
    textSuffix = text.slice(-4);
  }
  return texProfix + '****' + textSuffix;
}

export function hidePhone(phone: number | string | undefined | null) {
  if (!phone) return '';
  const phoneBefore = phone.toString().slice(0, 3);
  const phoneAfter = phone.toString().slice(-4);
  return phoneBefore + '****' + phoneAfter;
}


/**
 * @description:  加密字符串中间的字符，只留前后的位置
 * @param {string | number} text  加密字符串
 * @param {number} digit   前后截取的位置 默认值是2
 * @return {string}  返回加密后的字符串
 */
export const encryptionText = (text: string | number, digit: number = 2): string => {

  let newText = String(text)

  //   要截取的字符长度 >= 原字符串的长度  返回原字符串
  if (digit * 2 >= newText.length) return newText

  return `${newText.substring(0, digit)}**${newText.substring(newText.length - digit)}`

}
