import router from '@/router'
import { delay } from '@/utils/delay';
import { showLoading } from '@/utils/loading';
import { moneyConvertToClient } from '@/utils/custom'
import { getLocalTime } from '@/utils/date';
import { payAgainApi, payConfirmApi, payRecordListApi } from '@/api/assets';
import { PayRecordListParams, PayRecordListModel, PayCancelParams } from '@/api/assets/model';
import { handleStatementSelectTime } from '@/utils/reportTime'
import { showToast } from '@/utils'
import dayjs from 'dayjs';
import type { RecordItem } from '../comp/RecordList.vue'
import useCalcAssetStatusInfo from '@/hooks/useCalcAssetStatusInfo'
import { useThreePartyPayment } from '@/views/recharge/hooks/useThreePartyPayment'
import type { PayResultType } from '@/views/recharge/hooks/usePayCreate'
export default () => {
  const tenantStore = useTenantStore();
  const regchargeStore = useRegchargeStore()
  const payUrl = ref('');                                         // 第三方支付地址
  const expireTime = ref(0);                                      // 充值订单过期时间(时间戳)
  const countdown = ref('');                                      // 充值订单过期倒计时(字符串)
  const infiniteRef = ref();                                      // 上拉加载更多组件DOM
  const loading = ref(false);                                     // 加载状态
  const totalAmount = ref('0');                                    // 累计充值金额
  const loadMore = ref('more');                                   // 加载更多状态
  const iframeLoaded = ref(false);                                // iframe加载状态
  const modalVisible = ref(false);                                // 充值详情弹窗是否显示
  const payAgainStatus = ref(['NOT_ARRIVED', 'BE_PAID']);         // 可继续支付状态
  const recordList = ref<RecordItem[]>([]); // 充值记录列表
  let originRecordList: any[] = []; // 充值记录列表
  const changeTime = ref('today');                                // 时间类型
  const success = ['SUCCESS', 'PAID', 'HAVE_ARRIVED'];                  // 成功状态
  const fail = ['MANUALLY-END', 'TIMEOUT', 'CANCEL', 'LIMIT_EXCEEED'];  // 失败状态
  const isQRCode = ref(false)
  const QRCodeInfo = computed(() => regchargeStore.QRCodeInfo)
  const merchantCy = computed(() => tenantStore.tenantInfo?.merchantCy as string)  // 当前商户货币
  const { t } = useI18n() // 国际化
  const payRecordListParams: PayRecordListParams = { // 充值记录列表接口请求参数
    page: 1,
    pageSize: 10
  };

  // 当前商户报表查询时间列表

  const rechargeInfo = computed(() => tenantStore.rechargeInfo);          // 充值信息


  const payAmount = computed(() => rechargeInfo.value?.payAmount > 0 ? Number(rechargeInfo.value.payAmount) : Number(rechargeInfo.value?.amount ?? 0))

  let timer: NodeJS.Timeout;

  watch([() => expireTime.value, () => modalVisible.value], ([val, visible]) => { // 监听充值订单过期时间
    if (!visible && timer) return clearTimeout(timer);
    if (val > 0) {
      const hours = Math.floor(val / 3600);
      const minutes = Math.floor(val % 3600 / 60);
      const remainingSeconds = val % 60;
      countdown.value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
      timer = setTimeout(() => {
        expireTime.value--;
      }, 1000);
    } else if (val == 0) {
      modalVisible.value = false;
      payRecordListParams.page = 1;
      getPayRecordList();
    } else {
      countdown.value = '';
    }
  })

  const timeChange = (name: string) => {
    const dateObj = handleStatementSelectTime(name);
    payRecordListParams.startTime = dateObj?.startTime
    payRecordListParams.endTime = dateObj?.endTime
    payRecordListParams.page = 1;
    loadMore.value = 'more';
    recordList.value = [];
    originRecordList = []
    showLoading();
    getPayRecordList();
  }


  // 生命周期: 组件挂载前
  onBeforeMount(async () => {
    timeChange(changeTime.value)
    tenantStore.getTenantInfo();
  })

  // 生命周期: 离开页面前
  onBeforeRouteLeave(() => {
    iframeLoaded.value = false;
    payUrl.value = '';
  })

  /**
   * @description 充值RUL加载完成事件
   */
  function iframeLoadHandle() {
    iframeLoaded.value = true
  }

  /**
   * @description: 下拉刷新事件
   */
  async function handleRefresh(event: CustomEvent) {
    payRecordListParams.page = 1;
    await getPayRecordList();
    event.detail.complete();
  }

  /**
   * @description 触底加载更多事件
   */
  async function ionInfinite() {
    if (loadMore.value == 'noMore') return;
    await delay(1000);
    payRecordListParams.page! ++;
    getPayRecordList();
  }

  /**
   * @description: 充值详情弹窗事件
   */
  function detailHandle(orderNo: string) {
    expireTime.value = -9;
    const item = originRecordList.find(it => it.orderNo == orderNo);
    tenantStore.rechargeInfo = item;
    expireTime.value = (getLocalTime(item.expireTime) as dayjs.Dayjs).unix() - dayjs().unix();
    modalVisible.value = true;
  }

  /**
   * @description: 充值详情弹窗关闭事件
   */
  function modalCloseHandle() {
    modalVisible.value = false;
    expireTime.value = -9
  }

  /**
   * @description 充值完成事件
   */
  function finishHandle() {
    modalVisible.value = false;
    onConfirmOrder();
  }


  const calcStatus = useCalcAssetStatusInfo(success, fail)

  const processRecordData = (list: any[]) => {
    return list.map((it: any) => {
      const { createTime: time, payTypeName: name, status, amount, payAmount, orderNo } = it;

      return {
        time,
        name,
        orderNo,
        amount: success.includes(status) ? payAmount : amount,
        statusColor: calcStatus(status),
        status: t(`status.${status}`),
      } as RecordItem
    })
  }

  /**
   * @description 调用接口: 获取充值记录列表
   */
  async function getPayRecordList() {
    loading.value = true;
    const res = await payRecordListApi(payRecordListParams);
    loading.value = false;
    if (!res || !('recordList' in res)) return;
    if (res?.totalAmount) {
      totalAmount.value = `${moneyConvertToClient(Number(res?.totalAmount))}`;
    }

    if (res.recordList.length < payRecordListParams.pageSize!) {
      loadMore.value = 'noMore';
    } else {
      loadMore.value = 'more';
    }
    const newRecordList = processRecordData(res.recordList)
    if (payRecordListParams.page == 1) {
      recordList.value = newRecordList;
      originRecordList = res.recordList;
    } else {
      recordList.value.push(...newRecordList);
      originRecordList.push(...res.recordList);
    }
    infiniteRef.value.$el.complete();

  }

  /**
   * @description 调用接口: 继续支付
   */
  async function continuePayHandle() {
    showLoading();
    const result = await payAgainApi({ orderNo: rechargeInfo.value.orderNo}) as PayResultType; 
    if (result && 'message' in result) {
      showToast(result.message ?? '');
    }
    if (result && 'payUrl' in result) {
      modalCloseHandle();
      const res = await useThreePartyPayment(result.payUrl, result.redirectType);
      isQRCode.value = res.isQRCode;
      payUrl.value = res.url;
      if (res.isQRCode) {
        console.log((getLocalTime(result.expireTime) as dayjs.Dayjs).unix() - (getLocalTime(result.createTime) as dayjs.Dayjs).unix(), 'result');
        regchargeStore.QRCodeInfo = {
          QRCode: result.payUrl,
          orderNo: result.orderNo,
          expireTime: dayjs(result.expireTime).diff(dayjs(result.createTime), 's'),
          createTime: getLocalTime(result.createTime, 'YYYY-MM-DD HH:mm:ss') as string,
          amount: `${rechargeInfo.value.amount / 100}`,
        }
      } 
    }
  }
  onBeforeRouteLeave(() => {
  
    payUrl.value = ''
    isQRCode.value = false
  
  })
  /**
   * @description 调用接口: 取消订单
   */
  async function cancelOrderHandle() {
    modalVisible.value = false;
  }

  /**
   * @description 调用接口: 确认充值订单
   */
  async function onConfirmOrder() {
    showLoading();
    const res = await payConfirmApi({ orderNo: rechargeInfo.value.orderNo });
    if (res && 'status' in res) {
      showToast(res.status);
    }
    modalVisible.value = false;
    getPayRecordList();
  }






  const orderList = computed(() => [
    { name: `${t('viewsAssets.createTime')}`, value: `${getLocalTime(rechargeInfo.value?.createTime, 'YYYY-MM-DD HH:mm:ss')}` },
    { name: `${t('viewsAssets.transactionType')}`, value: rechargeInfo.value?.payTypeName },
    { name: `${t('viewsAssets.BeneficiaryBank')}`, value: rechargeInfo.value?.transferCompany },
    { name: `${t('viewsAssets.BeneficiaryName')}`, value: rechargeInfo.value?.transferRealName, copy: true },
    { name: `${t('viewsAssets.BeneficiaryAccount')}`, value: rechargeInfo.value?.transferAccount, copy: true },
    { name: `${t('viewsAssets.orderNumber')}`, value: rechargeInfo.value?.orderNo, copy: true },
  ])
  const modalBtnInfo = computed(() => {
    if (!payAgainStatus.value?.includes(rechargeInfo.value?.status)) return { btnText: '', fn: () => { } }
    if (rechargeInfo.value.type == 'TRANSFER') {
      return { btnText: `${t('status.CONFIRM-TRANSFER')}`, fn: finishHandle }
    }


    return { btnText: `${t('label.continuePay')}`, fn: continuePayHandle }

  })

  return {
    payUrl,
    totalAmount,
    loading,
    recordList,
    loadMore,
    iframeLoaded,
    modalVisible,
    rechargeInfo,
    countdown,
    infiniteRef,
    payAmount,
    changeTime,
    orderList,
    modalBtnInfo,
    isQRCode,
    merchantCy,
    QRCodeInfo,
    timeChange,
    cancelOrderHandle,
    handleRefresh,
    detailHandle,
    ionInfinite,
    iframeLoadHandle,
    t,
  }

}



