// 充值活动 逻辑层
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { activityRechargeInfoApi } from '@/api/activity'
import { moneyConvertToClient, getCustomerActivityId } from '@/utils/custom'
import { useI18n } from 'vue-i18n'
import { ActivityRechargeInfoModel, ActivityRechargeRewardItem } from '@/api/activity/model'
import { ZActivityRuleType, ZNameType, ZTActivityTypes, ZAwardType } from '@/enums/types/activity.type'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { useTenantStore } from '@/store/tenant'
import { generateDefultRules, Language, getActivityDefaultName } from '@/i18n/ruleHelper/activityRule'
import { hideLoading, showLoading } from '@/utils/loading'
import { activityApplyApi } from '@/api/activity'
import { isEmpty } from 'lodash-es';
import { isJSONStr } from '@/utils/verify'
import { showPopup } from '@/hooks/ShowPopup'
import { PopupType } from '@/components/Popup/data';

export default function useLogic() {
  const appStore = useAppStore()
  const userStore = useUserStore()
  const tenantStore = useTenantStore()

  const { t } = useI18n()

  const { id: activityId } = getCustomerActivityId()
  const isLoaded = ref(false)
  const activityInfo = reactive({
    name: '',
    awardType: '',
    awardCount: 0,
    description: '',
  })

  const rewardList = ref<ActivityRechargeRewardItem[]>([])
  const showBtnClaim = computed(() => {
    return ZAwardType.enum.ACTIVITY === activityInfo.awardType
  })
  const merchantCy = computed(() => tenantStore.tenantInfo?.merchantCy) // 当前商户货币

  /**
   * @description 生命周期: 页面挂载前
   */
  onBeforeMount(async () => {
    await getActivityConfig()
  })

  const descriptionList = computed(() => {
    if (activityInfo.description) {
      return activityInfo.description.split('\n')
    }
    return []
  });

  async function getActivityConfig() {
    isLoaded.value = false
    const data = (await activityRechargeInfoApi(Number(activityId))) as ActivityRechargeInfoModel
    const { type, nameType, nameParams, name,  rule, ruleType, awardType, awardCount, config } = data

    activityInfo.awardType = awardType
    activityInfo.awardCount = moneyConvertToClient(awardCount)

    const language = (await appStore.getLocale()) as Language
    if (nameType === ZNameType.enum.DEFAULT && isJSONStr(nameParams)) {
        const nameParamsObj = JSON.parse(`${nameParams}`)
        activityInfo.name = getActivityDefaultName(language, type, nameParamsObj.variablesValue)
    }
    else {
      activityInfo.name = name
    }
    if (ruleType === ZActivityRuleType.enum.DEFAULT) {
      if (isEmpty(rule)) return
      const ruleParams = JSON.parse(rule)
      activityInfo.description = generateDefultRules(language, ZTActivityTypes.enum.Recharge, ruleParams.variablesValue)
    }
    else {
      activityInfo.description = rule
    }

    rewardList.value = config.rewardLevels.map((item) => {
      item.conditionAmount = moneyConvertToClient(item.conditionAmount)
      item.rewardAmount = moneyConvertToClient(item.rewardAmount)
      return item
    })
    isLoaded.value = true
  }

  // 领取按钮
  async function claimHandle() {
    if (!activityInfo.awardCount) return
    showLoading()
    const data = await activityApplyApi({
      id: activityId,
      applyInfo: {
        type: ZTActivityTypes.enum.Recharge,
        info: {
          userId: userStore.user?.userId!,
        },
      },
    })
    if (data) {
      showPopup({
        type: PopupType.BONUS,
        msg: t('popup.tips04', { amount: merchantCy.value + convertMoneyToShow(data.awardCount)}),
      })
    }
    await getActivityConfig()
    hideLoading()
  }

  return {
    merchantCy,
    descriptionList,
    activityInfo,
    rewardList,
    showBtnClaim,
    isLoaded,
    claimHandle,
  }
}

export const useComponents = (isDetails?: boolean) => {
  const { defStyle } = getCustomerActivityId();
  const templateList: Record<string, () => Promise<typeof import('*.vue')>> = {
    'style_1': () => import('@/views/activity/recharge/style_1/index.vue'),
  };
  const computedTemplate = templateList[isDetails ? `${defStyle}_detail` : defStyle];
  if (computedTemplate) {
    return markRaw(defineAsyncComponent(computedTemplate)) as unknown as ComponentPublicInstance;
  }
  return null;
}