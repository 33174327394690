<script setup lang="ts">
import {
  IonPage,
  IonIcon,
  IonSpinner,
  IonButton,
  IonContent,
  IonHeader,
} from '@ionic/vue'
import AssetsHeader from '@/components/first/AssetsHeader.vue'
import AssetsFooter from '@/components/first/AssetsFooter.vue'
import useRecharge from '../hooks/useNewRecharge'
import AmountBtnList from '../comp/AmountBtnList.vue'
import ChannelSelect from '../comp/ChannelSelect.vue'
import Input from '@/components/first/Input/index.vue'
import { formatMoneyToShow } from '@/utils/custom'
import NavigationBar from '@/components/NavigationBar/index.vue'
import AssetsOrderModal from '@/components/AssetsOrderModal/index.vue'
import RechargeTab from '../comp/Tab.vue'
import AssetsTab from '@/views/withdraw/comp/AssetsTab.vue'
import BannerArea from '../comp/BannerArea.vue'
import TextTip from '../comp/TextTip.vue'
import AmountInput from '../comp/AmountInput.vue'
import ActivitySwitch from '../comp/ActivitySwitch.vue'
import QRCode from '@/views/recharge/QRCode/index.vue'
const footEl = ref(null)
const elementStore = useElementStore()

const {
  isShowThirdParty,
  isHasBottomTab,
  channelLoading,
  isParticipate,
  thirdUrl,
  tabId,
  tabList,
  description,
  subTabList,
  subTabId,
  amountInput,
  placeholder,
  maxAmount,
  minAmount,
  readOnly,
  channelList,
  channelId,
  amountList,
  isHasBonus,
  activityIsOpen,
  amountOfGift,
  rateOfGift,
  bannerUrl,
  merchantCy,
  withdrawFlowStr,
  limitPlatformList,
  btnLoading,
  iframeLoaded,
  payStatus,
  rechargeInfo,
  orderList,
  countdown,
  modalVisible,
  theme,
  isQRCode,
  QRCodeInfo,
  subTabChange,
  iframeCloseHandle,
  recordHandle,
  mainTabChange,
  amountInputChange,
  amountHandle,
  handleRechargeActivity,
  bannerHandle,
  iframeLoadHandle,
  handleBtnClick,
  cancelHandle,
  finishHandle,
  btnInfo,
  t,
  isDefaultBanner,
} = useRecharge()

const tabBarHeight = computed(() => elementStore.tabBarHeight)
const { height }
  = useElementBounding(footEl)


const distance = computed(() => `${((height.value) * (isHasBottomTab.value ? 1.1 : 3)) / 16}rem`)
//  第三分充值用, 没有充值按钮 
const distance2 = computed(() => `${((tabBarHeight.value + (isHasBottomTab.value ? 18 : 0)) / 16)}rem`)




</script>
<template>
  <IonPage>
    <!-- 链接第三方显示的头部 -->
    <NavigationBar :title="`${t('main.entrar')}`" v-if="isShowThirdParty">
      <template #start>
        <ion-button class="finish" @click="iframeCloseHandle" fill="clear">{{ $t('main.finish')
          }}</ion-button>
      </template>
      <template #end>
        <div class="icon-area flex-center" :class="[theme]"><ion-icon slot="icon-only" class="record"
            @click="recordHandle" src="/first/svg/assets/record.svg" /></div>
      </template>
    </NavigationBar>

    <IonHeader mode="ios" v-else>

      <AssetsHeader :contentText="`${t('main.entrar')}`" :isShowCloseicon="!isHasBottomTab"
        @recordHandle="recordHandle" />


    </IonHeader>
    <IonContent class="recharge-qr-code" v-if="isQRCode">
        <QRCode  v-bind="{...QRCodeInfo, merchantCy: merchantCy || ''}" />
    </IonContent>

    <!-- 充值页面 -->
    <IonContent v-if="!isShowThirdParty" :class="['first-content']">
      

      <RechargeTab :tab-id="tabId" :loading="channelLoading" :tabList="tabList" @tabChange="mainTabChange" />

      <AssetsTab :title="$t('viewsAssets.payment')" :tabList="subTabList" v-model:tab-id="subTabId"
        @tabChange="subTabChange" class="px-12 pay-channe" type="sub-tab" />
        <AmountInput>
          <template #channel>
            <ChannelSelect :list="channelList" v-model:channel-id="channelId" :loading="channelLoading" />
          </template>
          <template #amount>
            <Input v-model="amountInput" type='recharge' :placeholder="placeholder" mode="ios" :noBonus="!isHasBonus"
              :isInteger="true" :readOnly="readOnly" @input="amountInputChange" :maxAmount="maxAmount"
              :minAmount="minAmount" :extra="`${formatMoneyToShow(amountOfGift)}`" />
          </template>
        </AmountInput>
        <TextTip :isParticipate="isParticipate" :limitPlatformList="limitPlatformList"
          :withdrawFlowStr="withdrawFlowStr" :description="description" style="padding: 0 0.75rem;" />

      <AmountBtnList :amountBtnList="amountList" :amountInput="amountInput" @amountHandle="amountHandle" />

      <BannerArea :merchantCy="merchantCy" :bannerUrl="bannerUrl" :amountOfGift="amountOfGift" :rateOfGift="rateOfGift"
        @clickHandle="bannerHandle" :isDefaultBanner="isDefaultBanner" v-show="isParticipate" />

      <ActivitySwitch v-if="activityIsOpen" v-model:isParticipate="isParticipate"
        @handleSwitch="handleRechargeActivity" />


    </IonContent>

    <AssetsFooter v-if='!isShowThirdParty' :merchantCy="merchantCy" :amount="amountInput" :disabled="btnInfo.disabled"
      ref="footEl" :textContent="$t('viewsAssets.TDR')" :isHasBottomTab="isHasBottomTab"
      :btnText="btnInfo.btnText" @submitForm="handleBtnClick" />

    <!-- 第三方iframe页面 -->
    <ion-content id="iframe" :class="['third-content', { hidden: !thirdUrl }]">
      <iframe class="w-full h-full" id="iframe-content" v-if="thirdUrl" v-show="iframeLoaded" :src="thirdUrl"
        @load="iframeLoadHandle" @error="btnLoading = false" frameborder="0" />
      <div class="flex items-center justify-center h-full" v-if="!iframeLoaded">
        <ion-spinner class="w-20 h-20" name="dots" color="light" />
      </div>
    </ion-content>

    <!-- 弹框 -->
    <AssetsOrderModal type="recharge" :status="payStatus" :amount="Number(rechargeInfo?.amount) * 100" :data="orderList"
      :countdown="countdown" :cancelText="payStatus == 'BE_PAID' ? $t('label.cancelOrder') : ''"
      :confirmText="payStatus == 'BE_PAID' ? $t('status.CONFIRM-TRANSFER') : ''" @cancel="cancelHandle"
      @confirm="finishHandle" v-model="modalVisible" />




  </IonPage>
</template>

<style scoped lang="less">
ion-content.first-content {
  --background: var(--color-bg-300);
  --padding-bottom: v-bind('distance');
}

ion-content.third-content {
  --background: var(--color-bg-300);
  --padding-bottom: v-bind('distance2');
}
ion-content.recharge-qr-code{
  --background: var(--color-bg-300);
  --padding-bottom: v-bind('distance2');
  
}


ion-button.finish {
  --color: var(--text-color-white-100)
}

ion-content#iframe {
  --background: #FFF;
}

.icon-area {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  .bg-link();


  ion-icon.record {
    .font-size(--font-size-16);
    .color-text-100();

  }
}





.pay-channe {
  margin-top: .75rem;

}





::-webkit-scrollbar {
  display: none !important;
  width: 0px;
  height: 0px;
}

.icon-area.purple-light {
  background-color: var(--text-color-black-100);
}

.icon-area.amber-purple {
  background-color: var(--theme-color-800);
}
</style>
