<!-- 多级分销 下级数据 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('mlmAgent.nextLevelData') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="subordinateInfo">
        <div class="time">
          <!-- 下属等级选择 -->
          <div 
            class="level"
            @click="dateSelectHandle" 
            id="statement-trigger-level"
          >
            <div class="type">{{ getSubordinateLevel(level) }}</div>
            <ion-icon src="/first/svg/select-icon.svg"  :class="levelPopoverVisible ? 'on' : ''" />
            <!-- 下属等级选择弹出层 -->
            <ion-popover mode="md" class="level-popover" trigger="statement-trigger-level" :isOpen="levelPopoverVisible"
              @didDismiss="levelPopoverVisible = false" size="cover" :showBackdrop="false">
              <ion-row>
                <ion-col size="12" v-for="i in 7" :key="i" @click="selectedLevel(i - 1)">
                  <p 
                    :class="level == i - 1 ? 'selected' : 'unSelected'"
                  >
                    {{ getSubordinateLevel(i-1) }}
                  </p>
                </ion-col>
              </ion-row>
            </ion-popover>
          </div>
          <!-- 日期筛选 -->
          <div class="date">
            <span>{{ $t('activity.selectDate') }}:</span>
            <div class="timeChoose" id="popover-trigger-left">
              <div>{{ timeStart }} </div>
              <ion-icon icon="/svg/spread/calendar.svg" />
            </div>
          </div>
        </div>
        <!-- 下级总数据信息 -->
        <div class="subordinate-info">
          <div v-for="(item,index) in subordinateInfo" :key="index" class="subordinate-item">
            <div class="sub-item-value" v-if="index <= 2">{{ item.value }}</div>
            <div class="sub-item-value" v-else>{{ formatMoneyToShow(item.value) }}</div>
            <div class="icon">
              <ion-icon v-if="item.icon"  :src="item.icon" />
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- 根据ID查询下级数据 -->
        <div class="filter">
          <ion-searchbar 
             v-model="searchValue" id="search-agency"
            :placeholder="$t('mlmAgent.searchPlaceholder')" show-clear-button="never" mod="ios" @input="searchInput">
          </ion-searchbar>
          <ion-icon  icon="/svg/spread/find.svg" @click="searchHandle" />
        </div>
        <!-- 下级数据信息 -->
        <div class="perMes">
          <div 
            v-for='item in subordinateList' 
            :key='item.id'
            class="perCard"
          >
            <div
              class="line">
            </div>
            <div class="perImg">
              <img :src="item.avatar" />
              <div class="idMes">
                <div class="idNum">
                  ID:{{ item.userId }}
                </div>
                <ion-icon src="/svg/spread/copyRight.svg" @click="copy(item.userId)" />
              </div>
            </div>
            <div class="mes">
              <div class="top">
                <div class="left">
                  {{ item.level }}
                </div>
                <div class="right">
                  {{ formatMoneyToShow(item.rechargeAmount) }}
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  {{ $t('mlmAgent.subordLevels') }}
                </div>
                <div class="right">
                  {{ $t('activity.agent32') }}
                </div>
              </div>
              <div class="top">
                <div class="left">
                  {{ formatMoneyToShow(item.betAmount) }}
                </div>
                <div class="right">
                  {{ formatToDateTime(item.registerTime) }}
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  {{ $t('activity.vip38') }}
                </div>
                <div class="right">
                  {{ $t('main.register') }} {{ $t('activity.time') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 暂无数据 -->
        <div class="empty" v-if="showEmpty">
          <div class="img">
            <Empty :text="$t('mlmAgent.noMoreDeta')" />
          </div>
        </div>
         <!-- 触底加载模块 -->
         <ion-infinite-scroll ref="infiniteRef" @ionInfinite="ionInfinite" v-else>
           <ion-infinite-scroll-content :loading-text="loadMore == 'noMore' ? $t('label.noMore') : ''" :loading-spinner="loadMore == 'more' ? 'bubbles' : null"/>
         </ion-infinite-scroll>
      </div>
    </ion-content>
    <!-- 时间选择器 -->
    <ion-popover mode="md" trigger="popover-trigger-left" trigger-action="click" :isOpen="showPopover"
      :showBackdrop="false" @didDismiss="popoverDismiss">
      <ion-datetime 
        presentation="date" 
        mode="ios" 
        :value="dateValueTime" 
        :min="minDate" 
        :max="maxDate" 
        :locale="locale"
        :show-default-buttons="true"
        :cancel-text="$t('main.cancel')"
        :done-text="$t('mlmAgent.btnDone')"
        @ionChange="dateChange" 
        color="rose" 
      />
    </ion-popover>
  </ion-page>
</template>

<script setup lang="ts">
import { copy } from '@/hooks/Copy';
import { chevronDown } from 'ionicons/icons';
import { formatToDateTime } from '@/utils/date.ts'
import { formatMoneyToShow } from '@/utils/custom'
import { useCommissionDetailSpread } from '@/views/mlmAgent/hooks/subordinateInfoSpread'
import { IonPopover, IonInfiniteScroll, IonInfiniteScrollContent, IonIcon, IonDatetime, IonHeader, IonPage, IonRow, IonCol, IonGrid, IonContent, IonSearchbar, IonImg, IonTitle, IonToolbar, IonSelect, IonSelectOption } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';
import Empty from '@/components/Empty/index.vue'

const {
  timeStart,
  level,
  dateValueTime,
  minDate,
  maxDate,
  searchValue,
  levelPopoverVisible,
  showPopover,
  infiniteRef,
  loadMore,
  subordinateInfo,
  subordinateList,
  showEmpty,
  locale,
  popoverDismiss,
  dateChange,
  searchHandle,
  dateSelectHandle,
  getSubordinateLevel,
  selectedLevel,
  ionInfinite,
  searchInput
} = useCommissionDetailSpread();
</script>

<style scoped lang="less">
@import '@/views/mlmAgent/styles/subordinateInfo.less' ;
</style>
