<!-- 浮动标签栏 -->
<template>
  <div 
    ref="tabRef"
    class="segment-container-bg"
    :style="`margin-bottom:${bottom + 16}px;height:calc(${componentHeight}px - env(safe-area-inset-bottom))`"
  >
    <div class="segment-container relative">
      <div class="w-full z-10">
        <ion-segment mode="ios" v-model="tabValue" @ionChange="tabChange">
          <ion-segment-button v-for="item in tabs" :value="String(item.id)" :key="item.code"
            class="relative">
            <div v-if="tabValue == String(item.id)" class="lamp" />
            <ion-img :src="`/icons/platform/${item.code}.png`" />
            <ion-label>{{ item.name }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    IonIcon,
    IonImg,
    IonLabel,
    IonSegment,
    IonSegmentButton,
  } from "@ionic/vue";
  import useLogic from '@/views/tabbar/tabs/inicio/components/Segment/columnPlatformLogic'

  const emits = defineEmits(['tabChange']); // 定义默认传递事件方法(update:modelValue为v-model传值)

  interface Props {     // 定义默认绑定接参
    modelValue: string; // 选中标签值<父组件使用v-model传参>
    sticky: boolean;    // 是否固定
    height: number;     // 高度
    top: string;        // 顶部距离
    bottom: number; // 固定标签栏底部距离
  }

  const props = defineProps<Props>()// 定义默认绑定参数

  const {
    tabs,
    tabRef,
    voiceBug,
    tabValue,
    tabChange,
    componentHeight,
  } = useLogic(props, emits)

  defineExpose({ // 定义默认暴露方法
    voiceBug
  })
</script>

<style scoped lang="less">
  @import './index.less';
</style>