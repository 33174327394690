import { getTheme } from '@/theme/hooks'
export default (successList: string[], failList: string[]) => {
  const { theme } = getTheme()
  const themeColorMap = {
    success: {
      'amber-purple': 'var(--accent-color-green-1)',
      active: 'var(--color-success)'
    },
    fail: {
      active: 'var(--color-danger)'
    },
    warning: {
      active: 'var(--color-warning)'
    }

  }
  type StatusType = keyof typeof themeColorMap
  const getCurrentStatusColor = (status: StatusType) => {
    const statusMap = themeColorMap[status]
    if (theme in statusMap) return statusMap[theme as keyof typeof statusMap]
    return statusMap.active
  }

  return (status: string) => {
    if (successList.includes(status)) return getCurrentStatusColor('success')
    if (failList.includes(status)) return getCurrentStatusColor('fail')
    return getCurrentStatusColor('warning')

  }

}
