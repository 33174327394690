<script setup lang="ts">
import { convertMoneyToShow } from '@/utils/custom'
import { registerRewardInfoApi, registerRewardApplyApi } from "@/api/normal"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonImg,
  IonIcon,
} from '@ionic/vue'
import { meanBy } from 'lodash-es'
import { AppType } from "@/api/normal/model"
import { PopupType } from '@/components/Popup/data';
import { showPopup } from '@/hooks/ShowPopup'
import { emitter } from "@/utils/event";
const RewardTypeList = ['RANDOM', 'THANKS', 'FIXED'] as const
type RewardType = typeof RewardTypeList[number]

interface WheelRewardType {
  rewardType: RewardType,
  rewardValue: number
}

const tenantStore = useTenantStore(); // 租户store
const currentRegionCode = computed(() => tenantStore.tenantInfo?.code); // 租户信息
const router = useRouter()
const route = useRoute()
const { t } = useI18n() // 国际化
const systemStore = useSystemStore()
const userStore = useUserStore();
const merchantCy = computed(() => tenantStore.tenantInfo?.merchantCy)
const prizes = ref<any[]>([])
let awardAmount = 0
let wheelRewardList: WheelRewardType[] = []
const luckyRef = ref()
const deviceTypes = ref("")
const isNotWinner = ref(false)
const isStart = ref(false)
const isSpinning = ref(false)
const multiple = ref(0)
let applyAppTypeList: string[] = []
const getRandomNumber = (num1: number, num2: number) => {
  return Math.floor(Math.random() * (num2 - num1 + 1)) + num1;
}
const startCallback = async () => {
  if (isNotWinner.value) return
  const appType = systemStore.app?.build as AppType
  isSpinning.value = true
  const indexMap = new Map([
    [0, 2],
    [-1, 4]
  ])
  let index = indexMap.get(0)

  if (applyAppTypeList.includes(appType)) {
    console.log(`appType ===${appType}===applyAppTypeList ===${applyAppTypeList}`)
    luckyRef.value.play()
  } else {
    console.log(`${appType}不在applyAppTypeList中==applyAppTypeList ===${applyAppTypeList}`)
  }
  try {
    const response = await registerRewardApplyApi({ appType }) as { awardAmount?: number }
    isStart.value = true
    awardAmount = response?.awardAmount ?? 0
    const awardIndex = awardAmount === 0
      ? 0
      : wheelRewardList.findIndex(item => item.rewardValue === awardAmount)

    isNotWinner.value = awardAmount === 0
    index = indexMap.get(awardAmount) ??
      indexMap.get(awardIndex) ??
      awardIndex ??
      2
  } catch (error) {
    console.error('Register reward error:', error)
    isNotWinner.value = true
    luckyRef.value.stop(index)
    setTimeout(() => {
      isSpinning.value = false
    }, getRandomNumber(800, 1000))
    return
  }



  luckyRef.value.stop(index)
  setTimeout(() => {
    isStart.value = false
  }, getRandomNumber(800, 1000))


}
onMounted(async () => {
  await userStore.setUser()
  isNotWinner.value = !userStore.user?.canApplyRegisterReward
})

const navigate = () => {
  userStore.isAlreadyDisplayRegisterReward = true
  emitter.emit('registerReward/receive-success', {})
  router.push('/main/inicio')
}


onBeforeRouteLeave(() => {
  isNotWinner.value = false
})
const endCallback = () => {
  setTimeout(() => {
    isSpinning.value = false
  }, getRandomNumber(800, 1000))
  if (awardAmount) {
    userStore.user = {
      ...userStore.user,
      canUseRegisterRewardInfo: true
    }
    showPopup({
      type: PopupType.BONUS,
      msg: t('registerReward.000020', {
        amount: merchantCy.value + convertMoneyToShow(awardAmount)
      }),
      leftBtnCallback: navigate
    })
  }

}


const blocks = [{
  imgs: [{
    src: "/registerReward/bg.png",
    width: "100%",
    top: "0%",
    rotate: true
  }]
}]

const getRegisterRewardInfo = async () => {
  const { wheelReward, applyAppType, auditMultiple } = await registerRewardInfoApi() as { wheelReward: WheelRewardType[], applyAppType: string, auditMultiple: number }
  wheelRewardList = wheelReward
  const fontInfo = {
    fontColor: '#fff ',
    fontSize: '12px',
    fontWeight: 700
  }
  multiple.value = auditMultiple
  applyAppTypeList = applyAppType?.split(',') || []
  const genFIXEDData = (size: 'small' | 'large') => {
    const imgMap = {
      small: "/registerReward/bonus.png",
      large: "/registerReward/bonus-large.png"
    }
    return (rewardValue: number) => {
      return {
        imgs: [{
          src: imgMap[size],
          width: size === 'small' ? "40px" : "47px",
          height: size === 'small' ? "30px" : "36px",
          top: "18%"
        }],
        fonts: [{
          text: `${merchantCy.value} ${convertMoneyToShow(rewardValue)}`,
          ...fontInfo,
          top: "52%"
        }]
      }
    }
  }

  const wheelRewardMap: ReadonlyMap<Omit<RewardType, 'FIXED'>, any> = new Map([
    ['RANDOM', {
      imgs: [{
        src: "/registerReward/gift_box.png",
        width: "53px",
        height: "48px",
        top: "18%"
      }]
    }],
    ['THANKS', {
      fonts: [{
        text: 'THANKS',
        ...fontInfo,
        top: "22%"
      }]
    }]
  ])

  const deviceTypeMap = new Map([
    ['iOSH5', t("registerReward.000012")],
    ['AndroidH5', t("registerReward.000013")],
    ['PWA', t("registerReward.000014")],
    ['APK', t("registerReward.000015")],
    ['iOSApp', t("registerReward.000016")],
    ['iOSBookmark', t("registerReward.000017")],
    ['APKRelease', t("registerReward.000018")],
    ['DesktopOS', t("registerReward.000019")],
  ])
  deviceTypes.value = `${applyAppTypeList.map(item => deviceTypeMap.get(item as string) ?? '').join(",")}`

  const getFIXEDSmallRewardValue = genFIXEDData('small')
  const getFIXEDLargeRewardValue = genFIXEDData('large')
  const meanRewardValue = meanBy(wheelReward.filter(item => item.rewardType === 'FIXED'), 'rewardValue')

  prizes.value = wheelReward.map(item => {
    const { rewardType, rewardValue } = item
    if (rewardType !== 'FIXED') {
      return wheelRewardMap.get(rewardType)
    }
    return rewardValue > meanRewardValue ? getFIXEDLargeRewardValue(rewardValue) : getFIXEDSmallRewardValue(rewardValue)
  })
}



watch(() => route.path, (newPath) => {
    if (newPath == '/subscribeReward' ) {
      getRegisterRewardInfo()
    }
  
  }, { immediate: true })

const buttons = computed(() => [{
  radius: '30%',
  fonts: [],
  imgs: [{
    src: `/registerReward/${isNotWinner.value ? 'button-disabled' : 'button'}.png`,
    width: '4.625rem',
    height: '4.625rem',
    top: '-2.3rem'
  }]
}])

</script>

<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">

        <div @click="navigate">
          <ion-icon class="mx-2 arrow-left" slot="icon-only" src="/svg/arrow_left.svg" />
        </div>
        <ion-title class="registerReward-title">{{ $t("registerReward.000001") }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="registration-bonus">
      <div :class="['registerReward-content', { 'spinning': isSpinning }]">

      </div>
      <div class="lucky-Whee-area">
        <div :class="['lighter', { 'lighter-animation': isStart }]"></div>
        <div :class="['lighter-2', { 'lighter-2-animation': isStart }]"></div>
        <div class="pointer-area">
          <img src="/registerReward/pointer.png" alt="">
        </div>


        <div class="lucky-Whee-content">
          <LuckyWheel ref="luckyRef" width="18.25rem" height="18.25rem" :blocks="blocks" :prizes="prizes"
            :buttons="buttons"
            :defaultConfig="{ offsetDegree: -22, speed: 10, accelerationTime: 1800, decelerationTime: 3000 }"
            @start="startCallback" @end="endCallback" />
        </div>
      </div>

      <div class="registerReward-img-area">
        <div class="registerReward-img1">
          <img src="/registerReward/img1.png" alt="">
        </div>
        <div class="registerReward-img2">
          <img src="/registerReward/img2.png" alt="">
        </div>
        <div class="registerReward-img3">
          <img src="/registerReward/img3.png" alt="">
        </div>
      </div>

      <div class="text-desc-area">
        <div class="content-wrapper">
          <div class="title-text">
            {{ $t("registerReward.000004") }}
          </div>
          <ul>
            <li>1: {{ currentRegionCode === "BR" ? $t("registerReward.000005") : $t("registerReward.000021") }}</li>
            <li>2: {{ $t("registerReward.000006", { deviceType: deviceTypes }) }}</li>
            <li>3: {{ $t("registerReward.000007", { multiple: multiple }) }}</li>
            <li>4: {{ $t("registerReward.000008") }}</li>
          </ul>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped lang="less">
ion-header ion-toolbar {
  --background: #262624;
  box-shadow: 0px 4px 28.4px 0px #0000001A;
}

.arrow-left {
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.registerReward-title {
  color: #ffff;
}

.registration-bonus {
  --background: url('/registerReward/content-bg.png') no-repeat center center / cover;
}

.registerReward-content {
  width: 24.375rem;
  height: 24.375rem;
  background: url('/registerReward/di1.png') no-repeat center center / 100% 100%;
  position: absolute;

}

.spinning {
  animation: spin 8s linear infinite;
  /* 动画 */
  animation-fill-mode: forwards
}

.lucky-Whee-area {
  width: 20rem;
  height: 20rem;
  margin: 2rem auto 0;
  position: relative;
  background: url('/registerReward/border.png') no-repeat center center / 100% 100%;

  .lucky-Whee-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18.25rem;
    height: 18.25rem;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .pointer-area {
    width: 2.3125rem;
    height: 1rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  .lighter {
    position: absolute;
    width: 18.25rem;
    height: 18.25rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background: url('/registerReward/guangxiao1.png') no-repeat center center / 100% 100%;
    opacity: 0;
    z-index: -10;
  }

  .lighter-animation {
    z-index: 10;
    animation: lighter .5s;
  }

  .lighter-2 {

    position: absolute;
    width: 18.25rem;
    height: 18.25rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background: url('/registerReward/guangquan1.png') no-repeat center center / 100% 100%;
    z-index: -10;
  }
}

.lighter-2-animation {
  animation: lighter .5s;
  animation-delay: 0.1s;
}

.registerReward-img-area {
  width: 20rem;
  height: 4.8125rem;
  position: relative;
  margin: -2rem auto -3px;

  .registerReward-img1 {
    position: absolute;
    width: 8.125rem;
    height: 4.8125rem;
    top: 0;
    left: 0;
  }

  .registerReward-img2 {
    position: absolute;
    width: 4.125rem;
    height: 2.6875rem;
    bottom: 0.1875rem;
    right: 4.25rem;
    z-index: 10;
  }

  .registerReward-img3 {
    position: absolute;
    width: 5.25rem;
    height: 4.875rem;
    bottom: 0.1875rem;
    right: 0;
  }
}

.text-desc-area {
  width: 21.25rem;
  min-height: 14.5625rem;
  margin: 0 auto;
  position: relative;
  border-radius: 1.25rem;
  padding: 0.25rem;
  background: conic-gradient(from 180deg at 50% 50%,
      #F8F9FD 0deg,
      #86B4D5 78.24deg,
      #D0E2E8 152.94deg,
      #A9CEE9 198.28deg,
      #D0E2E8 242.43deg,
      #86B4D5 311.4deg,
      #F8F9FD 360deg);

  &::before {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    border-radius: 1rem;
    background: linear-gradient(180deg,
        #1B519D 0%,
        #49A7DA 50.5%,
        #1B519D 100%);
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    right: 0.25rem;
    height: 50%;
    border-radius: 1rem 1rem 0 0;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  .content-wrapper {
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 1.6875rem 1.1875rem 1.4375rem;
    color: #fff;

    div:first-child {
      text-align: center;
      font-size: 1.125rem;
      font-weight: 700;
      font-family: Inter;
      margin-bottom: 1rem;
      text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8),
        -4px -4px 8px rgba(255, 255, 255, 0.15);
    }

    ul {
      li {
        font-size: 12px;
        text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5),
          1px -1px 0 rgba(0, 0, 0, 0.5),
          -1px 1px 0 rgba(0, 0, 0, 0.5),
          1px 1px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.light-img {
  width: 19.5625rem;
  height: 19.5625rem;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  transform-origin: center;
}

.wheel-light {
  animation-name: lightAnimation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  pointer-events: none;
}

.wheel-banner {
  pointer-events: none;
}

.footer {
  width: 24.375rem;
  height: 5rem;
  background: #262624;
  border-top: 1px solid #292D36;
  box-shadow: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    /* 起始位置 */
  }

  to {
    transform: rotate(360deg);
    /* 结束位置 */
  }
}



@keyframes lightAnimation {
  0% {
    transform: rotate(0deg);
  }

  49.99% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(22.5deg);
  }

  99.99% {
    transform: rotate(22.5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes lighter {
  0% {
    transform: scale(0.3);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes lighter-2 {
  0% {
    transform: scale(.8);
  }

  100% {
    opacity: 0;
    transform: scale(1.8);
  }
}

.title-text {
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5),
    1px -1px 0 rgba(0, 0, 0, 0.5),
    -1px 1px 0 rgba(0, 0, 0, 0.5),
    1px 1px 0 rgba(0, 0, 0, 0.5);
}
</style>
