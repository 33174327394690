<!-- 兑换弹窗 -->
<template>
  <ion-modal id="modal-exchange" :is-open="modalVisible" @didDismiss="modalDismiss">
    <h5 class="text-center text-[--color-text-basic]">{{ $t('activity.redeem2') }}</h5>
    <RewardCards :rewardCards="rewardCards"/>
    <form class="px-2" @submit="submitForm">
      <p class="text-xs my-3 text-[--color-text-basic] ">{{ $t('viewsActivity.currentlyAvailableForExchange') }} : {{ summation }}</p>
      <!-- 输入兑换个数 -->
      <ion-item class="mb-[10px]">
        <ion-input mode="ios" v-model="amount" type="number" :placeholder="$t('viewsActivity.pleaseEnterTheExchangeAmount')" required :readonly="!summation"/>
        <ion-button class="absolute my-auto text-white right-0 z-10 text-[12px]" fill="clear" @click="setMaxHandle">
          <div class="button-enabled py-1 px-4 flex items-center justify-center rounded-md">
            <p class="text-xs">{{ $t('viewsAssets.max') }}</p>
          </div>
        </ion-button>
      </ion-item>
      <ion-button class="w-full" fill="clear" type="submit">
        <div class="h-[2.1875rem] w-[10rem] flex items-center justify-center rounded-[.625rem]" :class="summation ? 'button-enabled' : 'button-disabled'">
          <ion-label class="text-sm text-white">{{ $t('activity.redeem2') }}</ion-label>
        </div>
      </ion-button>
    </form>
  </ion-modal>
</template>

<script setup lang="ts">
import { IonModal, IonItem, IonInput, IonButton, IonLabel } from '@ionic/vue';
import RewardCards from './components/RewardCards.vue';
import useExchangeModalLogic from '../../exchangeModalLogic';

const props = defineProps({
  modalVisible: { type: Boolean, required: true },                              // 弹窗显示状态
  rewardCards: { type: Array as () => Record<string, any>[], required: true },  // 弹窗显示状态
})

const emit = defineEmits(['visibleChange', 'exchange'])

const {
  amount,
  summation,
  modalDismiss,
  submitForm,
  setMaxHandle
} = useExchangeModalLogic({ emit, props })

</script>

<style scoped lang="less">
  // 基础公共 less
  @import "@/views/activity/wheel/default/style/Exchange/base-exchange.less";
  @import "@/views/activity/wheel/default/style/Exchange/theme-style.less";

  #activity-wheel-default-modals-exchangeModal.style();

  .blue-default {
    #activity-wheel-default-modals-exchangeModal.style(
      --color-bg-400,
      --color-text-gray-200,
      --color-border-600,
      --color-text-white-100
    );
  }

  .green-default {
    #activity-wheel-default-modals-exchangeModal.style(
      --color-bg-400,
      --color-text-gray-200,
      --color-line,
      --color-text-gray-100
    );
  } 

  .amber-purple {
    #activity-wheel-default-modals-exchangeModal.style(
      --color-bg-400,
      --text-color-light-purple-2-100,
      --line-color,
      --text-color-light-purple-1-100
    );
  }
</style>
