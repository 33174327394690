<script setup lang="ts">
import { IonIcon, IonImg } from '@ionic/vue';
import { getTheme } from '@/theme/hooks'
import useAddThemeClassName from '@/hooks/useAddThemeClassName'
const tenantStore = useTenantStore();	// 租户信息
const merchanName = computed(() => tenantStore.tenantInfo?.name); // 商户名
const appLogo = computed(() => tenantStore.tenantInfo?.logo);
const isDefaultSkin = computed(() => getTheme().skin === 'default')
const router = useRouter()
const emit = defineEmits<{
  (e: 'goBack'): void
}>()
const goBack = () => {
  emit('goBack')
  // router.back()
}
</script>
<template>
  <div class="bt-header-wrap flex-between" :class="useAddThemeClassName()">
    <div class="back-area flex-start" @click="goBack">
      <IonIcon src="/first/svg/backSports.svg" class="back" />
      <span class="ml-1">{{ $t('main.back') }}</span>
    </div>
    <slot>

      <div>
        <ion-img class="h-[28px] max-w-[120px] pr-4" slot="secondary" :src="appLogo" v-if="isDefaultSkin" />
        <span class="title" v-else>
          {{ merchanName }}
        </span>
      </div>

    </slot>

  </div>

</template>

<style scoped lang="less">
.bt-header-wrap {

  width: 24.375rem;
  height: 3.125rem;
  box-sizing: border-box;
  line-height: 3.125rem;
  color: var(--text-color-white-100);
  .bg-200();

  .back-area {
    width: 6.25rem;
    height: 100%;
    padding-left: 0.75rem;
    .bg-100();
    cursor: pointer;

    ion-icon.back {
      font-size: var(--font-size-20);
      color: var(--color-text-40);
    }

  }




  .title {
    font-family: 'Archivo Black', sans-serif;
    font-size: 1.25rem;
    padding-right: 0.75rem;
    background: conic-gradient(from 180deg at 50% 50%, var(--ion-color-tertiary) 0deg, #FDFD68 359.96deg, var(--ion-color-tertiary) 360deg);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* 使用这两个属性来将渐变应用到文字 */
  }
}

.bt-header-wrap.scheme-light {
  background-color: var(--theme-color-800);

  .back-area {
    background-color: var(--theme-color-700);

    ion-icon.back {

      color: var(--color-text-secondary-2);
    }
  }

  .title {
    color: var(--color-bg-100);
    background: none;
    -webkit-text-fill-color: var(--color-bg-100);
  }

}
</style>
