<template>
  <svg class="svg-wall">
    <defs>
      <linearGradient id="svg-theme-color1" x1="20.4635" y1="-5.00817" x2="0.787575" y2="10.5236" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AE394"/>
        <stop offset="1" stop-color="#90D23F"/>
      </linearGradient>
      <linearGradient id="svg-theme-color2" x1="11.5677" y1="1.54517" x2="18.4448" y2="21.4853" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AE394"/>
        <stop offset="1" stop-color="#3F74D2"/>
      </linearGradient>
      <linearGradient id="svg-theme-color3" x1="25.6924" y1="24.1829" x2="3.14761" y2="1.3271" gradientUnits="userSpaceOnUse">
        <stop stop-color="#7041F3"/>
        <stop offset="1" stop-color="#F5C84C"/>
      </linearGradient>
      <linearGradient id="svg-theme-color4" x1="6.39036" y1="3.67933" x2="13.9247" y2="19.6325" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F7FB43"/>
        <stop offset="1" stop-color="#FF932E"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="less" scoped>
.svg-wall {
  width: 0;
  height: 0;
}
</style>