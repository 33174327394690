<!-- 公告消息 -->
<template>
  <ion-content class="common-ion-content">
    <div class="w-full px-[1rem] flex items-center justify-end fixed right-0 z-50" v-if="unreadAnnouncement>0">
      <ion-button class="read-all-btn" @click="readAllClick">
        <img class="w-[1.125rem] h-[1.125rem] mr-[0.375rem]" src="/icons/blue-default-all-email.png" />
        {{ $t('viewsUser.emailAllRead') }}
      </ion-button>
    </div>
    <div class="w-full" :class="unreadAnnouncement > 0 ? 'mt-[1.5rem]' : ''">
      <div v-for="(list, index) in announcementList" :key="index">
        <p v-if="typeof list == 'string'"></p>
        <div class="announcement-msg relative" v-for="item in list" v-else @click="detailHandle(item)" :key="item.id">
          <div class="item-top flexBox mb-[0.75rem] z-30">
            <!-- 公告🎁svg图标 -->
            <ion-icon class="w-[2.375rem] h-[2.375rem]" :src="currentMsgIcon" />
            <div class="item-top-tips ml-[0.75rem] w-full">
              <div class="middle-text-white flex-between">
                <!-- 公告标题 -->
                <p class="font-weight-medium">{{ item.title }}</p>
                <!-- 公告红点 -->
                <RipplePoint v-show="!readAnnouncementList.includes(item.id)" class="top-2 right-0" size="0.6rem"/>
                <!-- <ion-icon  v-if="!readAnnouncementList.includes(item.id)" class="fitst-red-point" src="/first/svg/red-point.svg"></ion-icon> -->
              </div>
              <!-- 公告创建时间 -->
              <p>{{ item.createTime }}</p>
            </div>
          </div>
          <!-- 公告内容 -->
          <div class="small-text-white announcement-content line-clamp-2 message-content" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <!-- 空列表提示 -->
    <div class="flex-center w-full h-full" v-if="!announcementList.length">
      <Empty :type="'msg'" class="w-full" />
    </div>
  </ion-content>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import Empty from '@/components/Empty/index.vue'
import { IonContent, IonIcon, IonButton, IonImg, IonLabel } from '@ionic/vue';
import { useAnnouncement } from '@/views/user/notification/hooks/announcementLogic'
import RipplePoint from '@/components/HotPoint/ripplePoint.vue'

// 公告🎁svg图标
const currentMsgIcon = computed(()  => {
  const data = getTheme();
  return `/${data.skin}/svg/${data.theme}-ancement-icon.svg`;
})
// 公告背景图片
const currentMsgBg = computed(() => {
  const data = getTheme();
  return `url('/${data.skin}/user/${data.theme}-ancement-bg.png')`;
})

const {
  readAnnouncementList,
  announcementList,
  unreadAnnouncement,
  replaceImgTag,
  detailHandle,
  readAllClick
} = useAnnouncement();

</script>

<style scoped lang="less">
.announcement-msg {
  padding: .75rem;
  font-size: .625rem;
  line-height: .9375rem;
  color: var(--color-text-40);
  margin-bottom: .625rem;
  border-radius: var(--rounded-middle);
  background: var(--color-bg-200);
  // background-image: url('/first/user/yellow-dark-ancement-bg.png');
  background-image: v-bind(currentMsgBg);
  background-size: 100% 100%;
}

:deep(.announcement-content p img) {
  margin-bottom: 1.25rem;
}

ion-button.read-all-btn {
  min-height: 0;
  min-width: 0;
  --background: var(--message-read-all-btn-bg);
  --color: var(--color-text-100);
  --border-radius: 1.8125rem;
  font-weight: 500;
  font-size: .75rem;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --background-activated-opacity: 1;
  --background-focused: var(--msg-all-button-color-shade);
  --background-hover: var(--msg-all-button-color-tint);
  --background-activated: var(--msg-all-button-color-shade);
}

ion-button.read-all-btn::part(native) {
  padding: .5rem .75rem;
}

.message-content {
  color: var(--my-card-detail-color);
}
</style>
