<!-- 多级分销 邀请规则 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('label.invite') + $t('main.rules') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <!-- 顶部图片 -->
        <div class="top-img"></div>
      <!-- 规则信息 -->
      <div class="rule-main">
        <div v-if="!isDefaultRule" class="item">
          <div v-html="customRuleList" class="custom-item"></div>
          <div class="item-count">01</div>
        </div>
        <div v-if="isDefaultRule">
          <div class="rule-item" v-for="(item,index) in invitationRuleList" :key="index">
            <div class="item-count">{{ handleRuleItemCount(index+1) }}</div>
            {{ item }}
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import { useInvitationRulesLogic } from '../hooks/invitationRulesLogic'
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';

//顶部图片
const currentTopPng = computed(() => {
  const data = getTheme();
  const skin = data.skin == 'second' ? 'second' : 'first'
  return `url(/${skin}/agent/${data.theme}-invitation-rules.png)`
})

// item右侧背景图
const currentRightRulePng = computed(() => {
  const data = getTheme();
  const skin = data.skin == 'second' ? 'second' : 'first'
  return `url('/${skin}/agent/${data.theme}-rules-rightTopBg.png')`
})

const { 
  invitationRuleList,
  isDefaultRule,
  customRuleList,
  handleRuleItemCount
} = useInvitationRulesLogic()
</script>

<style scoped lang="less">
@import '@/views/mlmAgent/styles/invitationRules.less' ;
.top-img {
  background: v-bind(currentTopPng) no-repeat;
  background-size: 100% 100%;
}


.rule-item,
.custom-item {
  background-image: v-bind(currentRightRulePng);
}

</style>
