import { useTenantStore } from "@/store/tenant";

// 主题皮肤配置
const themeConfig: Record<string, { skin: string; theme: string, home?: string, color: string }> = {
  "H5Dark:DarkGreen": { skin: "first", theme: "green-dark", color: '#22262E' },
  "Layout2:DarkGreen": { skin: "first", theme: "green-dark", color: '#22262E' },
  "H5Dark:GoldenYellow": { skin: "first", theme: "yellow-dark", color: '#262624' },
  "Layout2:GoldenYellow": { skin: "first", theme: "yellow-dark", color: '#262624' },
  "H5Dark:BluePurple": { skin: "first", theme: "purple-light", color: '#6526db' },
  "Layout2:BluePurple": { skin: "first", theme: "purple-light", color: '#6526db' },
  "Layout1:Green": { skin: "default", theme: "green-default", color: '#2B4F14' },
  "Layout1:GreenV01": { skin: "default", theme: "green-default", home: 'v01', color: '#2B4F14' },
  "Layout1:GreenV02": { skin: "default", theme: "green-default", home: 'v02', color: '#2B4F14' },
  "Layout3:AmberPurple": { skin: "second", theme: "amber-purple", color: '#262346' },
  "Layout1:AmberPurple": { skin: "default", theme: "amber-purple", color: '#262346' },
  "Layout1:AmberPurpleV01": { skin: "default", theme: "amber-purple", home: 'v01', color: '#262346' },
  "Layout1:Blue_V01": { skin: "default", theme: "blue-default", home: 'v01', color: '#090F1F' },
  "Layout1:BlueV02": { skin: "default", theme: "blue-default", home: 'v02', color: '#090F1F' },
  "Layout1:PineGreenV01": { skin: "default", theme: "forest-green", home: 'v01', color: '#004d37' },
  "Layout1:PineGreenV02": { skin: "default", theme: "forest-green", home: 'v02', color: '#004d37' },
  "Layout1:AuroraYellow": { skin: "default", theme: "auroral-yellow", color: '#24221F' },
};

/**
 * @description 获取当前配置主题
 */
export const getTheme = () => {
  const tenantStore = useTenantStore();
  let viteTheme = import.meta.env.VITE_THEME;
  const skinTwoType = tenantStore.tenantInfo?.skinTwoType as keyof typeof themeConfig;
  const defaultConfig = { skin: "default", theme: "blue-default", color: '#090F1F' };
  return themeConfig[viteTheme as keyof typeof themeConfig] || themeConfig[skinTwoType] || defaultConfig;
};
