<!-- 通知消息 -->
<template>
  <ion-content class="common-ion-content">
     <!-- 一键已读 -->
    <div class="w-full px-[1rem] flex items-center justify-end fixed right-0" v-if="unreadMailCount>0">
      <ion-button class="read-all-btn" @click="readAllClick">
        <img class="w-[1.125rem] h-[1.125rem] mr-[0.375rem]" src="/icons/blue-default-all-email.png" />
        {{ $t('viewsUser.emailAllRead') }}
      </ion-button>
    </div>
    <!-- 消息列表 -->
    <div class="w-full" :class="unreadMailCount > 0 ? 'mt-[1.5rem]' : ''">
      <div v-for="(list,index) in mailList" :key="index">
        <p  v-if="typeof list == 'string'"></p>
        <div v-for="item in list" :key="item.id" v-else @click="detailHandle(item)" class="notice-msg">
          <div class="item-top flexBox">
            <!-- 邮件📢svg -->
            <ion-icon class="w-[2.375rem] h-[2.375rem]" :src="currentMsgIcon" />
            <div class="item-top-tips ml-[0.75rem] w-full">
              <div class="middle-text-white flex-between">
                <!-- 邮件标题 -->
                <p class="font-weight-medium">{{ item.title }}</p>
                <!-- 邮件红点 -->
                <RipplePoint v-show="!item.isRead" class="right-[1rem]" size="0.6rem"/>
                <!-- <ion-icon v-if="!item.isRead" class="fitst-red-point" src="/first/svg/red-point.svg"></ion-icon> -->
              </div>
              <!-- 邮件创建时间 -->
              <p>{{ item.createTime }}</p>
            </div>
          </div>
          <!-- 邮件内容 -->
          <div class="mt-[0.75rem] small-text-white line-clamp-2 message-content" v-html="item.content" />
        </div>
      </div>
    </div>
    <!-- 空列表提示 -->
    <div class="w-full h-full flexBox" v-if="loadMore === 'noMore' && !mailList.length">
      <Empty :type="'msg'" class="w-full" />
    </div>
    <!-- 触底加载 -->
    <ion-infinite-scroll ref="infiniteRef" threshold="5px" @ionInfinite="ionInfinite" v-else>
      <ion-infinite-scroll-content :loading-text="loadMore == 'noMore' ? $t('label.noMore') : ''" :loading-spinner="loadMore == 'more' ? 'bubbles' : null"/>
    </ion-infinite-scroll>
  </ion-content>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import Empty from '@/components/Empty/index.vue'
import RipplePoint from '@/components/HotPoint/ripplePoint.vue'
import { useNotice } from '@/views/user/notification/hooks/noticeLogic'
import { IonContent, IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonImg, IonLabel } from '@ionic/vue';

const currentMsgIcon = computed(()  => {
  const data = getTheme();
  return `/${data.skin}/svg/${data.theme}-notice-icon.svg`;
})


const {
  infiniteRef,
  loadMore,
  mailList,
  startTimestamp,
  mailListParams,
  unreadMailCount,
  detailHandle,
  ionInfinite,
  getMailList,
  readAllClick
} = useNotice();

</script>

<style scoped lang="less">
.notice-msg {
  padding: .75rem;
  font-size: .625rem;
  line-height: .9375rem;
  color: var(--color-text-40);
  background: var(--color-bg-200);
  margin-bottom: .625rem;
  border-radius: var(--rounded-middle);
}

ion-infinite-scroll-content {
  min-height: 10px;	/* 修改最小高度 */
  font-size: var(--font-size-12);
  color: var(--color-text-40);
}

ion-button.read-all-btn {
  min-height: 0;
  min-width: 0;
  --background: var(--message-read-all-btn-bg);
  --color: var(--color-text-100);
  --border-radius: 1.8125rem;
  font-weight: 500;
  font-size: .75rem;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --background-activated-opacity: 1;
  --background-focused: var(--msg-all-button-color-shade);
  --background-hover: var(--msg-all-button-color-tint);
  --background-activated: var(--msg-all-button-color-shade);
}

ion-button.read-all-btn::part(native) {
  padding: .5rem .75rem;
}

.message-content {
  color: var(--my-card-detail-color);
}

.line-clamp-2 {
  word-break: break-all;
}
</style>
