// 代理详情 逻辑层
import { useRoute } from 'vue-router';
import { onBeforeMount, ref } from 'vue';
import { activityAgentMemberApi } from '@/api/activity';
import { moneyConvertToClient, validateUserId } from '@/utils/custom';
import { hideLoading, showLoading } from '@/utils/loading';
import { showToast } from '@/utils'
import { useI18n } from 'vue-i18n';
import { ActivityAgentValidUserItem, ActivityAgentValidUsersModel, ActivityAgentValidUsersParams } from '@/api/activity/model';
import { formatToDate } from '@/utils/date';

export default function useLogic() {
  const route = useRoute();
  const { t } = useI18n();

  const userId = ref('');
  const activityId = Number(route.params.id);
  const titleList = [t('activity.agent30'), t('activity.agent31'), t('activity.agent32'), t('activity.agent33'), t('activity.agent34')];
  const pageData = ref<ActivityAgentValidUserItem[]>([]);
  const isLoading = ref(false);

  function formatData(data: ActivityAgentValidUsersModel) {
    pageData.value = data.pageData.map(item => {
      return {
        userId: item.userId,
        rechargeCount: item.rechargeCount,
        historicalPay: moneyConvertToClient(item.historicalPay!),
        rechargeDays: item.rechargeDays,
        firstRechargeAmount: moneyConvertToClient(item.firstRechargeAmount!),
        historicalBetting: moneyConvertToClient(item.historicalBetting!),
        registerTime: formatToDate(item.registerTime!),
      }
    })
  }

  async function getUserDetails() {
    if (isLoading.value) return;
    if (userId.value && !validateUserId(Number(userId.value))) {
      showToast(t('activity.inputIdTips'));
      return;
    }
    isLoading.value = true;
    
    showLoading();
    const params: ActivityAgentValidUsersParams = {
      activityId,
      page: 1,
      pageSize: 100,
    }
    userId.value && (params.queryUserId = Number(userId.value))
    const data = await activityAgentMemberApi(params)
    formatData(data)
    hideLoading();
    isLoading.value = false;
  }

  onBeforeMount(()=>{
    getUserDetails()
  })

  return {
    titleList,
    pageData,
    userId,
    getUserDetails,
  }
}
