<!-- 自定义活动 -->
<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <!-- 顶部导航栏 -->
      <ion-toolbar class="" mode="ios">
        <BackButton />
        <ion-title>  <div class="text-container" ref="textRef" :style="titleStyle"> {{ activityName }}</div></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="space-y-3 ion-padding">
      <div v-html="content" class="content">
      </div>
      <div v-if="showApplyBtn" class="flex items-center justify-center">
        <Button class="text-sm apply" :shiny="true" @click="applyHandle">{{ $t('activity.custom1') }}</Button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle } from '@ionic/vue'
import BackButton from '@/components/BackButton.vue'
import Button from '@/components/second/Button/index.vue'
import useLogic from '../logic'

const textRef = ref(null);      
const isTwoLines = ref(false);

// 计算属性，动态调整字体大小
const titleStyle = ref({
  fontSize: "1.25rem",
  lineHeight:"1.875rem"
});

// 计算是否是两行
const checkLines = () => {
  if (!textRef.value) return;
  const element = textRef.value;
  isTwoLines.value = element.scrollHeight > parseFloat(getComputedStyle(element).lineHeight) * 1.5; // 超过一行半就算两行
  titleStyle.value.fontSize = isTwoLines.value ? "0.875rem" : ""; // 动态修改字体
  titleStyle.value.lineHeight = isTwoLines.value ? "1rem" : ""; // 动态修改字体
};


const {
  activityName,
  content,
  applyHandle,
  showApplyBtn
} = useLogic()

// **监听 activityName 变化，并立即触发检查**
watch(activityName, async () => {
  await nextTick(); // 确保 DOM 更新后计算
  checkLines();
}, { immediate: true }); // **确保初次渲染时就执行一次 checkLines()**

</script>

<style scoped>
.content {
  color: var(--color-custom-text);
}

.apply {
  width: 17.5rem;
  height: 2.5rem;
}

/* 解决v-html显示富文本表格边框不显示 */
:deep(table) {
  border-collapse: collapse;
  width: 100%;
}

:deep(table), :deep(th), :deep(td) {
    border: 1px solid var(--line-color);
}

:deep(th), :deep(td) {
    /* padding: 8px; */
    text-align: left;
}

.text-container {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制最多 2 行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: normal; /* 允许换行 */
}
</style>
