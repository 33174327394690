import { openUrl, encryptData, getInstallInfo } from "@/utils";
import { checkDomainAvailability } from '@/hooks/ping';
import openAPK from '@/utils/pwa/openAPK'
export const useRedomain = () => {
  //openAPK 拉起apk
  copyTextAppendEL.value = 'compulsory-warp';
  const appStore = useAppStore();
  const urlParams = encryptData(getInstallInfo(appStore));
  const installRePwa = async (domainList: TDomainInfo[], install: { installType: string, apkName: string, apkUrl: string }) => {
    console.log('domainList', domainList);
    const url = `?info=` + urlParams;
    if (install.installType !== 'pwa') return openAPK(true, install.apkUrl);
    if (domainList?.length) {
      for (const item of domainList) {
        const domain = await checkDomainAvailability(item?.rescueDomain ?? '');
        if (domain) {
          copyTextToClipboard(`https://${domain}/download` + url);
          break
        }
      }
    }
  }
  return {
    installRePwa,
    getUrlParams
  }
}

