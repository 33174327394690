import router from '@/router'
import { AssetsTabInfo } from '@/views/withdraw/type'
import { moneyConvertToClient, moneyConvertToServer, ratioConvertToClient, formatMoneyToShow } from '@/utils/custom'
import { payCancelApi, payChannelListApi, payConfirmApi } from '@/api/assets'
import {  PayCancelParams } from '@/api/assets/model'
import { getTheme } from '@/theme/hooks'
import { AmountBtnItem } from '../comp/AmountBtnList.vue'
import { LimitGameItem, LimitGamePlatformItem } from "@/api/assets/model";
import { getGameTypeName } from "@/utils/custom";
import { emitter } from '@/utils/event'
import { showPopup } from '@/hooks/ShowPopup'
import { PopupType } from '@/components/Popup/data';
import { showToast } from '@/utils'
import { formatToDateTime, calcDifference, getLocalTime } from '@/utils/date'
import { showLoading } from '@/utils/loading'
import { delay } from '@/utils/delay'
import { isEmpty } from 'lodash-es'
import type { PayResultType, processModeType } from '@/views/recharge/hooks/usePayCreate'
import handlePayCreate, { payCreateParams, btnLoading } from '@/views/recharge/hooks/usePayCreate'
import { thirdUrl, orderNo, isQRCode } from '@/views/recharge/bindCPF/hooks/useLogic'
import { useThreePartyPayment } from '@/views/recharge/hooks/useThreePartyPayment'
import { useWallet, isHasAccount, walletCode, queryWalletBalance, is_THREE_PARTY_WALLET } from '@/views/recharge/main/hooks/usePayBet'
import dayjs from 'dayjs';
export default () => {
  const route = router.currentRoute
  const gameStore = useGameStore() // 游戏信息
  const userStore = useUserStore() // 用户Store
  const tenantStore = useTenantStore() // 商户Store
  const channelStore = useChannelStore(); // 渠道信息 
  const regchargeStore = useRegchargeStore()
  const tabId = ref(-1) // 导航标签动态值
  const tabList = ref<AssetsTabInfo[]>([]) // 导航标签动态值
  const subTabId = ref(-1) // 导航标签动态值
  const subTabList = ref<AssetsTabInfo[]>([]) // 导航标签动态值
  const channelId = ref<number | undefined>(undefined) // 导航标签动态值
  const channelList = ref<any[]>([]) // 导航标签动态值
  const limitPlatformList = ref<any[]>([]) // 充值优惠限制平台名称
  const maxAmount = ref(0) // 最大金额
  const minAmount = ref(0) // 最小金额
  const isParticipate = ref(false) // 是否参与充值赠送活动
  const activityIsOpen = ref(false) // 活动是否开启
  const readOnly = ref(false) // 是否只读
  const channelLoading = ref(false) // 是否加载中
  const description = ref('') // 充值描述
  const amountInput = ref('') // 金额(输入框)
  const rateOfGift = ref(0) // 赠送比例
  const amountOfGift = ref(0) // 赠送金额
  const bannerUrl = ref('') // banner图片
  const withdrawFlowStr = ref('') // 提现流水提示
  const isHasBonus = ref(false)
  const iframeLoaded = ref(false) // iframe加载状态
  const amountList = ref<AmountBtnItem[]>([]) // 充值金额可选列表
  const expireTime = ref(0) // 充值订单过期时间
  const countdown = ref('') // 充值订单倒计时
  const modalVisible = ref(false) // 充值详情弹窗是否显示
  const payStatus = ref('BE_PAID') // 充值状态
  let timing = false // 定时器计算状态
  let rewardRateList: any[] = []
  const tabMap = new Map()
  const subActiveTabMap = new Map()
  const channelMap = new Map()
  let jumpDetails = false
  const { t } = useI18n();
  let activityId = 0
  const isDefaultBanner = ref(false)

  const { skin, theme } = getTheme()
  const payCancelParams = reactive<PayCancelParams>({
    type: 'TRANSFER',
    orderNo: '',
  })
  const isShowThirdParty = computed(() => !!thirdUrl.value || isQRCode.value)
  const isHasBottomTab = computed(() => route.value.path.startsWith("/main"))
  const placeholder = computed(() => `${formatMoneyToShow(minAmount.value, 0)} - ${formatMoneyToShow(maxAmount.value, 0)}`)
  const merchantCy = computed(() => tenantStore.tenantInfo?.merchantCy as string)  // 当前商户货币
  const homeGameList = computed(() => gameStore.homeGames) // 首页游戏列表

  const rechargeInfo = computed(() => tenantStore.rechargeInfo) // 充值详情

  const btnInfo = computed(() => {
     const btnTextMap = {
       'wallet': !isHasAccount.value ? t('viewsAssets.000004') : t('label.depositNow'),
       'default': t('label.depositNow')
     }
     const disabled = Number(amountInput.value) < Number(minAmount.value)
     const disabledMap = {
      'wallet': !isHasAccount.value ? false : disabled,
      'default': disabled
     }
     const name = tabMap.get(tabId.value)?.name
     console.log(disabledMap[name as keyof typeof disabledMap], 'tabMap.get(tabId.value)?.name')
     return {
      btnText: btnTextMap[name as keyof typeof btnTextMap] || btnTextMap.default,
      disabled:  name in  disabledMap ? disabledMap[name as keyof typeof disabledMap] :disabled
    }
  })

 const QRCodeInfo = computed(() => regchargeStore.QRCodeInfo)
  const orderList = computed(() => [
    { name: `${t('viewsAssets.createTime')}`, value: rechargeInfo.value?.createTime },
    { name: `${t('viewsAssets.transactionType')}`, value: rechargeInfo.value?.payTypeName },
    { name: `${t('viewsAssets.BeneficiaryBank')}`, value: rechargeInfo.value?.transferCompany },
    { name: `${t('viewsAssets.BeneficiaryName')}`, value: rechargeInfo.value?.transferRealName, copy: true },
    { name: `${t('viewsAssets.BeneficiaryAccount')}`, value: rechargeInfo.value?.transferAccount, copy: true },
    { name: `${t('viewsAssets.orderNumber')}`, value: rechargeInfo.value?.orderNo, copy: true },
  ])
  const getBonus = (rechargeAmount: number = Number(amountInput.value), checked: boolean = isParticipate.value) => {
    let amount = 0
    if (rechargeAmount) {
      if (checked) {
        let target = rewardRateList.findLast((it: any) => rechargeAmount >= it.amount)
        if (target) {
          let { rate, amount: digit, betMultiple } = target
          amount = (rate / 100) * moneyConvertToServer(digit)
          rateOfGift.value = rate
          withdrawFlowStr.value = t('viewsTabbar.rechargeCenter1', { multiple: betMultiple })
        } else {
          rateOfGift.value = 0
          withdrawFlowStr.value = ''
        }
      } else {
        console.log('fixedAmount', withdrawFlowStr.value)
        withdrawFlowStr.value = ''
        rateOfGift.value = 0
        if (rechargeAmount >= minAmount.value) {
          let { discountRatio } = subActiveTabMap.get(subTabId.value)
          const ratio = ratioConvertToClient(discountRatio)
          amount = (ratio / 100) * moneyConvertToServer(rechargeAmount)
        }
      }
    } else {
      withdrawFlowStr.value = ''
      rateOfGift.value = 0
    }

    amountOfGift.value = moneyConvertToClient(amount)

  }

  watch(
    () => amountInput.value,
    (amount) => {
      // 监听输入框金额变动
      const newAmount = Number(amount)
      getBonus(newAmount)
    }
  )

  const emptyChannelTodo = (logo: string, name: string) => {
    channelMap.clear()
    channelId.value = undefined
    if (skin !== 'default') {
      channelList.value = [{ logo, name }]
    } else {
      channelList.value = []
    }
  }

  /**
 * @description 调用接口-获取充值渠道列表
 */
  const getPayChannelList = async (payTypeSubId: number) => {
    channelLoading.value = true // 设置渠道加载状态
    try {
      const res = await payChannelListApi({ payTypeSubId }) // 调用接口-获取充值渠道列表

      let logo = tabMap.get(tabId.value).logo
      if (res.length) {
        channelId.value = res[0].channelId
        channelList.value = res
        let list: any[] = []
        res.forEach((it: any) => {
          list.push({
            id: it.channelId,
            name: it.channelName,
            logo
          })
          channelMap.set(it.channelId, { ...it, logo })
        })
        channelList.value = list
      } else {
        let name = subActiveTabMap.get(subTabId.value).showName
        emptyChannelTodo(logo, name)
      }
    } finally {
      channelLoading.value = false // 设置渠道加载状态
    }
  }

  const changeRechargeActivity = (rechargeAmount: number = Number(amountInput.value), checked: boolean = isParticipate.value) => {
    if (checked) {
      amountList.value = rewardRateList
      isHasBonus.value = true
    } else {
      let { fixedAmount, discountRatio } = subActiveTabMap.get(subTabId.value)
      amountList.value = fixedAmount.split(',')?.map((it: string) => ({ amount: Number(it) }))
      isHasBonus.value = discountRatio > 0
    }
    getBonus(rechargeAmount, checked)
  }

  const subTabChange = (activeId: number) => {
    let { id, isInputAmount, processMode, showName, description: desc, maxAmount: maxA, minAmount: minA, discountRatio, fixedAmount, code } = subActiveTabMap.get(activeId)
    subTabId.value = id
    regchargeStore.subTypeId = id
    maxAmount.value = maxA / 100
    minAmount.value = minA / 100
    readOnly.value = isInputAmount !== 1
    description.value = desc
    is_THREE_PARTY_WALLET.value = processMode === 'THREE_PARTY_WALLET'
    const processModeMap: Record<processModeType, () => void> = {
      'TRANSFER': () => getPayChannelList(id),
      'THREE_PARTY_WALLET': () => useWallet({
        walletCode: code,
        processCode: processMode,
      }),
      'THREE_PARTY_PAYMENT': () => {
        let logo = tabMap.get(tabId.value).logo
        emptyChannelTodo(logo, showName)
      }
    }

    processModeMap[processMode as keyof typeof processModeMap]()
   

    changeRechargeActivity()


  }
  const getSubTabId = (list: AssetsTabInfo[]) => {
    const codeMap = {
      single: () => list[0].id,
      found: (target: AssetsTabInfo) => target.id,
      default: () => list[0].id
    }
    
    if (list.length === 1) return codeMap.single()
    const target = list.find((it: AssetsTabInfo) => it.id === regchargeStore.subTypeId)
    return target ? codeMap.found(target) : codeMap.default()
  
}

  const mainTabChange = (activeId: number) => {
    tabId.value = activeId
    regchargeStore.typeId = activeId
    let { payTypeSubList } = tabMap.get(activeId)
    payTypeSubList.sort((a: any, b: any) => {
      // 根据sort字段排序
      return b.sort - a.sort
    })
    let list: AssetsTabInfo[] = []
    subActiveTabMap.clear()
    payTypeSubList.forEach((it: any) => {
      const { showName, id, tags, tagValue, processMode , code} = it
      subActiveTabMap.set(id, it)
      let newTagValue = tags !== 'NOTHING' ? tags == 'GIVE_AWAY' ?
        `+${formatMoneyToShow(Number(tagValue), 2)}%` : t(`tags.${tags}`) : ''

      list.push({ id, name: showName, tagValue: newTagValue})
    })
    subTabList.value = list
 
  
    subTabChange(getSubTabId(list))
  }

  // 获取限制平台名称
  const getLimitPlatformName = (homeGameList: any, limitGameList: any) => {
    const platformNameList: string[] = [];
    // 先建立一个homeGameTypeMap，方便后续查找
    const homeGameTypeMap = new Map<string, LimitGameItem>(homeGameList.map((item: any) => [item.gameType, item]));

    limitGameList.forEach((it: LimitGameItem) => {
      const homeGameInfo = homeGameTypeMap.get(it.gameType)
      if (homeGameInfo) {
        const gameType = homeGameInfo?.gameType;
        let platformListMap = new Map(homeGameInfo.platformList?.map((item: any) => [item.id, item.name]));
        it.platformData.forEach((item: LimitGamePlatformItem) => {
          const name = platformListMap.get(item.platformId);
          if (name) {
            platformNameList.push(`${name}${getGameTypeName(gameType)}`);
          }
        });
      }
    });
    return platformNameList;
  };

  // 页面加载前
  watch(() => route.value.path, async (newPath) => {

    if (newPath == '/main/entrar' || newPath === '/recharge/apply') {
      amountInput.value = ''
      await tenantStore.getTenantInfo() // 获取商户信息
      gameStore.getHomeGames() // 获取首页游戏列表
      userStore.getUser() // 获取用户信息
      const payList = await tenantStore.getPayList() // 获取充值列表
      const rechargeRewardInfo = payList?.rechargeRewardInfo
      const tenantPayTypeList = payList?.tenantPayTypeList

      let list: AssetsTabInfo[] = []
      if (!isEmpty(rechargeRewardInfo)) {
        let { rewardRate, id, isJumpDetail, bannerUrl: url, auditLimitGame, imageType } = rechargeRewardInfo

        rewardRateList = rewardRate.map((it: any) => {
          return {
            amount: moneyConvertToClient(it.amount),
            rate: ratioConvertToClient(it.rate),
            betMultiple: it.betMultiple,
          }
        })
        bannerUrl.value = url
        activityIsOpen.value = true
        jumpDetails = isJumpDetail
        activityId = id
        isDefaultBanner.value = imageType === 'DEFAULT'
        if (!isEmpty(auditLimitGame)) {
          if ('status' in auditLimitGame && 'limitData' in auditLimitGame) {
            if (auditLimitGame.status === 'OFF' || isEmpty(auditLimitGame.limitData)) {
              limitPlatformList.value = [];
            } else {
              limitPlatformList.value = getLimitPlatformName(homeGameList.value, auditLimitGame.limitData)
            }



          }
        }
      } else {
        activityIsOpen.value = false
      }
      if (!isEmpty(tenantPayTypeList)) {
        console.log(rechargeRewardInfo, tenantPayTypeList, 'tenantPayTypeList');
        tabMap.clear()
        tenantPayTypeList.forEach((item: any) => {
          let { id, name, tags, tagValue } = item
          tabMap.set(id, item)
          let newTagValue = tags !== 'NOTHING' ? tags == 'GIVE_AWAY' ?
            `+${formatMoneyToShow(Number(tagValue))}%` : t(`tags.${tags}`) : ''

          list.push({ id, name, tagValue: newTagValue,  })
        })

      }
      tabList.value = list
      const tabId = tabMap.has(regchargeStore.typeId) ? regchargeStore.typeId : list[0].id
      mainTabChange(tabId)

    }

  }, { immediate: true })
 
   

  watch(
    () => expireTime.value,
    (val) => {
      // 监听充值订单过期时间
      if (timing) return
      timing = true
      if (val > 0) {
        payStatus.value = 'BE_PAID'
        const hours = Math.floor(val / 3600)
        const minutes = Math.floor((val % 3600) / 60)
        const remainingSeconds = val % 60
        countdown.value = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
        setTimeout(() => {
          expireTime.value--
          timing = false
        }, 1000)
      } else {
        countdown.value = ''
        payStatus.value = 'TIMEOUT'
      }
    },
  )
  /**
  * @description 充值记录按钮点击事件
  */
  function recordHandle() {
    router.push('/rechargeRecord')
  }

  /**
 * @description 充值金额输入事件
 * @param event 事件对象
 */
  const amountInputChange = (event: CustomEvent) => {

    if (event.detail.value > maxAmount.value) {
      // 判断输入金额是否大于最大金额

      setTimeout(() => {
        amountInput.value = maxAmount.value.toString() // 设置输入框金额为最大金额
      }, 0)
      return
    }
    if (event.detail.value <= 0) {
      // 判断输入金额是否大于最大金额

      setTimeout(() => {
        amountInput.value = '' // 设置输入框金额为最小金额
      }, 0)
      return
    }
    amountInput.value = event.detail.value // 设置输入框金额
  }
  /**
   * @description 充值金额选择事件
   * @param amount 金额
   */
  function amountHandle(amount: number) {
    amountInput.value = amount.toString()

  }
  const clearUnauthorizedAmounts = (amountsList: any[]) => {
    //   支持输入和没有输入金额 不用clear
    if (readOnly.value || !amountInput.value) return
    // 判断不支持输入的情况， 已经存在的金额是不是在金额按钮列表里面  amount
    let target = amountsList.find((it: any) => Number(it.amount) === Number(amountInput.value))
    if (!target) {
      amountInput.value = ''
    }

  }

  const handleRechargeActivity = (e: CustomEvent) => {
    let checked = e.detail.checked
    changeRechargeActivity(Number(amountInput.value), checked)
    clearUnauthorizedAmounts(amountList.value)

  }

  /**
 * @description 宣传图点击事件
 */
  const bannerHandle = () => {
    if (!jumpDetails) return
    router.push({
      path: `/activity/RechargeReward/${activityId}`,
    })
  }

  /**
 * @description 充值成功事件
 */
  const paySuccessHandler = (data: any) => {
    if (orderNo.value !== data.orderNo) return
    showPopup({
      type: PopupType.TIPS,
      msg: t('popup.tips15'),
      leftBtnText: t('main.confirm'),
      leftBtnCallback: () => {
        router.push('/main')
      },
    })
  }
  const paySuccessCb = (data: any) => {
    paySuccessHandler(data)
    channelStore.APKTaskPopupTime = 'Recharge'
  }

  /**
  * @description 充值RUL加载完成事件
  */
  const iframeLoadHandle = () => {
    iframeLoaded.value = true
    btnLoading.value = false
    userStore.isRechargeing = true
    emitter.on('user/pay-success', paySuccessCb); // 监听充值成功事件
  }
  /**
  * @description 关闭iframe
  */
  const iframeCloseHandle = () => {
    iframeLoaded.value = false
    thirdUrl.value = ''
    isQRCode.value = false
    userStore.isRechargeing = false
    emitter.off('user/pay-success', paySuccessCb);
  }

  const handleThreePartyPayment = (result: PayResultType) => {
    const res = useThreePartyPayment(result.payUrl, result.redirectType)
    thirdUrl.value = res?.url
    isQRCode.value = res.isQRCode
    orderNo.value = result.orderNo
  }


  const handleTransferPayment = (result: PayResultType) => {
    const { showName, name } = subActiveTabMap.get(subTabId.value)
    const { transferRealName, transferCompany, transferAccount } = channelMap.get(channelId.value)
    tenantStore.rechargeInfo = {
      amount: amountInput.value,
      createTime: formatToDateTime(result.createTime),
      orderNo: result.orderNo,
      payTypeName: showName ?? name,
      transferRealName,
      transferCompany,
      transferAccount,
    }
    expireTime.value = calcDifference(result.createTime, result.expireTime)
    modalVisible.value = true
  }



  /**
 * @description 调用接口-创建充值订单
 */
  const onPayCreate = async () => {
    const processModeMap: Record<processModeType, (params: PayResultType) => void> = {
      THREE_PARTY_PAYMENT: handleThreePartyPayment,
      TRANSFER: handleTransferPayment,
      THREE_PARTY_WALLET: handleThreePartyPayment
    }
    regchargeStore.QRCodeInfo.amount =amountInput.value
    handlePayCreate(processModeMap[payCreateParams.processMode])
  }


  /**
 * @description 提交表单
 * @param event 事件对象
 */
  const submitForm = (event: Event) => {
    event?.preventDefault() // 阻止默认事件
    const amount = Number(amountInput.value)
    if (amount < minAmount.value || amount > maxAmount.value) {
      // 判断充值金额是否小于最小金额或大于最大金额
      return showToast('toast.invalidInputAmount') // 提示充值金额不能小于最小金额
    }
    if (!Number.isInteger(amount)) {
      return showToast('toast.0001') // 充值金额只能为整数
    }
    payCreateParams.amount = amount * 100 // 设置充值金额(后端金额需乘100)
    payCreateParams.participateReward = isParticipate.value // 设置是否参与优惠
    payCreateParams.payTypeSubId = subTabId.value
    payCreateParams.payChannelId = channelId.value
    payCreateParams.processMode = subActiveTabMap.get(subTabId.value).processMode
    onPayCreate() // 调用接口-创建充值订单
  }

  // 生命周期: 路由离开
  onBeforeRouteLeave(() => {
    iframeCloseHandle()
  })


  const handleBtnClick = () => {
    const clickEventMap = {
      'register': () => {
        queryWalletBalance(walletCode.value)
      },
      'depositNow': submitForm
    }
    const name = tabMap.get(tabId.value)?.name
    const action = name === 'wallet' && !isHasAccount.value ? 'register' : 'depositNow'
    clickEventMap[action]()
  }


  /**
   * @description 调用接口: 取消充值订单
   */
  const onCancelOrder = async () => {
    showLoading()
    const res = await payCancelApi(payCancelParams)
    if (res && 'status' in res) {
      showToast('toast.cancelSuccess')
    }
    modalVisible.value = false
  }
  /**
   * @description 调用接口: 确认充值订单
   */
  const onConfirmOrder = async () => {
    showLoading()
    const res = await payConfirmApi({ orderNo: rechargeInfo.value.orderNo })
    if (res && 'status' in res) {
      showToast('status.SUCCESS')
    }
    modalVisible.value = false
    await delay(1000)
    router.push('/rechargeRecord')
  }

  /**
   * @description 取消订单事件
   */
  const cancelHandle = () => {
    payCancelParams.orderNo = rechargeInfo.value.orderNo
    onCancelOrder()
  }
  /**
   * @description 充值完成事件
   */
  const finishHandle = () => {
    modalVisible.value = false
    onConfirmOrder()
  }


  return {
    isShowThirdParty,
    isHasBottomTab,
    channelLoading,
    isParticipate,
    thirdUrl,
    tabId,
    tabList,
    description,
    readOnly,
    amountInput,
    placeholder,
    maxAmount,
    minAmount,
    subTabList,
    subTabId,
    channelList,
    channelId,
    amountList,
    isHasBonus,
    activityIsOpen,
    amountOfGift,
    rateOfGift,
    bannerUrl,
    merchantCy,
    withdrawFlowStr,
    limitPlatformList,
    btnLoading,
    iframeLoaded,
    payStatus,
    rechargeInfo,
    orderList,
    countdown,
    modalVisible,
    expireTime,
    theme,
    isQRCode,
    QRCodeInfo,
    amountHandle,
    iframeCloseHandle,
    recordHandle,
    mainTabChange,
    amountInputChange,
    subTabChange,
    handleRechargeActivity,
    bannerHandle,
    iframeLoadHandle,
    handleBtnClick,
    cancelHandle,
    finishHandle,
    btnInfo,
    t,
    isDefaultBanner,
  }

}
