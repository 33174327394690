<!-- 多级分销 佣金明细 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('main.commissionDetails') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="commissionDetail">
        <!-- 时间选择框 -->
        <div class="time">
          <span>{{ $t('activity.selectDate') }}:</span>
          <div class="timeChoose" id="popover-trigger-left">
            <div>{{ timeStart }}</div>
            <ion-icon icon="/svg/spread/calendar.svg" />
          </div>
        </div>
        <!-- 佣金详情信息 -->
        <div
          class="card">
          <ion-icon icon="/svg/spread/coin.svg" />
          <div class="Earn">{{ $t('activity.earn') }} {{ $t('toggle.commissions') }}</div>
          <div class="money">{{ tenantInfo?.merchantCy }} {{ formatMoneyToShow(myTotalCommission) }}</div>
          <div class="right">
            <img src="/first/agent/comm-detail.png" alt="">
          </div>
        </div>
        <!-- 下级ID搜索框 -->
        <div class="filter">
          <ion-searchbar 
            class="search"
            v-model="searchValue" 
            id="search-agency"
            :placeholder="$t('mlmAgent.searchPlaceholder')"
            show-clear-button="never" 
            @input="searchInput" 
            mod="ios"
          >
          </ion-searchbar>
          <ion-icon icon="/svg/spread/find.svg" @click="searchHandle" />
        </div>
        <div class="perMes">
          <div 
            v-for='item in commissionDetailList' 
            :key='item.userId'
            class="perCard"
          >
            <div
              class="line">
            </div>
            <div class="perImg">
              <img :src="item.avatar" />
              <div class="idMes">
                <div class="idNum">
                  ID:{{ item.userId }}
                </div>
                <ion-icon  src="/svg/spread/copyRight.svg" @click="copy(item.userId)" />
              </div>
            </div>
            <div class="mes">
              <div class="top">
                <div class="left">
                  {{ item.level }}
                </div>
                <div class="right">
                  {{ formatMoneyToShow(item.rechargeAmount) }}
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  {{ $t('mlmAgent.subordLevels') }}
                </div>
                <div class="right">
                  {{ $t('activity.agent32') }}
                </div>
              </div>
              <div class="top">
                <div class="left">
                  {{ tenantInfo?.merchantCy }} {{ formatMoneyToShow(item.commission) }}
                </div>
                <div class="right">
                  {{ formatToDateTime(item.registerTime) }}
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  {{ $t('mlmAgent.contributionCom') }}
                </div>
                <div class="right">
                  {{ $t('main.signUp') }} {{ $t('activity.time') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 暂无数据 -->
        <div class="empty" v-if="!showEmpty">
          <div class="img">
            <Empty :text="$t('mlmAgent.noMoreDeta')" />
          </div>
        </div>
      </div>
    </ion-content>
    <!-- 时间选择器 -->
    <ion-popover mode="md" trigger="popover-trigger-left" trigger-action="click" :isOpen="showPopover"
      :showBackdrop="false" @didDismiss="popoverDismiss">
      <ion-datetime 
        presentation="date" 
        mode="ios" 
        :value="dateValueTime" 
        :min="minDate" 
        :max="maxDate" 
        :locale="locale"
        :show-default-buttons="true"
        :cancel-text="$t('main.cancel')"
        :done-text="$t('mlmAgent.btnDone')" 
        @ionChange="dateChange" color="rose" 
      />
    </ion-popover>
  </ion-page>
</template>

<script setup lang="ts">
import { copy } from '@/hooks/Copy';
import { formatToDateTime } from '@/utils/date.ts'
import { formatMoneyToShow } from '@/utils/custom'
import { IonPopover, IonIcon, IonDatetime, IonHeader, IonPage, IonRow, IonCol, IonGrid, IonContent, IonSearchbar, IonSpinner, IonImg, IonModal, IonTitle, IonButton, IonButtons, IonList, IonLabel, IonToolbar } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';
import { useCommissionDetailSpread } from '@/views/mlmAgent/hooks/commissionDetailSpread'
import Empty from '@/components/Empty/index.vue'
const {
  minDate,
  maxDate,
  timeStart,
  showPopover,
  searchValue,
  dateValueTime,
  commissionDetailList,
  myTotalCommission,
  locale,
  tenantInfo,
  showEmpty,
  popoverDismiss,
  dateChange,
  searchHandle,
  searchInput
} = useCommissionDetailSpread();
</script>

<style scoped lang="less">
@import '@/views/mlmAgent/styles/commissionDetail.less' ;
</style>
