<!-- 多级分销 代理级别 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('mlmAgent.agentLevel') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <!-- 顶部图片 -->
      <div class="top-img"></div>
      <!-- 代理级别信息 -->
      <div class="agent-level-main">
        <div 
          class="agent-level-item"
          :class="{ 'lase-item': index === agentLevelInfo.length - 1 }"
          v-for="(item,index) in agentLevelInfo" 
          :key="index"
        >
          <!-- VIP等级图标 -->
          <div class="pl-[0.25rem] flex mt-[-1.375rem]">
            <div class="item-level-border relative">
              <img class="current-level" :src="agentStore.getMlmAgentLevelInfo(item.level,'icon')" alt="">
            </div>
            <div class="level-tips h-full font-bold">LV{{ item.level }}</div>
          </div>
          <div class="item-level-bottom">
            <div 
              class="level-item" 
              v-for="(conditionItem,index) in item.conditionList" 
              :key="index"
            >
              <span class="level-item-left">{{ $t(`mlmAgent.${conditionItem.name}`) }}</span>
              <span v-if="conditionItem.name == 'membersCount'" class="level-item-right">{{ conditionItem.value }}</span>
              <span v-else class="level-item-right">{{ formatMoneyToShow(conditionItem.value) }}</span>
            </div>
          </div>
        </div>
        <!-- 代理级别说明 -->
        <div class="agent-description">
          <div class="title">{{ $t('mlmAgent.agentLevelTips') }}</div>
          <div class="description-content" v-for="(item,index) in agentLevelRules" :key="index">
            <div class="point"></div>
            {{ item }}
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import { useAgentStore } from '@/store/agent';
import { formatMoneyToShow } from '@/utils/custom'
import { useAgentLevelInfoLogic } from '../hooks/agentLevelInfoLogic'
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';

const agentStore = useAgentStore();   // 代理store

// 代理等级外圈图片
const currentAgentLevelPng = computed(() => {
  const data = getTheme();
  const skin = data.skin == 'second' ? 'second' : 'first'
  return `url('/${skin}/agent/${data.theme}-agent-level-bg.png')`
})

const {
  agentLevelInfo,
  agentLevelRules
} = useAgentLevelInfoLogic();
</script>

<style scoped lang="less">
  @import '@/views/mlmAgent/styles/agentLevelInfo.less';

  .item-level-border {
    background: v-bind(currentAgentLevelPng) no-repeat;
    background-size: 100% 100%;
  }
</style>
