// 公告弹窗逻辑层
import useLinkHandle from "@/hooks/useLinkHandle";
import { showLogin } from '@/hooks/ShowLogin'
import { closeForceModal } from '@/utils/pwa/forcedModal/useClose'
import {debounce} from 'lodash-es'
export default function useLogic() {
  const route = useRoute();               // 路由
  const appStore = useAppStore();         // 用户store
  const tenantStore = useTenantStore();   // 租户store
  const statusStore = useStatusStore();   // 状态store


  let announcementLink = '';                                               // 链接
  const announcementDetail = ref('');                                             // 公告详情
  const announcementType = ref('text');                                           // 类型
  const announceBgImg = ref('');                                                  // 标题
  const title = ref('');                                                  // 公告背景图片
  let announcementLinkType = '';        // 链接跳转类型
  let announcementValueType = '';  // 链接格式类型

  const announcementList = computed(() => tenantStore.announcementList);        // 公告列表
  const todayInvisible = computed(() => statusStore.announcementInvisible);     // 今日不再显示



  /**
   * @description 关闭弹窗
   */
  const dismiss = debounce(async() => {
  
    const announcement = announcementList.value[0];
    const popupMethodMap = {
      day: () => tenantStore.setAnnouncementHide(announcement.id),
      one: () => tenantStore.setAnnouncementHide(announcement.id),
      login: () => tenantStore.setAnnouncementInvisible(announcement.id)
    };

    if (todayInvisible.value) {
      tenantStore.setAnnouncementHide(announcement.id);
    } else {
      popupMethodMap[announcement?.popupMethod as keyof typeof popupMethodMap]?.();
    }

    if(announcementList.value.length === 1 ){
      await closeForceModal(false)
    }
 
    if(announcementList.value.length ){
      tenantStore.shiftAnnouncementList();
      announcementList.value.length && getAnnouncementDetail()
    }
 
  }, 200 )

  /**
   * @description 获取公告详情
   */
  async function getAnnouncementDetail() {
    const announcement = announcementList.value[0];
    if(!announcement) return
    title.value = announcement.title;
    announcementType.value = announcement.announcementType;
    announcementLink = announcement.value;
    announcementLinkType = announcement.type;
    announcementValueType = announcement.valueType;
    announcementDetail.value = '';
    announceBgImg.value = announcement.imgUrl;
    if (announcementType.value === 'text') {
      announcementType.value = 'text';
      const response = await fetch(announcement.content);
      announcementDetail.value = await response.text();
      announceBgImg.value = announcement.imgUrl;
    }
    else {
      announcementDetail.value = announcement.imgUrl;
    }
  }

  /**
   * @description 公告链接跳转
   */
  function onLinkHandle() {
  if (announcementLink && announcementLink == '/register') {
    if (!appStore.token) {
      dismiss()
      closeForceModal(false)
      showLogin('register')
    }
  } else{
    closeForceModal(false)
    useLinkHandle(announcementLinkType, announcementLink, announcementValueType)
  }
  }

  /**
   * @description 今日不再提醒文本点击事件
   */
  function checkHandle() {
    statusStore.setAnnouncementInvisible(!todayInvisible.value)
  }
  onMounted(() => {
    getAnnouncementDetail()
  })

  return {
    announcementDetail,
    announcementType,
    todayInvisible,
    announceBgImg,
    title,
    dismiss,
    onLinkHandle,
    checkHandle
  }
}
