<template>
  <!-- 语言列表弹窗 -->
  <ion-modal ref="profileModalLanguageRef" mode="md" :is-open="isOpen" id="modal-language" @didDismiss="modelDismiss">
    <div class="w-[22.5rem] px-[.625rem] mx-auto"
      :class="{ 'old-language': curDefaultSkin, 'first-language': curFirstSkin }">
      <h1 class="text text-center">{{ $t('label.chooseLanguage') }}</h1>
      <div v-for="lang in langList" :key="lang"
        :class="{ 'old-language-item': curDefaultSkin, 'flex-between first-language-item': curFirstSkin }"
        @click="changeLanguage(lang)">
        <p class="text-sub py-4">{{ getLanguageName(lang, locale) }}</p>
        <ion-checkbox mode="md" :checked="true" v-if="locale.toString() == lang" />
      </div>
    </div>
  </ion-modal>
</template>

<script setup lang="ts">
import { useAppStore } from '@/store/app';
import { useTenantStore } from '@/store/tenant';
import { computed, ref } from 'vue';
import { getTheme } from '@/theme/hooks'
import { IonModal, IonCheckbox } from '@ionic/vue';
import { getLanguageName } from '@/utils/custom'


const appStore = useAppStore(); // 应用信息
const tenantStore = useTenantStore(); // 租户信息

const profileModalLanguageRef = ref();  // 语言列表弹窗DOM

const curDefaultSkin = computed(() => getTheme().skin == 'default');  // 旧版皮肤
const curFirstSkin = computed(() => ['first','second'].includes(getTheme().skin));      // 新皮肤
const langList = computed(() => tenantStore.getTenantLanguageList()); // 语言列表
const locale = computed(() => appStore.locale);               // 当前语言
const isOpen = computed(() => appStore.languageModalVisible); // 是否打开语言列表弹窗

/**
 * @description 切换语言
 */
function changeLanguage(language: string) {
  appStore.setLocale(language);
  profileModalLanguageRef.value.$el.dismiss();
}

/**
 * @description 关闭弹窗回调
 */
function modelDismiss() {
  appStore.setLanguageModalVisible();
}
</script>

<style lang="less" scoped>
ion-modal#modal-language::part(content) {
  --height: fit-content;
}

.old-language {
  background: var(--color-bg-300);
  border-radius: 1rem;
}
.auroral-yellow .default  .old-language {
  background:  var(--color-text-black-100);
  ion-checkbox {
    --checkbox-background-checked: var(--theme-color-800);
    --checkmark-color: var(--color-text-black-100);
  }
}
.old-language-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--line-color);
}

.first-language {
  background: var(--color-bg-200);
  border-radius: var(--rounded-large);
  color: var(--color-text-100);
}

.first-language-item {
  border-top: 1px solid var(--line-color);
}

.text {
  color: var(--color-language-text);
}

.text-sub {
  color: var(--color-language-text1);
}
ion-checkbox {
  --checkbox-background-checked: var(--color-checkbox-bg);
}


.forest-green {
  .old-language {
    background: #004D37;
    border-radius: 1rem;
  }
}
</style>
