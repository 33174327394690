<!-- 多级分销 佣金比例表 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('mlmAgent.commissionRate') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <!-- 选择游戏类型 -->
      <div class="game-type" v-if="isShowGameType">
        <ion-segment ref="segment" scrollable mode="md" class="game-type-se" v-model="currentGameType">
          <ion-segment-button 
            class="game-type-btn" 
            :class="{'second-game-type': isSecondSkin }"
            :value="item.value" 
            v-for="(item,index) in gameTypeList" 
            :key="index" 
            @click="selectAgmeType(item.value)"
          >
            <ion-button fill="clear" class="base-style" :class="{ 'select-style': currentGameType == item.value }">
              {{ item.name }}
            </ion-button>
          </ion-segment-button>
        </ion-segment>
      </div>
      <!-- 顶部图片 -->
      <div class="top-img"></div>
      <!-- 代理级别 segment -->
      <div class="level">
        <ion-segment ref="segment" class="level-agent-se" scrollable v-model="currentAgentLevel">
          <ion-segment-button 
            class="level-agent" 
            v-for="(item,index) in agentLevelList" 
            :key="index" 
            :value="item.level" 
            @click="selectAgentLevel(item.level)"
          >
            <div class="item" :class="{ 'level-icon': currentAgentLevel == item.level }">
              <img class="current-level" :src="agentStore.getMlmAgentLevelInfo(item.level,'icon')" />
            </div>
            <ion-label>LV{{ item.level }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
      <!-- 代理级别比例信息 -->
      <div class="mes">
        <div class="rate-info">
          <div class="icon">
            <img :src="agentStore.getMlmAgentLevelInfo(currentAgentLevel,'icon')" />
            <span>LV{{ currentAgentLevel }}</span>
          </div>
          <div class="rate-title">
            <span>{{ $t('mlmAgent.agentLevel') }}</span>
            <span>LV{{ currentAgentLevel }}</span>
          </div>
          <!-- 代理等级比例信息 -->
          <div class="rate-box">
            <div class="rate-item" v-for="(item,index) in agentLevelRateInfo" :key="index">
              <span class="rate-item-left">{{ $t('activity.vip5') }} {{ item.level }}</span>
              <span class="rate-item-right">{{ `${(item.rat)}%` }}</span>
            </div>
          </div>
          <!-- 底部说明 -->
          <div class="rate-footer-tips">
            {{ $t('mlmAgent.rateFooterTips') }}
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import { useAgentStore } from '@/store/agent';
import { useCommissionRateLogic } from '../hooks/commissionRateLogic'
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle, IonSegment, IonSegmentButton, IonLabel, IonImg, IonButton } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';

const agentStore = useAgentStore();   // 代理store

// 当前是second皮肤
const isSecondSkin = computed(() => getTheme().skin == 'second');

// 代理等级外圈图片
const currentAgentLevelPng = computed(() => {
  const data = getTheme();
  const skin = data.skin == 'second' ? 'second' : 'first'
  return `url('/${skin}/agent/${data.theme}-rate-level-bg.png')`
})

const { 
  gameTypeList,
  currentGameType,
  currentAgentLevel,
  agentLevelList,
  agentLevelRateInfo,
  isShowGameType,
  selectAgentLevel,
  selectAgmeType
} = useCommissionRateLogic();
</script>

<style scoped lang="less">
@import '@/views/mlmAgent/styles/commissionRate.less' ;
.level-icon {
  background: v-bind(currentAgentLevelPng) no-repeat;
  background-size: 100% 100%;
}
</style>
