// import { useAppStore } from '@/store/app';
import { showLogin } from '@/hooks/ShowLogin';
// import { useTenantStore } from '@/store/tenant';
import { showLoading } from '@/utils/loading';
import { showToast } from '@/utils'
export default async () => {
    const appStore = useAppStore();
    const tenantStore = useTenantStore();
    if (!appStore.token) {
        showLogin();
        return true
    }
    showLoading();
    const res = await tenantStore.setPayList();

    if (res.tenantPayTypeList.length) {
        return false;
    }
    if (res.limitType.length) {
        showToast('toast.accountError');
    } else {
        showToast('toast.rechargemaintenance');
    }
    return true
}



