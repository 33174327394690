<template>
  <div v-if="tenantInfo.switch" class="bonus" @click="bonusClick">
    <ion-icon class="bonus-img" src="/svg/bonus1.svg" />
    <div class="bonus-content">
      <div class="bonus-content-inner"> 
        <ion-label class="bonus-currency">{{ merchantCy }}</ion-label>
        <ion-label class="bonus-money">{{ showMoney }}</ion-label>
      </div>
      <ion-label class="bonus-title">JACKPOT</ion-label>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { IonIcon, IonImg, IonLabel } from '@ionic/vue';
  import useLogic from '@/components/BonusPool/logic';

  const {
    tenantInfo,
    merchantCy,
    showMoney,
    bonusClick,
  } = useLogic();
</script>

<style lang="less" scoped>
.bonus {
  display: flex;
  align-items: center;

  .bonus-img {
    font-size: 2.375rem;
    margin-inline-end: 0.375rem;
  }

  .bonus-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: 0 0 0.625rem #00D4FF;

    .bonus-content-inner {
      font-style: italic;
      line-height: 1;

      .bonus-money, .bonus-currency {
        font-size: 0.875rem;
        font-weight: 700;
        color: #fff;
      }

      .bonus-currency {
        margin-inline-end: 0.3125rem;
      }
    }
    

    .bonus-title {
      font-size: 0.625rem;
      font-weight: 700;
      font-style: italic;
      color: #fff;
      background: linear-gradient(90deg, #24D097 0%, #239BC4 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
