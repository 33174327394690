import type { ActivityRecordItem } from "@/api/activity/model";
import { ZVipReceiveType } from "@/enums/types";
import { ZLuckyWheelLotteryTicketGetType, ZNameType, ZRechargeConditionsType, ZTActivityTypes, ZActivityAwardType } from "@/enums/types/activity.type";
import { moneyConvertToClient, formatMoneyToShow } from "@/utils/custom";
import { useI18n } from '@/hooks/useI18n';
import { isJSONStr } from "@/utils/verify";
import { getActivityDefaultName } from "@/i18n/ruleHelper/activityRule";
import { useAppStore } from "@/store/app";

const { t } = useI18n();

// 获取奖金
export function getReward(source: ActivityRecordItem) {
    const { activityType, awardType, awardCount } = source;
    // 幸运转盘
    if (activityType === ZTActivityTypes.enum.LuckyWheel) {
        // 奖金
        if (awardType === ZActivityAwardType.enum.BALANCE) {
            return formatMoneyToShow(moneyConvertToClient(awardCount))
        }
        // 抽奖券
        else if (awardType === ZActivityAwardType.enum.LOTTERY_TICKET) {
            return `${t('activity.rewardTicket')}${awardCount}`
        }
        // 卡片
        else {
            return `${awardType.split('_')[2]}x${awardCount}`
        }
    }
    else if (activityType === ZTActivityTypes.enum.Agency) {
        if (source.minAwardCount || source.maxAwardCount) {
            const min = moneyConvertToClient(source.minAwardCount)
            const max = moneyConvertToClient(source.maxAwardCount)
            return `${formatMoneyToShow(min)}~${formatMoneyToShow(max)}`
        }
        return formatMoneyToShow(moneyConvertToClient(source.awardCount));
    }
    else {
      return formatMoneyToShow(moneyConvertToClient(source.awardCount));
    }
  }
  
// 根据游戏类型获取奖励来源
export function getRewardSource(source: ActivityRecordItem): string {
    const { activityType, remake, awardType } = source;
    // 救援金
    if (activityType === ZTActivityTypes.enum.Assistance) {
        return t('activity.assistance')
    }
    // 幸运转盘
    else if (activityType === ZTActivityTypes.enum.LuckyWheel) {
        // 抽奖券
        if (awardType === ZActivityAwardType.enum.LOTTERY_TICKET) {
            const rewardType: {[key: string]: string} = {
                [ZLuckyWheelLotteryTicketGetType.enum.firstLogin]: t('activity.firstLogin'),
                [ZLuckyWheelLotteryTicketGetType.enum.firstRecharge]: t('activity.firstRecharge'),
                [ZLuckyWheelLotteryTicketGetType.enum.everyDayFirstRecharge]: t('activity.everyDayFirstRecharge'),
                [ZLuckyWheelLotteryTicketGetType.enum.recharge]: t('activity.singleRecharge'),
                [ZLuckyWheelLotteryTicketGetType.enum.cumulativeRecharge]: t('activity.cumulativeRecharge'),
                [ZLuckyWheelLotteryTicketGetType.enum.validBet]: t('activity.validBet'),
                [ZLuckyWheelLotteryTicketGetType.enum.cumulativeValidBet]: t('activity.cumulativeValidBet'),
            }
            const type = remake.level.type as string
            const amount = type === ZLuckyWheelLotteryTicketGetType.enum.firstLogin ? '' : formatMoneyToShow(moneyConvertToClient(remake.triggerAmount))
            return `${rewardType[type]}${amount}`
        }
        // 奖金
        else if (awardType === ZActivityAwardType.enum.BALANCE) {
            if (remake.type === 'exchange') {
                return t('activity.exchange')
            }
            else {
                return t('activity.raffle');
            }
        }
        // 其他
        else {
            return t('activity.raffle');
        }
    }
    // VIP
    else if (activityType === ZTActivityTypes.enum.VIP) {
        const rewardType: {[key: string]: string} = {
            [ZVipReceiveType.enum.PROMOTION]: t('activity.promotion'),
            [ZVipReceiveType.enum.DAILY]: t('activity.dailyBonus'),
            [ZVipReceiveType.enum.WEEKLY]: t('activity.weeklyBonus'),
            [ZVipReceiveType.enum.MONTHLY]: t('activity.monthlyBonus'),
        }
        return `vip${remake.vipLevelInfo.level}${rewardType[remake.arg.type]}`
    }
    else if (activityType === ZTActivityTypes.enum.Agency) {
        return t('activity.promotionBonus')
    }
    else if (activityType === ZTActivityTypes.enum.AssistanceCash) {
        return t('viewsActivity.boostCash')
    }
    else if (activityType === ZTActivityTypes.enum.Rebate) {
        return t('activity.Rebate')
    }
    else if (activityType === ZTActivityTypes.enum.Recharge) {
        const rewardType: {[key: string]: string} = {
        [ZRechargeConditionsType.enum.FIRST]: t('activity.firstRecharge'),
        [ZRechargeConditionsType.enum.SUM]: t('activity.cumulativeRecharge'),
        [ZRechargeConditionsType.enum.SINGLE]: t('activity.singleRecharge'),
        }
        return `${rewardType[remake.type]}${formatMoneyToShow(moneyConvertToClient(remake.amount))}`
    }
    else if (activityType === ZTActivityTypes.enum.SignIn) {
        return t('activity.SignIn')
    }
    else if (activityType === ZTActivityTypes.enum.RedPacket) {
        return t('viewsActivity.redEnvelope')
    }
    else if (activityType === ZTActivityTypes.enum.Custom) {
        return t('activity.Custom')
    }
    else if (activityType === ZTActivityTypes.enum.MemberReward) {
        return t('activity.appreciation')
    }
    else if (activityType === ZTActivityTypes.enum.MysteryReward) {
        return t('option.activity:mysteryReward')
    }
    else if (activityType === ZTActivityTypes.enum.RedeemCode) {
        return t('activity.redeem')
    }
    else if (activityType === ZTActivityTypes.enum.Other) {
        return t('activity.other')
    }
    else if (activityType === ZTActivityTypes.enum.CommissionReward) {
        return t('activity.commission01')
    }
    else if (activityType === ZTActivityTypes.enum.CPFActivity) {
        return t('activity.cpfActivityName')
    }
    else if (activityType === ZTActivityTypes.enum.LuckyBet) {
        return t('option.activity:luckyBet')
    }
    else if (activityType === ZTActivityTypes.enum.SignInVolume) {
        return t('option.activity:signInVolumeReward')
    }
    return '';
}

// 获取活动名称
export function getActivityName(source: ActivityRecordItem, lang: string): string {
    if (source.activityType === ZTActivityTypes.enum.VIP) {
        return 'VIP'
    }
    else if (source.activityType === ZTActivityTypes.enum.Other) {
        return t('activity.other')
    }
    else if (source.activityType === ZTActivityTypes.enum.RedeemCode) {
        return t('activity.redeem')
    }
    if (source.activityNameType === ZNameType.enum.DEFAULT && isJSONStr(source.activityNameParams)) {
        const nameParams = JSON.parse(source.activityNameParams)
        source.activityName = getActivityDefaultName(lang, source.activityType, nameParams.variablesValue)
    }
    return source.activityName ?? 'Unknown';
}
