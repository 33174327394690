<!-- CPF 邀请活动 跟随皮肤 -->
<template>
  <ion-page>
    <!-- 顶部导航栏 -->
    <NavigationBar :title="activityName">
      <template #end>
        <ion-icon @click="router.push(`/activity/CPFActivity/record/${id}@${defStyle}`)" class="text-[1.875rem] default-invite-cpf-record mr-[1.25rem]" src="/svg/inviteCpf/invite-cpf-default-record.svg" />
      </template>
    </NavigationBar>
    <ion-content>
      <div class="w-full h-full relative">
        <!-- 顶部图片 -->
        <div class="default-invite-cpf-top-bg w-full h-[27.375rem] relative">
          <img class="absolute bottom-0 left-[45%] w-[3.264375rem] z-10 h-[4.033125rem] penguin-img" :src="currentTopPng1" />
          <img @click="copyClick" class="absolute bottom-[-1.2rem] z-20 left-[50%] w-[4.75rem] h-[3rem] img-translate" :src="currentTopPng2" />
        </div>
        <!-- 分享内容提示 -->
        <div class="cpf-share-content text-[1rem] mt-[1.875rem] text-center font-bold">
          {{ $t('activity.cpfShareBtn') }}
        </div>
        <!-- 可领取金额 -->
        <div class="w-full mt-[1rem] px-[0.75rem]">
          <div class="default-invite-cpf-claim relative">
            <div class="top-line absolute top-0 left-0 w-full"></div>
            <div class="bottom-line absolute bottom-0 left-0 w-full"></div>
            <div class="text-center default-invite-cpf-reward-amount-tips">{{ $t('activity.vip37') }}</div>
            <div class="text-center default-invite-cpf-reward-amount">
              {{ merchantCy }} {{ formatMoneyToShow(availableMoney) }}
            </div>
          </div>
        </div>
        <!-- 活动规则 -->
        <div class="default-invite-cpf-rule">
          <div class="keep-space default-invite-cpf-rule-content">
            <p v-html="activityRule"></p>
          </div>
        </div>
        <!-- 底部领取按钮 -->
        <div class="default-invite-cpf-footer fixed bottom-0 w-full h-[5rem]">
          <ion-button class="default-available-btn w-full" :class="{ 'default-disabled-btn': !isAvailable }" :disabled="!isAvailable"  @click="availableClick">
            {{ $t('activity.common001') }} {{ $t('activity.agent11') }}
          </ion-button>
        </div>
      </div>
    </ion-content>
    <!-- 分享弹窗 -->
    <ShareModal v-if="openShareModal" @closeShare="closeShareModalFun" />
  </ion-page>
</template>

<script setup lang="ts">
import router from '@/router'
import { computed } from 'vue';
import { getTheme } from '@/theme/hooks'
import { IonPage, IonContent, IonIcon, IonButton } from '@ionic/vue'
import { formatMoneyToShow } from '@/utils/custom'
import { getCustomerActivityId } from '@/utils/custom'
import { useInviteCpfLogic } from '@/views/activity/inviteCpf/hooks/inviteCpfLogic'
import NavigationBar from '@/components/NavigationBar/index.vue'
import ShareModal from '../modal/shareModal.vue'

const { id,defStyle } = getCustomerActivityId();

const currentTopPng = computed(() => {
  const data = getTheme();
  return `url('/images/inviteCpf/yellow-dark-invite-cpf-top.png')`
})

const currentTopPng1 = computed(() => {
  const data = getTheme();
  return `/images/inviteCpf/yellow-dark-invite-cpf-top1.png`
})

const currentTopPng2 = computed(() => {
  const data = getTheme();
  return `/images/inviteCpf/${data.theme}-invite-cpf-top2.png`
})

const {
  activityName,
  activityRule,
  merchantCy,
  openShareModal,
  availableMoney,
  isAvailable,
  closeShareModalFun,
  copyClick,
  availableClick
} = useInviteCpfLogic();
</script>

<style scoped lang="less">
@import '@/views/activity/inviteCpf/components/default/styles/base-index.less';
@import '@/views/activity/inviteCpf/components/default/styles/theme-style.less';

#activity-inviteCpf-components-default-index.style();


.default-invite-cpf-top-bg {
  background: v-bind(currentTopPng) no-repeat;
  background-size: 100% 100%;
}

.yellow-dark {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-300,
    --text-color-white-100,
    --accent-color-green,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --theme-color-800,
    --text-color-white-40,
    --color-bg-200,
    --invite-cpf-footer-top-line,
    --theme-color-800,
    --color-text-black-100,
    --color-primary-btn-load,
    --color-primary-btn-text-disable
  );
}

.green-dark {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-300,
    --text-color-white-100,
    --accent-color-green,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --accent-color-orange,
    --text-color-white-40,
    --color-bg-200,
    --invite-cpf-footer-top-line,
    --theme-color-800,
    --text-color-white-100,
    --color-primary-btn-disable,
    --color-primary-btn-text-disable
  )
}

.purple-light {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-300,
    --text-color-white-100,
    --accent-color-green,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --accent-color-orange,
    --text-color-black-80,
    --color-bg-200,
    --invite-cpf-footer-top-line,
    --theme-color-800,
    --text-color-white-100,
    --color-primary-btn-disable,
    --color-primary-btn-text-disable
  )
}

.amber-purple {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-300,
    --color-text-gray-100,
    --default-invite-share-text,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --accent-color-yellow,
    --color-text-gray-200,
    --color-bg-200,
    --invite-cpf-footer-top-line,
    --theme-color-gradient-100,
    --text-color-white-100,
    --gradients-disabled-btn-bg,
    --color-text-gray-100
  )
}

.blue-default {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-400,
    --color-text-white-100,
    --mlm-agent-team-text-color,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --accent-color-orange,
    --text-color-white-40,
    --invite-cpf-footer-bg-color,
    --invite-cpf-footer-top-line,
    --mlm-agent-home-invite-btn-color,
    --color-text-white-100,
    --color-primary-btn-disable,
    --color-primary-btn-text-disable
  )
}

.auroral-yellow {
  #activity-inviteCpf-components-default-index.style(
    @activity-inviteCpf-components-default-index-14: --color-text-black-100;
  )
}

.forest-green {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-300,
    --color-text-white-100,
    --mlm-agent-team-text-color,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --color-warning,
    --text-color2,
    --color-bg-200,
    --invite-cpf-footer-top-line,
    --theme-color-gradient-100,
    --color-text-white-100,
    --agent-disabled-btn-bgc,
    --color-text-white-40
  )
}

.green-default {
  #activity-inviteCpf-components-default-index.style(
    --color-bg-300,
    --color-text-gray-100,
    --mlm-agent-team-text-color,
    --invite-cpf-claim-bg-color,
    --invite-cpf-claim-top-bg-color,
    --invite-cpf-reward-amount-text-color,
    --color-warning,
    --color-text-gray-200,
    --color-bg-200,
    --invite-cpf-footer-top-line,
    --theme-color-gradient-100,
    --color-text-white-100,
    --theme-color-gradient-500,
    --color-text-gray-100
  )
}
</style>
