<!-- 兑换码活动 -->
<template>
  <div :class="$attrs.class" :style="$attrs.style">
    <div class="rounded-large overflow-hidden mt-[0.625rem] mb-4">
      <ion-img v-show="imgUrl" :src="imgUrl" @click="onImgClick"></ion-img>
    </div>
    <Input class="h-[2.875rem] mb-6" ref="inputRef" :placeholder="t('activity.redeem1')" bgColor="--color-redeem-input-bg"
      v-model="redeemCode" type="text" :minlength="5" :maxlength="12" clearInput></Input>
    <div class="mb-3">
      <Button @click="redemptionHandle">{{ $t('activity.redeem2') }}</Button>
    </div>
    <PartView :gamePlatform="false" :paymentPlatform="false"></PartView>
    <div class="rule-text text-xs pt-[1.875rem] mb-10">
      <p class="keep-space">{{ description }}</p>
    </div>
  </div>
</template>
  
  <script setup lang="ts">
  import PartView from '@/views/tabbar/tabs/inicio/components/PartView/first/index.vue';
  import { IonImg } from '@ionic/vue';
  import Input from '@/components/first/Input/index.vue'
  import Button from '@/components/first/Button/index.vue'
  import useLogic from '../logic';

  defineOptions({
    inheritAttrs: false
  })

  const {
    inputRef,
    redeemCode,
    description,
    imgUrl,
    redemptionHandle,
    t,
    onImgClick,
  } = useLogic();
  
  </script>
  
  <style scoped>
  .rule-text {
    color: var(--color-activity-rule);
  }
  </style>
