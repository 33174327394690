<!-- 多级分销 推广中心 -->
<template>
  <ion-page>
    <!-- 顶部导航栏 -->
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('main.agent') + $t('main.center') }}</ion-title>
        <ion-icon slot="end" v-show="isShowLeaderBoard" @click="leaderBoardClick" src="/first/svg/agent/leaderboard-icon.svg" />
      </ion-toolbar>
    </ion-header>
    <!-- 内容 -->
    <ion-content>
      <!-- 代理等级信息 -->
      <div class="agent-level">
        <div class="agent-level-left">
          <div class="level-icon">
            <img class="current-level" :src="agentStore.getMlmAgentLevelInfo(agencyInfo.currentLevel,'icon')" alt="">
          </div>
          <div class="level-tips">LV{{ agencyInfo.currentLevel }}</div>
        </div>
        <div class="agent-level-right">
          <div class="top">{{ $t('date.yesterday') }} {{ $t('spread.totalCommission') }}</div>
          <div class="claim-commisson">
            <ion-icon class="agent-money" src="/svg/agent-money.svg" />
            <span>{{ tenantInfo?.merchantCy }} {{ formatMoneyToShow(agencyInfo?.claimedCommission) }}</span>
            <ion-icon class="agent-money agent-money-right" src="/svg/agent-money.svg" />
          </div>
          <!-- 领取 领取记录按钮 -->
          <div class="btn">
            <ion-button class="mlm-agent-btn" @click="historyClick">{{ $t('toggle.history') }}</ion-button>
            <ion-button 
              class="mlm-agent-btn claim-btn" 
              :disabled="claimDisabled"
              :class="{ 'claim-dis': claimDisabled }"
              @click="claimClick"
              v-show="isShowClaimBtn"
            >
              {{ $t('toggle.claim') }}
            </ion-button>
          </div>
          <!-- 佣金领取规则 -->
          <div class="receive-rule">
            <span class="text">
              <span class="hint" v-html="$t('toggle.receiveTime', { time: config?.commissionDistributeTime })"></span>
            </span>
            <div v-show="false" class="rule-btn" @click="showBonusRuleModal">
              <ion-icon :src="`/svg/warning.svg`" />
              <span>{{ $t('toggle.ruleBtn') }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 代理信息 -->
      <div class="mlm-agent-info">
        <!-- 下级信息 -->
        <div class="top">
          <div class="subordinate-info" v-for="item in agencyInfo.subordinateInfo" :key="item.type">
            <!-- 直属下级/团队下级 -->
            <div 
              class="left" 
              :class="item.type == 'direct' ? 'direct-title' : 'team-title'"
            >
              <ion-icon  :src="`/first/svg/agent/${item.icon}.svg`" />
              {{ item.title }}
            </div>
            <div class="dataItem-info" v-for="(dataItem,index) in item.data" :key="index">
              <div class="dataItem-title">
                <ion-icon v-if="dataItem.icon" :src="`/first/svg/agent/${dataItem.icon}.svg`" />
                {{ dataItem.name }}
              </div> 
              <div class="num">{{ dataItem.format ? formatMoneyToShow(dataItem.value) : dataItem.value  }}</div>
            </div>
          </div>
        </div>
        <!-- 邀请链接 -->
        <div class="link">
          <Button v-if="!isSecondSkin" background="var(--mlm-agent-home-invite-btn-color)" @click="invitationLink">{{ $t('mlmAgent.inviteLink') }}</Button>
          <secondButton v-else @click="invitationLink">{{ $t('mlmAgent.inviteLink') }}</secondButton>
        </div>
        <!-- 我的推广 -->
        <div class="agent-info-datial">
          <div class="header">
            <ion-icon class="agent-report-icon" src="/first/svg/agent/agent-report.svg" />
            <span class="my-agency">{{ $t('toggle.MyAgency') }}</span>
          </div>
          <div class="item">
            <div class="detail-item" :class="item.icon ? 'up' : ''" v-for="(item,index) in agencyInfo.myAgentInfoList" :key="index">
              <div class="count-info">{{ item.format ? formatMoneyToShow(item.value) : item.value }}</div>
              <div class="des">
                <ion-icon v-if="item.icon"  :src="`/first/svg/agent/${item.icon}.svg`" />
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- 下级数据/佣金明细/邀请规则/代理级别/佣金比例表 -->
        <div class="list">
          <div 
            class="jump-item" 
            v-for="(item,index) in nlnAgentJumpList" 
            :key="index"
            :style="{ backgroundImage: `url(/first/agent/${item.bgIcon}.png` }"
            @click="item.fun"
          >
            <div class="text">{{ item.name }}</div>
            <ion-icon src="/first/svg/agent/agent-detail-icon.svg"/>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getTheme } from '@/theme/hooks'
import { useAgentStore } from '@/store/agent';
import { useMlmAgentLogic } from './hooks/logic'
import { formatMoneyToShow } from '@/utils/custom'
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle, IonImg, IonIcon, IonButton } from '@ionic/vue'; 
import Button from '@/components/first/Button/index.vue'
import secondButton from '@/components/second/Button/index.vue'
import BackButton from '@/components/BackButton.vue';

const agentStore = useAgentStore();   // 代理store

// 当前是second皮肤
const isSecondSkin = computed(() => getTheme().skin == 'second');


// 代理等级外圈图片
const currentAgentLevelPng = computed(() => {
  const data = getTheme();
  const skin = data.skin == 'second' ? 'second' : 'first'
  return `url('/${skin}/agent/${data.theme}-home-agent-level-bg.png')`
})

const {
  agencyInfo,
  tenantInfo,
  config,
  nlnAgentJumpList,
  claimDisabled,
  isShowClaimBtn,
  isShowLeaderBoard,
  invitationLink,
  leaderBoardClick,
  historyClick,
  claimClick,
  showBonusRuleModal
} = useMlmAgentLogic();

</script>

<style scoped lang="less">
  @import '@/views/mlmAgent/styles/index.less' ;

  .agent-level {
    .agent-level-left {
      .level-icon {
        background: v-bind(currentAgentLevelPng) no-repeat;
        background-size: 100% 100%;
      }
    } 
  }

.auroral-yellow {
  .receive-rule,
  .dataItem-title {
    color: var(--color-text-gray-200);
  }
  .detail-item .des{
    color: var(--color-text-gray-200);
  }
  .direct-title,
  .team-title {
    background: var(--color-bg-100);
  }
}
</style>
 