<!-- 会员答谢 -->
<template>
	<ion-page>
		<!-- 导航标签 -->
		<ion-header class="ion-no-border">
			<ion-toolbar mode="ios">
				<BackButton slot="start" />
				<ion-title>{{ activityInfo.name }}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<!-- 活动时间 -->
			<div class="banner">
				<div class="timeBoard text-[0.625rem] leading-[0.9375rem]">
					<p class="textBasic font-normal">{{ $t('activity.appreciation01') }}</p>
					<p class="textEmphasis font-bold">{{ activityInfo.endTime }}</p>
					<p></p>
				</div>
			</div>
			<div class="mx-3">
				<!-- 循环周期 -->
				<div>
					<div class="flex items-center mt-7 mb-[0.5625rem]">
						<ion-icon class="text-xl" :icon="'/svg/activity/appreciation_icon02.svg'"></ion-icon>
						<span class="ml-1 font-medium text-sm leading-7  title-text">{{ $t('activity.appreciation02') }}</span>
					</div>
					<div class="w-full h-[3.75rem] rounded-md bgSecond flex items-center justify-center">
						<span class="textTime text-xl font-bold">
							{{ activityInfo.cycle }}
						</span>
					</div>
				</div>
				<!-- 奖励金额 -->
				<div>
					<div class="flex items-center mt-7 mb-[0.5625rem]">
						<ion-icon class="text-xl" :icon="'/svg/activity/appreciation_icon01.svg'"></ion-icon>
						<span class="ml-1 font-medium text-sm leading-7  title-text">{{ $t('activity.appreciation12') }}</span>
					</div>
					<div class="w-full h-[3.75rem] rounded-md bgSecond flex items-center justify-center">
						<span class="text-xl font-bold">
							<span class="textSecond">{{ merchantCy }}</span>
							<span class="textReward">{{ activityInfo.maxReward }}</span>
						</span>
					</div>
				</div>
				<!-- 活动规则 -->
				<div class="mt-[0.875rem] pb-28">
					<p class="mb-5 text-sm font-medium  title-text">{{ $t('activity.appreciation13') }}</p>
					<p class="text-xs font-normal  textRule keep-space">
						{{ activityInfo.description }}
					</p>
				</div>
			</div>
		</ion-content>
		<Footer v-if=" skin == 'default'">
			<!-- 领取按钮 -->
			<div class="btn" :class="activityInfo.canReceive ? 'shiny active' : 'unable'" @click="onActivityApply">{{ $t('toggle.claim') }}</div>
		</Footer>
		<FooterFirst v-if=" skin == 'first'">
			<!-- 领取按钮 -->
			<ButtonFirst :disabled="!activityInfo.canReceive" :shiny="activityInfo.canReceive" @click="onActivityApply">
			{{$t('toggle.claim') }}
			</ButtonFirst>
		</FooterFirst>
		<FooterFirst v-if=" skin == 'second'">
			<!-- 领取按钮 -->
			<!-- 领取按钮 -->
			<div class="flex flex-col justify-end h-[3rem] mt-[-0.25rem]" @contextmenu="forbidContextmenu" :class=" activityInfo.canReceive ? '' :'disabledBtn'" >
				<div class="bottomBtn rounded-[0.875rem] overflow-hidden h-[3rem]">
					<ButtonFirst @click="onActivityApply" background="transpant" :shiny="activityInfo.canReceive">
						{{$t('toggle.claim') }}
					</ButtonFirst>
				</div>
			</div>
		</FooterFirst>
	</ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonContent, IonToolbar, IonHeader, IonIcon, IonTitle } from '@ionic/vue'
import BackButton from '@/components/BackButton.vue'
import Footer from '@/views/activity/comp/default/Footer/index.vue';
import FooterFirst from '@/views/activity/comp/first/Footer/index.vue';
import ButtonFirst from '@/components/first/Button/index.vue'
import { getTheme } from '@/theme/hooks';
const { skin } = getTheme();
import useLogic from '../logic';

const { activityInfo, merchantCy, onActivityApply,forbidContextmenu } = useLogic();

</script>

<style scoped lang="less">
  // 基础公共 less
  @import "@/views/activity/appreciation/style_0/style/base-index.less";
  @import "@/views/activity/appreciation/style_0//style/theme-style.less";

	#activity-appreciation-default-index.style();

	.blue-default {
		#activity-appreciation-default-index.style();
	}

	.green-default {
		#activity-appreciation-default-index.style();
	}

	.amber-purple .default {
		#activity-appreciation-default-index.style(
			@appreciation-default-index-04: --color-bg-200
		);
	}

	.yellow-dark,
	.green-dark {
		#activity-appreciation-default-index.style(
			--color-activity-time,
			--color-warning,
			--color-appreciation-currency,
			--color-appreciation-board,
			--accent-color-blue,
			--color-appreciation-title,
			--color-warning,
			--color-appreciation-currency,
		);
	}

	.purple-light {
		#activity-appreciation-default-index.style(
			--color-activity-time,
			--color-warning,
			--color-appreciation-currency,
			--color-appreciation-board,
			--accent-color-blue,
			--color-appreciation-title,
			--color-warning,
			--color-appreciation-title,
		);
	}

	.amber-purple .second {
		#activity-appreciation-default-index.style(
			--color-activity-time,
			--color-text-orange-emphasis,
			--color-text-first,
			--color-bg-fifth,
			--accent-color-blue,
			--color-text-first,
			--color-text-yellow-emphasis,
			--color-text-second
		);
		.bottomBtn {
          background: var(--color-gradients-btn);
          box-shadow: var(--color-shadow-bottom-btn);
        }

        .bottomBtn:active {
          background: var(--color-gradients-btn);
          box-shadow: none;
          height: 2.8rem;
        }

        .disabledBtn {
          opacity: 0.4;
          pointer-events: none;
        }
	}
</style>

