<!-- 公用弹窗 -->
<template>
    <div class="h-full w-full flex justify-center items-center public-popup-wrap">
        <div class="main-bg relative w-[21.25rem] min-h-[13.75rem]">
            <ion-img :src="bgUrl"></ion-img>
            <ion-img class="absolute top-0 -translate-y-[4.375rem]" :src="iconUrl"></ion-img>
            <!-- content -->
            <component :is="customComponent"  v-if="customComponent" />
            <div v-else class="w-full min-h-[3.75rem] my-5 flex items-center justify-center">
                <div class="content w-[16.25rem] text-sm text-center">
                    <div v-html="content"></div>
                </div>
            </div>
            <!-- button list -->
            <div class="mx-2 flex justify-around mb-5" :class="{ 'flex-row-reverse': reverseBtn }">
                <Button v-show="showLeftBtn" width="9.375rem" background="var(--common-dialog-left-btn-bg)" @click="leftBtnCallback"
                :style="{'--button-text-color': 'var(--common-dialog-left-btn-text-color)'}">
                    {{  leftBtnText || $t('main.confirm')  }}
                </Button>
                <Button v-show="showRightBtn" :disabled="rightBtnIsDisabled" width="9.375rem" background="var(--common-dialog-right-btn-bg)" @click="rightBtnCallback" 
                :style="{'--button-text-color': 'var(--common-dialog-right-btn-text-color)'}">
                    {{ rightBtnText || $t('main.cancel') }}
                    <span v-show="countdownEnable > 0">{{ `(${countdownTime})` }}</span>
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { IonImg } from '@ionic/vue';
import Button from '@/components/first/Button/index.vue'
import { PopupType, PopupParams } from '../data';
import useLogic from '../logic'

const props = withDefaults(
    defineProps<PopupParams>(),
    {
        type: PopupType.TIPS,
        showLeftBtn: true,
        showRightBtn: false,
        reverseBtn: false,
        countdownEnable: 0,
    }
)

const {
    bgUrl,
    iconUrl,
    content,
    rightBtnIsDisabled,
    countdownTime,
    customComponent,
    leftBtnCallback,
    rightBtnCallback,
} = useLogic(props)
</script>

<style scoped>
.main-bg {
    background-color: var(--common-dialog-bg);
    border-radius: 1.125rem;
}

.content {
    color: var(--common-dialog-text-color);
}

.left-btn {
    background-color: var(--common-dialog-left-btn-bg);
    color: var(--common-dialog-left-btn-text-color);
}

.right-btn {
    background-color: var(--common-dialog-right-btn-bg);
    color: var(--common-dialog-right-btn-text-color);
}
</style>
