import { useLoadComponent } from '@/hooks/useLoadComponent';
import type { ComponentList } from '@/hooks/useLoadComponent';


export default () => {
  const {
    loadComponent,
  } = useLoadComponent();
  // 
  const tabsList: ComponentList = {
    'blue-default-GameType': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs1/index.vue'),
    'blue-default-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs4/index.vue'),
    'blue-default-v01-GameType': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs5/index.vue'),
    'green-default-v01-GameType': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'green-default-v01-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'green-default-v02-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'forest-green-v01-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'forest-green-v02-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'amber-purple-v01-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'blue-default-v02-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs6/index.vue'),
    'auroral-yellow-Platform': () => import('@/views/tabbar/MainPage/components/Tabs/Tabs7/index.vue'),
  };
  const drawerLeftList: ComponentList = {
    'blue-default-GameType': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft1/index.vue'),
    'blue-default-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft2/index.vue'),
    'blue-default-v01-GameType': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft5/index.vue'),
    'green-default-v01-GameType': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft1/index.vue'),
    'green-default-v01-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft3/index.vue'),
    'green-default-v02-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft4/index.vue'),
    'forest-green-v01-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft3/index.vue'),
    'forest-green-v02-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft4/index.vue'),
    'amber-purple-v01-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft3/index.vue'),
    'blue-default-v02-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft3/index.vue'),
    'auroral-yellow-Platform': () => import('@/views/tabbar/components/DrawerLeft/default/DrawerLeft6/index.vue'),
  };

 
  const tabsComponent = loadComponent(tabsList);
  const drawerLeftComponent = loadComponent(drawerLeftList);


  return {
    tabsComponent,
    drawerLeftComponent
  }
}
