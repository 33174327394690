<!-- 多级分销 佣金排行榜 -->
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <BackButton />
        <ion-title>{{ $t('mlmAgent.leaderBordName') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scrollY="showBoardEmpty">
      <!-- 日榜/周榜/月榜  排行榜前三 -->
      <div class="leader-board-top">
        <!-- 日榜/周榜/月榜tab  -->
        <div class="leader-board-tab" >
          <div 
            class="tab-item" 
            v-for="(item,index) in boardTabList" 
            :key="index"
            :class="{ 'selected-board': rewardType == item.value }"
            @click="selectBoardClick(item.value)"
          > 
            {{ item.name }}
          </div>
        </div>
        <!-- 排行榜 前三 -->
        <div class="top-three-box" v-if="showBoardEmpty">
          <!-- 排行榜骨架 -->
          <template v-if="showLoadPage">
            <div 
              v-for="(item,index) in topThreeLoadList" 
              :key="index" 
              class="top-board-item"
              :class="{ 'top-one': index == 0, 'top-two': index == 1, 'top-three': index == 2 }"
            >
              <img class="mlm-user-bg" :src="item.topImg" alt="">
              <img class="mlm-user-avatar" :src="item.avatar" alt="">
              <div class="user-phone">{{ item.userId }}</div>
              <div class="middle-text-white ">
                <ion-icon :src="`/first/svg/agent/agent-currency.svg`" />
                {{ formatMoneyToShow(item.totalCommission) }}
              </div>
            </div>
          </template>
          <!-- 真实排行榜信息 -->
          <template v-else>
            <div 
              v-for="(item,index) in threeTopBoardList" 
              :key="index" 
              class="top-board-item"
              :class="{ 'top-one': index == 0, 'top-two': index == 1, 'top-three': index == 2 }"
            >
              <img class="mlm-user-bg" :src="item.topImg" alt="">
              <img class="mlm-user-avatar" :src="item.avatar" alt="">
              <div class="user-phone">{{ item.userId }}</div>
              <div class="middle-text-white">
                <ion-icon :src="`/first/svg/agent/agent-currency.svg`" />
                {{ formatMoneyToShow(item.totalCommission) }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 排行榜 其他 -->
      <div class="other" v-if="showBoardEmpty">
        <!-- 标题 title -->
        <div class="board-title board-table-title">
          <div class="rank-board">{{ $t('mlmAgent.ranking') }}</div>
          <div class="usuario-board">{{ $t('mlmAgent.userInfo') }}</div>
          <div class="comissao-board">{{ $t('toggle.commissions') }}</div>
        </div>
        <!-- 排行榜名单 -->
        <template v-if="showLoadPage">
          <div class="board-title board-item" v-for="(item,index) in ohterBoardLoadList" :key="index">
            <div class="rank-board">{{ handleOtherBoardNum(index) }}</div>
            <div class="userInfo-board">
              <div class="user">
                <img :src="item.avatar" alt="">
              </div>
              {{ item.userId }}
            </div>
            <div class="comission-board middle-text-white">
              <ion-icon :src="`/first/svg/agent/agent-currency.svg`" />
              {{ formatMoneyToShow(item.totalCommission) }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="board-title board-item" v-for="(item,index) in otherBoardList" :key="index">
            <div class="rank-board">{{ handleOtherBoardNum(index) }}</div>
            <div class="userInfo-board">
              <div class="user">
                <img :src="item.avatar" alt="">
              </div>
              {{ item.userId }}
            </div>
            <div class="comission-board middle-text-white">
              <ion-icon  :src="`/first/svg/agent/agent-currency.svg`" />
              {{ formatMoneyToShow(item.totalCommission) }}
            </div>
          </div>
        </template>
        <!-- 暂无数据 -->
        <div class="empty" v-if="!showLoadPage && !otherBoardList.length">
          <Empty :text="$t('mlmAgent.noMoreDeta')" />
        </div>
      </div>
      <!-- 整体排行 empty -->
      <div class="empty" v-if="!showBoardEmpty">
        <Empty :text="$t('mlmAgent.noMoreDeta')" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { topThreeLoadList, ohterBoardLoadList } from '../data'
import { formatMoneyToShow } from '@/utils/custom'
import { useLeaderBordInfoLogic } from '../hooks/leaderBordInfoLogic.ts' 
import { IonPage, IonHeader, IonToolbar, IonContent, IonTitle, IonIcon } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';
import Empty from '@/components/Empty/index.vue'

const {
  rewardType,
  boardTabList,
  threeTopBoardList,
  otherBoardList,
  showLoadPage,
  showBoardEmpty,
  selectBoardClick,
  handleOtherBoardNum
} = useLeaderBordInfoLogic();
</script>

<style scoped lang="less">
@import '@/views/mlmAgent/styles/leaderBordInfo.less' ;
</style>
