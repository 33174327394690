<!-- 代理活动-详情 -->
<template>
  <ion-page>
    <ion-header>
      <!-- 顶部导航栏 -->
      <ion-toolbar class="" mode="ios">
          <BackButton />
          <ion-title>{{ $t('activity.agent28') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content >
      <!-- 搜索栏 -->
      <div class="flex items-center px-3 w-full h-[2.5rem]">
        <div class="flex justify-center items-center px-[0.625rem] py-[0.25rem] w-[10.5rem] h-[1.625rem] border border-[--color-border-input-basic] rounded-xl ">
          <ion-input class="no-spinners h-[2.375rem] text-xs" ref="inputRef" mode="ios" v-model="userId" :placeholder="$t('activity.agent29')" type="number" :controls="false" @ionChange="getUserDetails">
            <ion-icon slot="end" :icon="searchOutline"></ion-icon>
          </ion-input>
        </div>
      </div>

      <div class="px-2">
        <!-- 列表标题 -->
        <div class="flex py-3 text-center text-xs font-[--font-weight-bold]  bg-[--color-bg-third] text-[--color-text-second]">
          <span v-for="title in titleList" class="flex-1 overflow-auto whitespace-normal">{{ title }}</span>
        </div>
        <ion-list class=" bg-[--color-bg-second]">
          <div v-for="(item, index) in pageData" class="flex items-center w-full h-[3.125rem] text-xs text-center" :class="index%2 === 0 ? 'bg-[--color-bg-second]': ''">
            <span class="flex-1">{{ item.registerTime }}</span>
            <span class="flex-1">{{ item.userId }}</span>
            <span class="flex-1 font-[--font-weight-bold] text-[--color-text-emphasis]">
              <p>{{ formatMoneyToShow(item.firstRechargeAmount) }}</p>
              <p>{{ formatMoneyToShow(item.historicalPay) }}</p>
            </span>
            <span class="flex-1">
              <p>{{ `${item.rechargeCount}${$t('activity.agent35')}` }}</p>
              <p>{{ `${item.rechargeDays}${$t('activity.agent36')}` }}</p>
            </span>
            <span class="flex-1 font-[--font-weight-bold] text-[--color-text-emphasis]">
              <p>{{ formatMoneyToShow(item.historicalBetting) }}</p>
            </span>
          </div>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonHeader, IonToolbar, IonInput, IonContent, IonList, IonTitle, IonIcon } from '@ionic/vue';
import BackButton from '@/components/BackButton.vue';
import { formatMoneyToShow } from '@/utils/custom';
import { searchOutline } from 'ionicons/icons';

import useLogic from '../detailsLogic';

const {
  titleList,
  pageData,
  userId,
  getUserDetails,
} = useLogic();

</script>

<style scoped lang="less">
  // 基础公共 less
  @import "@/views/activity/agency/default/style/details/base.less";
  @import "@/views/activity/agency/default/style/details/theme-style.less";

  #activity-agency-default-details.style();

  .blue-default {
    #activity-agency-default-details.style(
      --color-text-white-100,
      --color-text-gray-200,
      --color-border-600,
      --font-weight-bold,
      --color-bg-100,
      --color-text-gray-200,
      --color-bg-200,
      --color-bg-200,
      --accent-color-yellow,
      --font-weight-bold,
      --color-text-white-100
    );
  }

  .green-default {
    #activity-agency-default-details.style(
      --color-text-white-100,
      --color-text-gray-200,
      --color-line,
      --font-weight-bold,
      --color-bg-100,
      --color-text-gray-200,
      --color-bg-200,
      --color-bg-200,
      --accent-color-yellow,
      --font-weight-bold,
      --color-text-gray-100
    );
  }
  
  .amber-purple {
    #activity-agency-default-details.style(
      --text-color-white-100,
      --text-color-light-purple-2-100,
      --line-color,
      --font-weight-bold,
      --color-bg-100,
      --text-color-light-purple-2-100,
      --color-bg-200,
      --color-bg-200,
      --accent-color-yellow,
      --font-weight-bold,
      --text-color-light-purple-1-100
    );
  };
</style>
